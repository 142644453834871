@media screen and (max-width: 1229px) {
  .wrapper {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }


  .main-slide-code-container {
    font-size: max(3.4146vw, 16px);
  }
    .main-rotator-container.with-mini .main-slide-code-container {
      font-size: max(2.9268vw, 16px);
    }


  .main-rotator-mini-code {
    font-size: max(1.4634vw, 14px);
  }
}

@media (max-width: 1099px) {
  .header-logo-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
    .header-logo + .header-slogan {
      padding: 8px 0px 0px;
    }
      .header-slogan br {
        display: none;
      }
}

/* landscape tablet - lt */
@media screen and (max-width: 1023px) {
  /* Line height */
  /* =========== */
  .lt_lh10 { line-height: 1; }
  .lt_lh100p { line-height: 100%; }
  .lt_lh11 { line-height: 1.1; }
  .lt_lh110p { line-height: 110%; }
  .lt_lh12 { line-height: 1.2; }
  .lt_lh120p { line-height: 120%; }
  .lt_lh13 { line-height: 1.3; }
  .lt_lh130p { line-height: 130%; }
  .lt_lh14 { line-height: 1.4; }
  .lt_lh140p { line-height: 140%; }
  .lt_lh15 { line-height: 1.5; }
  .lt_lh150p { line-height: 150%; }
  .lt_lh16 { line-height: 1.6; }
  .lt_lh160p { line-height: 160%; }
  .lt_lh17 { line-height: 1.7; }
  .lt_lh170p { line-height: 170%; }
  .lt_lh18 { line-height: 1.8; }
  .lt_lh180p { line-height: 180%; }
  .lt_lh19 { line-height: 1.9; }
  .lt_lh190p { line-height: 190%; }
  .lt_lh20 { line-height: 2; }
  .lt_lh200p { line-height: 200%; }


  /* Display */
  /* ======= */
  .lt_dn { display: none; }
  .lt_dn_i { display: none !important; }
  .lt_db { display: block; }
  .lt_di { display: inline; }
  .lt_dib { display: inline-block; }
  .lt_dt { display: table; }
  .lt_dtr { display: table-row; }
  .lt_dtc { display: table-cell; }

  /* Flex */
  .lt_dfdr { display: flex; flex-direction: row; }
  .lt_dfdrr { display: flex; flex-direction: row-reverse; }
  .lt_dfdc { display: flex; flex-direction: column; }
  .lt_dfdcr { display: flex; flex-direction: column-reverse; }
  .lt_difdr { display: inline-flex; flex-direction: row; }
  .lt_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .lt_difdc { display: inline-flex; flex-direction: column; }
  .lt_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .lt_fwn { flex-wrap: nowrap; }
  .lt_fww { flex-wrap: wrap; }
  .lt_fwwr { flex-wrap: wrap-reverse; }
  .lt_fjcs { justify-content: flex-start; }
  .lt_fjce { justify-content: flex-end; }
  .lt_fjcc { justify-content: center; }
  .lt_fjcsa { justify-content: space-around; }
  .lt_fjcsb { justify-content: space-between; }
  .lt_fais { align-items: flex-start; }
  .lt_faie { align-items: flex-end; }
  .lt_faic { align-items: center; }
  .lt_faib { align-items: baseline; }
  .lt_faist { align-items: stretch; }
  .lt_facs { align-content: flex-start; }
  .lt_face { align-content: flex-end; }
  .lt_facc { align-content: center; }
  .lt_facsa { align-content: space-around; }
  .lt_facsb { align-content: space-between; }
  .lt_facst { align-content: stretch; }
  .lt_fass { align-self: flex-start; }
  .lt_fase { align-self: flex-end; }
  .lt_fasc { align-self: center; }
  .lt_fasb { align-self: baseline; }
  .lt_fasst { align-self: stretch; }


  /* Height */
  /* ====== */
  .lt_h0 { height: 0; }
  .lt_h100p, .lt_hfull { height: 100%; }
  .lt_hauto { height: auto; }


  /* Width */
  /* ===== */
  .lt_w0 { width: 0; }
  .lt_w1 { width: 1px; }
  .lt_w5p { width: 5%; }
  .lt_w7p { width: 7%; }
  .lt_w10p { width: 10%; }
  .lt_w12p { width: 12.5%; }
  .lt_w15p { width: 15%; }
  .lt_w20p { width: 20%; }
  .lt_w25p { width: 25%; }
  .lt_w33p { width: 33.33333333%; }
  .lt_w35p { width: 35%; }
  .lt_w40p { width: 40%; }
  .lt_w50p { width: 50%; }
  .lt_w60p { width: 60%; }
  .lt_w65p { width: 65%; }
  .lt_w66p { width: 66.66666666%; }
  .lt_w70p { width: 70%; }
  .lt_w75p { width: 75%; }
  .lt_w80p { width: 80%; }
  .lt_w85p { width: 85%; }
  .lt_w88p { width: 87.5%; }
  .lt_w90p { width: 90%; }
  .lt_w93p { width: 93%; }
  .lt_w95p { width: 95%; }
  .lt_w100p, .lt_wfull { width: 100%; }
  .lt_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .lt_m0auto, .lt_m0a { margin: 0 auto; }
  .lt_m0auto_i, .lt_m0a_i { margin: 0 auto !important; }
  .lt_mauto0, .lt_ma0 { margin: auto 0; }
  .lt_mauto0_i, .lt_ma0_i { margin: auto 0 !important; }
  .lt_mauto, .lt_ma { margin: auto; }
  .lt_mauto_i, .lt_ma_i { margin: auto !important; }
  .lt_mtauto, .lt_mta { margin-top: auto; }
  .lt_mtauto_i, .lt_mta_i { margin-top: auto !important; }
  .lt_mlauto, .lt_mla { margin-left: auto; }
  .lt_mlauto_i, .lt_mla_i { margin-left: auto !important; }
  .lt_mrauto, .lt_mra { margin-right: auto; }
  .lt_mrauto_i, .lt_mra_i { margin-right: auto !important; }
  .lt_mbauto, .lt_mba { margin-bottom: auto; }
  .lt_mbauto_i, .lt_mba_i { margin-bottom: auto !important; }
  .lt_m0 { margin: 0; }
  .lt_m0_i { margin: 0 !important; }
  .lt_mt0 { margin-top: 0; }
  .lt_mt0_i { margin-top: 0 !important; }
  .lt_ml0 { margin-left: 0; }
  .lt_ml0_i { margin-left: 0 !important; }
  .lt_mr0 { margin-right: 0; }
  .lt_mr0_i { margin-right: 0 !important; }
  .lt_mb0 { margin-bottom: 0; }
  .lt_mb0_i { margin-bottom: 0 !important; }
  .lt_m1 { margin: 1px; }
  .lt_mt1 { margin-top: 1px; }
  .lt_ml1 { margin-left: 1px; }
  .lt_mr1 { margin-right: 1px; }
  .lt_mb1 { margin-bottom: 1px; }
  .lt_m2 { margin: 2px; }
  .lt_mt2 { margin-top: 2px; }
  .lt_ml2 { margin-left: 2px; }
  .lt_mr2 { margin-right: 2px; }
  .lt_mb2 { margin-bottom: 2px; }
  .lt_m3 { margin: 3px; }
  .lt_mt3 { margin-top: 3px; }
  .lt_ml3 { margin-left: 3px; }
  .lt_mr3 { margin-right: 3px; }
  .lt_mb3 { margin-bottom: 3px; }
  .lt_m4 { margin: 4px; }
  .lt_mt4 { margin-top: 4px; }
  .lt_ml4 { margin-left: 4px; }
  .lt_mr4 { margin-right: 4px; }
  .lt_mb4 { margin-bottom: 4px; }
  .lt_m5 { margin: 5px; }
  .lt_mt5 { margin-top: 5px; }
  .lt_ml5 { margin-left: 5px; }
  .lt_mr5 { margin-right: 5px; }
  .lt_mb5 { margin-bottom: 5px; }
  .lt_m7 { margin: 7px; }
  .lt_mt7 { margin-top: 7px; }
  .lt_ml7 { margin-left: 7px; }
  .lt_mr7 { margin-right: 7px; }
  .lt_mb7 { margin-bottom: 7px; }
  .lt_m10 { margin: 10px; }
  .lt_mt10 { margin-top: 10px; }
  .lt_ml10 { margin-left: 10px; }
  .lt_mr10 { margin-right: 10px; }
  .lt_mb10 { margin-bottom: 10px; }
  .lt_m13 { margin: 13px; }
  .lt_mt13 { margin-top: 13px; }
  .lt_ml13 { margin-left: 13px; }
  .lt_mr13 { margin-right: 13px; }
  .lt_mb13 { margin-bottom: 13px; }
  .lt_m15 { margin: 15px; }
  .lt_mt15 { margin-top: 15px; }
  .lt_ml15 { margin-left: 15px; }
  .lt_mr15 { margin-right: 15px; }
  .lt_mb15 { margin-bottom: 15px; }
  .lt_m20 { margin: 20px; }
  .lt_mt20 { margin-top: 20px; }
  .lt_ml20 { margin-left: 20px; }
  .lt_mr20 { margin-right: 20px; }
  .lt_mb20 { margin-bottom: 20px; }
  .lt_m25 { margin: 25px; }
  .lt_mt25 { margin-top: 25px; }
  .lt_ml25 { margin-left: 25px; }
  .lt_mr25 { margin-right: 25px; }
  .lt_mb25 { margin-bottom: 25px; }
  .lt_m30 { margin: 30px; }
  .lt_mt30 { margin-top: 30px; }
  .lt_ml30 { margin-left: 30px; }
  .lt_mr30 { margin-right: 30px; }
  .lt_mb30 { margin-bottom: 30px; }
  .lt_m35 { margin: 35px; }
  .lt_mt35 { margin-top: 35px; }
  .lt_ml35 { margin-left: 35px; }
  .lt_mr35 { margin-right: 35px; }
  .lt_mb35 { margin-bottom: 35px; }
  .lt_m-1 { margin: -1px; }
  .lt_mt-1 { margin-top: -1px; }
  .lt_ml-1 { margin-left: -1px; }
  .lt_mr-1 { margin-right: -1px; }
  .lt_mb-1 { margin-bottom: -1px; }
  .lt_m-2 { margin: -2px; }
  .lt_mt-2 { margin-top: -2px; }
  .lt_ml-2 { margin-left: -2px; }
  .lt_mr-2 { margin-right: -2px; }
  .lt_mb-2 { margin-bottom: -2px; }
  .lt_m-3 { margin: -3px; }
  .lt_mt-3 { margin-top: -3px; }
  .lt_ml-3 { margin-left: -3px; }
  .lt_mr-3 { margin-right: -3px; }
  .lt_mb-3 { margin-bottom: -3px; }
  .lt_m-4 { margin: -4px; }
  .lt_mt-4 { margin-top: -4px; }
  .lt_ml-4 { margin-left: -4px; }
  .lt_mr-4 { margin-right: -4px; }
  .lt_mb-4 { margin-bottom: -4px; }
  .lt_m-5 { margin: -5px; }
  .lt_mt-5 { margin-top: -5px; }
  .lt_ml-5 { margin-left: -5px; }
  .lt_mr-5 { margin-right: -5px; }
  .lt_mb-5 { margin-bottom: -5px; }
  .lt_m-7 { margin: -7px; }
  .lt_mt-7 { margin-top: -7px; }
  .lt_ml-7 { margin-left: -7px; }
  .lt_mr-7 { margin-right: -7px; }
  .lt_mb-7 { margin-bottom: -7px; }
  .lt_m-10 { margin: -10px; }
  .lt_mt-10 { margin-top: -10px; }
  .lt_ml-10 { margin-left: -10px; }
  .lt_mr-10 { margin-right: -10px; }
  .lt_mb-10 { margin-bottom: -10px; }
  .lt_m-13 { margin: -13px; }
  .lt_mt-13 { margin-top: -13px; }
  .lt_ml-13 { margin-left: -13px; }
  .lt_mr-13 { margin-right: -13px; }
  .lt_mb-13 { margin-bottom: -13px; }
  .lt_m-15 { margin: -15px; }
  .lt_mt-15 { margin-top: -15px; }
  .lt_ml-15 { margin-left: -15px; }
  .lt_mr-15 { margin-right: -15px; }
  .lt_mb-15 { margin-bottom: -15px; }
  .lt_m-20 { margin: -20px; }
  .lt_mt-20 { margin-top: -20px; }
  .lt_ml-20 { margin-left: -20px; }
  .lt_mr-20 { margin-right: -20px; }
  .lt_mb-20 { margin-bottom: -20px; }
  .lt_m-25 { margin: -25px; }
  .lt_mt-25 { margin-top: -25px; }
  .lt_ml-25 { margin-left: -25px; }
  .lt_mr-25 { margin-right: -25px; }
  .lt_mb-25 { margin-bottom: -25px; }
  .lt_m-30 { margin: -30px; }
  .lt_mt-30 { margin-top: -30px; }
  .lt_ml-30 { margin-left: -30px; }
  .lt_mr-30 { margin-right: -30px; }
  .lt_mb-30 { margin-bottom: -30px; }
  .lt_m-35 { margin: -35px; }
  .lt_mt-35 { margin-top: -35px; }
  .lt_ml-35 { margin-left: -35px; }
  .lt_mr-35 { margin-right: -35px; }
  .lt_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .lt_p0 { padding: 0 !important; }
  .lt_pt0 { padding-top: 0 !important; }
  .lt_pl0 { padding-left: 0 !important; }
  .lt_pr0 { padding-right: 0 !important; }
  .lt_pb0 { padding-bottom: 0 !important; }
  .lt_p1 { padding: 1px !important; }
  .lt_pt1 { padding-top: 1px !important; }
  .lt_pl1 { padding-left: 1px !important; }
  .lt_pr1 { padding-right: 1px !important; }
  .lt_pb1 { padding-bottom: 1px !important; }
  .lt_p2 { padding: 2px !important; }
  .lt_pt2 { padding-top: 2px !important; }
  .lt_pl2 { padding-left: 2px !important; }
  .lt_pr2 { padding-right: 2px !important; }
  .lt_pb2 { padding-bottom: 2px !important; }
  .lt_p3 { padding: 3px !important; }
  .lt_pt3 { padding-top: 3px !important; }
  .lt_pl3 { padding-left: 3px !important; }
  .lt_pr3 { padding-right: 3px !important; }
  .lt_pb3 { padding-bottom: 3px !important; }
  .lt_p4 { padding: 4px !important; }
  .lt_pt4 { padding-top: 4px !important; }
  .lt_pl4 { padding-left: 4px !important; }
  .lt_pr4 { padding-right: 4px !important; }
  .lt_pb4 { padding-bottom: 4px !important; }
  .lt_p5 { padding: 5px !important; }
  .lt_pt5 { padding-top: 5px !important; }
  .lt_pl5 { padding-left: 5px !important; }
  .lt_pr5 { padding-right: 5px !important; }
  .lt_pb5 { padding-bottom: 5px !important; }
  .lt_p7 { padding: 7px !important; }
  .lt_pt7 { padding-top: 7px !important; }
  .lt_pl7 { padding-left: 7px !important; }
  .lt_pr7 { padding-right: 7px !important; }
  .lt_pb7 { padding-bottom: 7px !important; }
  .lt_p10 { padding: 10px !important; }
  .lt_pt10 { padding-top: 10px !important; }
  .lt_pl10 { padding-left: 10px !important; }
  .lt_pr10 { padding-right: 10px !important; }
  .lt_pb10 { padding-bottom: 10px !important; }
  .lt_p13 { padding: 13px !important; }
  .lt_pt13 { padding-top: 13px !important; }
  .lt_pl13 { padding-left: 13px !important; }
  .lt_pr13 { padding-right: 13px !important; }
  .lt_pb13 { padding-bottom: 13px !important; }
  .lt_p15 { padding: 15px !important; }
  .lt_pt15 { padding-top: 15px !important; }
  .lt_pl15 { padding-left: 15px !important; }
  .lt_pr15 { padding-right: 15px !important; }
  .lt_pb15 { padding-bottom: 15px !important; }
  .lt_p20 { padding: 20px !important; }
  .lt_pt20 { padding-top: 20px !important; }
  .lt_pl20 { padding-left: 20px !important; }
  .lt_pr20 { padding-right: 20px !important; }
  .lt_pb20 { padding-bottom: 20px !important; }
  .lt_p25 { padding: 25px !important; }
  .lt_pt25 { padding-top: 25px !important; }
  .lt_pl25 { padding-left: 25px !important; }
  .lt_pr25 { padding-right: 25px !important; }
  .lt_pb25 { padding-bottom: 25px !important; }
  .lt_p30 { padding: 30px !important; }
  .lt_pt30 { padding-top: 30px !important; }
  .lt_pl30 { padding-left: 30px !important; }
  .lt_pr30 { padding-right: 30px !important; }
  .lt_pb30 { padding-bottom: 30px !important; }
  .lt_p35 { padding: 35px !important; }
  .lt_pt35 { padding-top: 35px !important; }
  .lt_pl35 { padding-left: 35px !important; }
  .lt_pr35 { padding-right: 35px !important; }
  .lt_pb35 { padding-bottom: 35px !important; }

  .lt_pt10p { padding-top: 10% !important; }
  .lt_pt15p { padding-top: 15% !important; }
  .lt_pt20p { padding-top: 20% !important; }
  .lt_pt25p { padding-top: 25% !important; }
  .lt_pt33p { padding-top: 33.33333333% !important; }
  .lt_pt35p { padding-top: 35% !important; }
  .lt_pt40p { padding-top: 40% !important; }
  .lt_pt50p { padding-top: 50% !important; }
  .lt_pt60p { padding-top: 60% !important; }
  .lt_pt65p { padding-top: 65% !important; }
  .lt_pt66p { padding-top: 66.66666666% !important; }
  .lt_pt70p { padding-top: 70% !important; }
  .lt_pt75p { padding-top: 75% !important; }
  .lt_pt80p { padding-top: 80% !important; }
  .lt_pt85p { padding-top: 85% !important; }
  .lt_pt90p { padding-top: 90% !important; }
  .lt_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .lt_vat { vertical-align: top; }
  .lt_vam { vertical-align: middle; }
  .lt_vab { vertical-align: bottom; }
  .lt_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .lt_tal { text-align: left; }
  .lt_tal_i { text-align: left !important; }
  .lt_tac { text-align: center; }
  .lt_tac_i { text-align: center !important; }
  .lt_tar { text-align: right; }
  .lt_tar_i { text-align: right !important; }
  .lt_taj { text-align: justify; }
  .lt_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .lt_fsi { font-style: italic; }

  /* Font size */
  .lt_fz0 { font-size: 0; }
  .lt_fz0_i { font-size: 0 !important; }
  .lt_fz10 { font-size: 0.625em; }
  .lt_fz11 { font-size: 0.6875em; }
  .lt_fz12 { font-size: 0.75em; }
  .lt_fz14 { font-size: 0.875em; }
  .lt_fz15 { font-size: 0.9375em; }
  .lt_fz16 { font-size: 1em; }
  .lt_fz18 { font-size: 1.125em; }
  .lt_fz20 { font-size: 1.25em; }
  .lt_fz22 { font-size: 1.375em; }
  .lt_fz24 { font-size: 1.5em; }
  .lt_fz26 { font-size: 1.625em; }
  .lt_fz28 { font-size: 1.75em; }
  .lt_fz30 { font-size: 1.875em; }
  .lt_fz32 { font-size: 2em; }
  .lt_fz10_i { font-size: 0.625em !important; }
  .lt_fz11_i { font-size: 0.6875em !important; }
  .lt_fz12_i { font-size: 0.75em !important; }
  .lt_fz14_i { font-size: 0.875em !important; }
  .lt_fz15_i { font-size: 0.9375em !important; }
  .lt_fz16_i { font-size: 1em !important; }
  .lt_fz18_i { font-size: 1.125em !important; }
  .lt_fz20_i { font-size: 1.25em !important; }
  .lt_fz22_i { font-size: 1.375em !important; }
  .lt_fz24_i { font-size: 1.5em !important; }
  .lt_fz26_i { font-size: 1.625em !important; }
  .lt_fz28_i { font-size: 1.75em !important; }
  .lt_fz30_i { font-size: 1.875em !important; }
  .lt_fz32_i { font-size: 2em !important; }
  .lt_fz10r { font-size: 0.625rem; }
  .lt_fz11r { font-size: 0.6875rem; }
  .lt_fz12r { font-size: 0.75rem; }
  .lt_fz14r { font-size: 0.875rem; }
  .lt_fz15r { font-size: 0.9375rem; }
  .lt_fz16r { font-size: 1rem; }
  .lt_fz18r { font-size: 1.125rem; }
  .lt_fz20r { font-size: 1.25rem; }
  .lt_fz22r { font-size: 1.375rem; }
  .lt_fz24r { font-size: 1.5rem; }
  .lt_fz26r { font-size: 1.625rem; }
  .lt_fz28r { font-size: 1.75rem; }
  .lt_fz30r { font-size: 1.875rem; }
  .lt_fz32r { font-size: 2rem; }
  .lt_fz10r_i { font-size: 0.625rem !important; }
  .lt_fz11r_i { font-size: 0.6875rem !important; }
  .lt_fz12r_i { font-size: 0.75rem !important; }
  .lt_fz14r_i { font-size: 0.875rem !important; }
  .lt_fz15r_i { font-size: 0.9375rem !important; }
  .lt_fz16r_i { font-size: 1rem !important; }
  .lt_fz18r_i { font-size: 1.125rem !important; }
  .lt_fz20r_i { font-size: 1.25rem !important; }
  .lt_fz22r_i { font-size: 1.375rem !important; }
  .lt_fz24r_i { font-size: 1.5rem !important; }
  .lt_fz26r_i { font-size: 1.625rem !important; }
  .lt_fz28r_i { font-size: 1.75rem !important; }
  .lt_fz30r_i { font-size: 1.875rem !important; }
  .lt_fz32r_i { font-size: 2rem !important; }
  .lt_fz10px { font-size: 10px; }
  .lt_fz11px { font-size: 11px; }
  .lt_fz12px { font-size: 12px; }
  .lt_fz14px { font-size: 14px; }
  .lt_fz15px { font-size: 15px; }
  .lt_fz16px { font-size: 16px; }
  .lt_fz18px { font-size: 18px; }
  .lt_fz20px { font-size: 20px; }
  .lt_fz22px { font-size: 22px; }
  .lt_fz24px { font-size: 24px; }
  .lt_fz26px { font-size: 26px; }
  .lt_fz28px { font-size: 28px; }
  .lt_fz30px { font-size: 30px; }
  .lt_fz32px { font-size: 32px; }
  .lt_fz10px_i { font-size: 10px !important; }
  .lt_fz11px_i { font-size: 11px !important; }
  .lt_fz12px_i { font-size: 12px !important; }
  .lt_fz14px_i { font-size: 14px !important; }
  .lt_fz15px_i { font-size: 15px !important; }
  .lt_fz16px_i { font-size: 16px !important; }
  .lt_fz18px_i { font-size: 18px !important; }
  .lt_fz20px_i { font-size: 20px !important; }
  .lt_fz22px_i { font-size: 22px !important; }
  .lt_fz24px_i { font-size: 24px !important; }
  .lt_fz26px_i { font-size: 26px !important; }
  .lt_fz28px_i { font-size: 28px !important; }
  .lt_fz30px_i { font-size: 30px !important; }
  .lt_fz32px_i { font-size: 32px !important; }

  /* Font weight */
  .lt_fw700, .lt_fw700_a, .lt_fw700_a *, .lt_bold, .lt_bold_a, .lt_bold_a * { font-weight: 700; }
  .lt_fw700_i, .lt_fw700_a_i, .lt_fw700_a_i *, .lt_bold_i, .lt_bold_a_i, .lt_bold_a_i * { font-weight: 700 !important; }
  .lt_fw600, .lt_fw600_a, .lt_fw600_a *, .lt_semibold, .lt_semibold_a, .lt_semibold_a * { font-weight: 600; }
  .lt_fw600_i, .lt_fw600_a_i, .lt_fw600_a_i *, .lt_semibold_i, .lt_semibold_a_i, .lt_semibold_a_i * { font-weight: 600 !important; }
  .lt_fw500, .lt_fw500_a, .lt_fw500_a * { font-weight: 500; }
  .lt_fw500_i, .lt_fw500_a_i, .lt_fw500_a_i * { font-weight: 500 !important; }
  .lt_fw400, .lt_fw400_a, .lt_fw400_a * { font-weight: 400; }
  .lt_fw400_i, .lt_fw400_a_i, .lt_fw400_a_i * { font-weight: 400 !important; }


  /* Floating */
  /* ======== */
  .lt_fln { float: none; }
  .lt_fll { float: left; }
  .lt_flr { float: right; }
}
@media screen and (max-width: 1023px) {
  .menu-is-opened {
    position: relative;
    overflow: hidden !important;
  }


  .header:after {
    content: "";
    display: block;
    background: #1a580d;
    height: 2px;
  }
    .header-content {
      padding-left: 50px;
    }
      .header-logo {
        line-height: 1;
        padding-left: 15px;
      }
        .header-logo img {
          width: 100%;
        }

      .header-slogan,
      .header-logo + .header-slogan {
        padding-left: 15px;
      }

    .header-menu-container {
      background: none;
      width: max-content;
      max-width: 100%;
      padding: 0px 43px 0px 0px;
      position: fixed;
      top: 0px;
      /*left: 43px;*/
      right: 100%;
      bottom: 0px;
      z-index: 99;

      -webkit-transition: right .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
      -moz-transition: right .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
      -o-transition: right .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
      transition: right .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
    }
      .menu-is-opened .header-menu-container {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
      }

      .header-menu-button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 0px;
        padding: 10px 0px;
        position: absolute;
        top: 0px;
        left: 10px;
        bottom: 0px;
        z-index: 96;
      }
        .header-menu-button-container.fixed {
          bottom: unset;
          position: fixed;
          padding-bottom: 0px;
        }

        .header-menu-button-container:before {
          content: "";
          display: block;
          background: #000;
          position: fixed;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 97;

          opacity: 0;
          visibility: hidden;

          -webkit-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
          -moz-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
          -o-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
          transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out .3s;
        }
          .menu-is-opened .header-menu-button-container:before {
            opacity: .6;
            visibility: visible;

            -webkit-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
            -moz-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
            -o-transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
            transition: opacity .3s ease-in-out 0s, visibility 0s ease-in-out 0s;
          }

        .header-menu-button,
        .header-menu-button-close {
          display: block;
          background: #1a580d;
          width: 43px;
          min-width: 43px;
          padding: 5px 8px;
          cursor: pointer;
          position: relative;
        }

          .header-menu-button > span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            position: relative;
          }
            .header-menu-button > span:before,
            .header-menu-button > span:after,
            .header-menu-button > span > span {
              content: "";
              background: #fff;
              border-radius: 9px;
              height: 3px;
              position: relative;
            }
            .header-menu-button > span:before,
            .header-menu-button > span:after {
              margin: 6px 0px;
            }

      .header-menu-button-close {
        padding: 20px 8px 23px;
        position: absolute;
        top: 10px;
        right: 0px;
        z-index: 98;
      }
        .header-menu-button-close:before,
        .header-menu-button-close:after {
          content: "";
          display: block;
          background: #fff;
          height: 3px;
          margin: 0px -2px -3px -2px;

          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        .header-menu-button-close:after {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

      .header-menu-container > ul {
        display: block;
        background: #f5f5f5;
        border: solid #f5f5f5;
        border-width: 15px 0px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,.5);
        height: 100%;
        padding: 0px 10px;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 98;
      }
        .header-menu-container ul > li,
        .header-menu-container > ul > li.with-groups > ul > li,
        .header-menu-container > ul > li.with-groups > ul > li + li {
          border: none;
          padding: 0px;
        }
        .header-menu-container > ul > li.icn-menu-custom1 {
          display: initial;
        }
          .header-menu-container > ul li > div > .dropmarker,
          .header-menu-container > ul > li ul > li > div > .dropmarker {
            display: block;
            width: 41px;
            min-width: 41px;
            margin: 0px;
            cursor: pointer;

            -webkit-transition: background .2s ease-in-out 0s;
            -moz-transition: background .2s ease-in-out 0s;
            -o-transition: background .2s ease-in-out 0s;
            transition: background .2s ease-in-out 0s;
          }
            .header-menu-container > ul li.with-active > div > .dropmarker {
              background-color: rgba(51,51,51,.06);
            }
            .header-menu-container > ul li.is-hovered > div > .dropmarker {
              background-color: rgba(51,51,51,.03);
            }

            .header-menu-container > ul li > div > .dropmarker:before {
              background: url(/i/header-menu-dropdown--black.svg) no-repeat 0px 50%/contain transparent;
              width: 12px;

              -webkit-transition: -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
              -moz-transition: -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
              -o-transition: -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
              transition: -webkit-transform .25s ease-in-out 0s, -moz-transform .25s ease-in-out 0s, -ms-transform .25s ease-in-out 0s, -o-transform .25s ease-in-out 0s, transform .25s ease-in-out 0s;
            }
              .header-menu-container > ul li.is-hovered > div > .dropmarker:before {
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
              }

          .header-menu-container > ul li > div,
          .header-menu-container > ul > li > ul > li.icn-menu-service-group-null > div {
            display: flex;
            text-align: left;
          }
          .header-menu-container > ul li + li > div {
            border-top: 1px solid rgba(51,51,51,.4);
          }
            .header-menu-container > ul li > div:before {
              display: none;
            }

            .header-menu-container > ul li > div > div {
              width: 100%;
            }
              .header-menu-container > ul li > div a,
              .header-menu-container > ul li > div > div > span,
              .header-menu-container > ul > li ul > li > div a,
              .header-menu-container > ul > li ul > li > div > div > span,
              .header-menu-container > ul > li.with-groups > ul > li > div a,
              .header-menu-container > ul > li.with-groups > ul > li > div > div > span {
                color: #000;
                font-weight: 500;
                text-align: left;
                white-space: normal;
                padding: 10px 40px 10px 2px;

                -webkit-transition: none;
                -moz-transition: none;
                -o-transition: none;
                transition: none;
              }
                .header-menu-container > ul li > div > div > span {
                  cursor: pointer;
                }

                .header-menu-container > ul > li ul > li > div a:before,
                .header-menu-container > ul > li.with-groups > ul > li > div a:after,
                .header-menu-container > ul > li.with-groups > ul > li > div > div > span:after {
                  display: none;
                }

          .header-menu-container > ul > li > ul,
          .header-menu-container > ul > li:hover > ul,
          .header-menu-container > ul > li.with-groups > ul,
          .header-menu-container > ul > li > ul > li:not(.icn-menu-service-group-null) > ul,
          .header-menu-container li.group-cols-2 > ul,
          .header-menu-container li.group-cols-3 > ul,
          .header-menu-container li.group-cols-4 > ul {
            display: none;
            background: rgba(51,51,51,.03);
            border: none;
            box-shadow: none;
            top: unset;
            left: unset;
            min-width: unset;
            padding: 0px 0px 0px 10px;
            margin: 0px 0px 15px;
            position: unset;
            overflow: hidden;
            visibility: visible;
            opacity: 1;

            -webkit-transition: none;
            -moz-transition: none;
            -o-transition: none;
            transition: none;
          }


  .team-member-block-container .list-view-item-image {
    max-width: 33.33333333%;
    min-width: 33.33333333%;
  }



  .footer {
    padding: 0px;
  }
    .footer-content-wrapper {
      display: none;
    }


    .footer-copyrights {
      border: none;
    }



  .footer-whatsapp {
    left: -10px;
    right: -10px;
  }
}

/* portrait tablet - pt */
@media screen and (max-width: 979px) {
  /* Line height */
  /* =========== */
  .pt_lh10 { line-height: 1; }
  .pt_lh100p { line-height: 100%; }
  .pt_lh11 { line-height: 1.1; }
  .pt_lh110p { line-height: 110%; }
  .pt_lh12 { line-height: 1.2; }
  .pt_lh120p { line-height: 120%; }
  .pt_lh13 { line-height: 1.3; }
  .pt_lh130p { line-height: 130%; }
  .pt_lh14 { line-height: 1.4; }
  .pt_lh140p { line-height: 140%; }
  .pt_lh15 { line-height: 1.5; }
  .pt_lh150p { line-height: 150%; }
  .pt_lh16 { line-height: 1.6; }
  .pt_lh160p { line-height: 160%; }
  .pt_lh17 { line-height: 1.7; }
  .pt_lh170p { line-height: 170%; }
  .pt_lh18 { line-height: 1.8; }
  .pt_lh180p { line-height: 180%; }
  .pt_lh19 { line-height: 1.9; }
  .pt_lh190p { line-height: 190%; }
  .pt_lh20 { line-height: 2; }
  .pt_lh200p { line-height: 200%; }


  /* Display */
  /* ======= */
  .pt_dn { display: none; }
  .pt_dn_i { display: none !important; }
  .pt_db { display: block; }
  .pt_di { display: inline; }
  .pt_dib { display: inline-block; }
  .pt_dt { display: table; }
  .pt_dtr { display: table-row; }
  .pt_dtc { display: table-cell; }

  /* Flex */
  .pt_dfdr { display: flex; flex-direction: row; }
  .pt_dfdrr { display: flex; flex-direction: row-reverse; }
  .pt_dfdc { display: flex; flex-direction: column; }
  .pt_dfdcr { display: flex; flex-direction: column-reverse; }
  .pt_difdr { display: inline-flex; flex-direction: row; }
  .pt_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .pt_difdc { display: inline-flex; flex-direction: column; }
  .pt_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .pt_fwn { flex-wrap: nowrap; }
  .pt_fww { flex-wrap: wrap; }
  .pt_fwwr { flex-wrap: wrap-reverse; }
  .pt_fjcs { justify-content: flex-start; }
  .pt_fjce { justify-content: flex-end; }
  .pt_fjcc { justify-content: center; }
  .pt_fjcsa { justify-content: space-around; }
  .pt_fjcsb { justify-content: space-between; }
  .pt_fais { align-items: flex-start; }
  .pt_faie { align-items: flex-end; }
  .pt_faic { align-items: center; }
  .pt_faib { align-items: baseline; }
  .pt_faist { align-items: stretch; }
  .pt_facs { align-content: flex-start; }
  .pt_face { align-content: flex-end; }
  .pt_facc { align-content: center; }
  .pt_facsa { align-content: space-around; }
  .pt_facsb { align-content: space-between; }
  .pt_facst { align-content: stretch; }
  .pt_fass { align-self: flex-start; }
  .pt_fase { align-self: flex-end; }
  .pt_fasc { align-self: center; }
  .pt_fasb { align-self: baseline; }
  .pt_fasst { align-self: stretch; }


  /* Height */
  /* ====== */
  .pt_h0 { height: 0; }
  .pt_h100p, .pt_hfull { height: 100%; }
  .pt_hauto { height: auto; }


  /* Width */
  /* ===== */
  .pt_w0 { width: 0; }
  .pt_w1 { width: 1px; }
  .pt_w5p { width: 5%; }
  .pt_w7p { width: 7%; }
  .pt_w10p { width: 10%; }
  .pt_w12p { width: 12.5%; }
  .pt_w15p { width: 15%; }
  .pt_w20p { width: 20%; }
  .pt_w25p { width: 25%; }
  .pt_w33p { width: 33.33333333%; }
  .pt_w35p { width: 35%; }
  .pt_w40p { width: 40%; }
  .pt_w50p { width: 50%; }
  .pt_w60p { width: 60%; }
  .pt_w65p { width: 65%; }
  .pt_w66p { width: 66.66666666%; }
  .pt_w70p { width: 70%; }
  .pt_w75p { width: 75%; }
  .pt_w80p { width: 80%; }
  .pt_w85p { width: 85%; }
  .pt_w88p { width: 87.5%; }
  .pt_w90p { width: 90%; }
  .pt_w93p { width: 93%; }
  .pt_w95p { width: 95%; }
  .pt_w100p, .pt_wfull { width: 100%; }
  .pt_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .pt_m0auto, .pt_m0a { margin: 0 auto; }
  .pt_m0auto_i, .pt_m0a_i { margin: 0 auto !important; }
  .pt_mauto0, .pt_ma0 { margin: auto 0; }
  .pt_mauto0_i, .pt_ma0_i { margin: auto 0 !important; }
  .pt_mauto, .pt_ma { margin: auto; }
  .pt_mauto_i, .pt_ma_i { margin: auto !important; }
  .pt_mtauto, .pt_mta { margin-top: auto; }
  .pt_mtauto_i, .pt_mta_i { margin-top: auto !important; }
  .pt_mlauto, .pt_mla { margin-left: auto; }
  .pt_mlauto_i, .pt_mla_i { margin-left: auto !important; }
  .pt_mrauto, .pt_mra { margin-right: auto; }
  .pt_mrauto_i, .pt_mra_i { margin-right: auto !important; }
  .pt_mbauto, .pt_mba { margin-bottom: auto; }
  .pt_mbauto_i, .pt_mba_i { margin-bottom: auto !important; }
  .pt_m0 { margin: 0; }
  .pt_m0_i { margin: 0 !important; }
  .pt_mt0 { margin-top: 0; }
  .pt_mt0_i { margin-top: 0 !important; }
  .pt_ml0 { margin-left: 0; }
  .pt_ml0_i { margin-left: 0 !important; }
  .pt_mr0 { margin-right: 0; }
  .pt_mr0_i { margin-right: 0 !important; }
  .pt_mb0 { margin-bottom: 0; }
  .pt_mb0_i { margin-bottom: 0 !important; }
  .pt_m1 { margin: 1px; }
  .pt_mt1 { margin-top: 1px; }
  .pt_ml1 { margin-left: 1px; }
  .pt_mr1 { margin-right: 1px; }
  .pt_mb1 { margin-bottom: 1px; }
  .pt_m2 { margin: 2px; }
  .pt_mt2 { margin-top: 2px; }
  .pt_ml2 { margin-left: 2px; }
  .pt_mr2 { margin-right: 2px; }
  .pt_mb2 { margin-bottom: 2px; }
  .pt_m3 { margin: 3px; }
  .pt_mt3 { margin-top: 3px; }
  .pt_ml3 { margin-left: 3px; }
  .pt_mr3 { margin-right: 3px; }
  .pt_mb3 { margin-bottom: 3px; }
  .pt_m4 { margin: 4px; }
  .pt_mt4 { margin-top: 4px; }
  .pt_ml4 { margin-left: 4px; }
  .pt_mr4 { margin-right: 4px; }
  .pt_mb4 { margin-bottom: 4px; }
  .pt_m5 { margin: 5px; }
  .pt_mt5 { margin-top: 5px; }
  .pt_ml5 { margin-left: 5px; }
  .pt_mr5 { margin-right: 5px; }
  .pt_mb5 { margin-bottom: 5px; }
  .pt_m7 { margin: 7px; }
  .pt_mt7 { margin-top: 7px; }
  .pt_ml7 { margin-left: 7px; }
  .pt_mr7 { margin-right: 7px; }
  .pt_mb7 { margin-bottom: 7px; }
  .pt_m10 { margin: 10px; }
  .pt_mt10 { margin-top: 10px; }
  .pt_ml10 { margin-left: 10px; }
  .pt_mr10 { margin-right: 10px; }
  .pt_mb10 { margin-bottom: 10px; }
  .pt_m13 { margin: 13px; }
  .pt_mt13 { margin-top: 13px; }
  .pt_ml13 { margin-left: 13px; }
  .pt_mr13 { margin-right: 13px; }
  .pt_mb13 { margin-bottom: 13px; }
  .pt_m15 { margin: 15px; }
  .pt_mt15 { margin-top: 15px; }
  .pt_ml15 { margin-left: 15px; }
  .pt_mr15 { margin-right: 15px; }
  .pt_mb15 { margin-bottom: 15px; }
  .pt_m20 { margin: 20px; }
  .pt_mt20 { margin-top: 20px; }
  .pt_ml20 { margin-left: 20px; }
  .pt_mr20 { margin-right: 20px; }
  .pt_mb20 { margin-bottom: 20px; }
  .pt_m25 { margin: 25px; }
  .pt_mt25 { margin-top: 25px; }
  .pt_ml25 { margin-left: 25px; }
  .pt_mr25 { margin-right: 25px; }
  .pt_mb25 { margin-bottom: 25px; }
  .pt_m30 { margin: 30px; }
  .pt_mt30 { margin-top: 30px; }
  .pt_ml30 { margin-left: 30px; }
  .pt_mr30 { margin-right: 30px; }
  .pt_mb30 { margin-bottom: 30px; }
  .pt_m35 { margin: 35px; }
  .pt_mt35 { margin-top: 35px; }
  .pt_ml35 { margin-left: 35px; }
  .pt_mr35 { margin-right: 35px; }
  .pt_mb35 { margin-bottom: 35px; }
  .pt_m-1 { margin: -1px; }
  .pt_mt-1 { margin-top: -1px; }
  .pt_ml-1 { margin-left: -1px; }
  .pt_mr-1 { margin-right: -1px; }
  .pt_mb-1 { margin-bottom: -1px; }
  .pt_m-2 { margin: -2px; }
  .pt_mt-2 { margin-top: -2px; }
  .pt_ml-2 { margin-left: -2px; }
  .pt_mr-2 { margin-right: -2px; }
  .pt_mb-2 { margin-bottom: -2px; }
  .pt_m-3 { margin: -3px; }
  .pt_mt-3 { margin-top: -3px; }
  .pt_ml-3 { margin-left: -3px; }
  .pt_mr-3 { margin-right: -3px; }
  .pt_mb-3 { margin-bottom: -3px; }
  .pt_m-4 { margin: -4px; }
  .pt_mt-4 { margin-top: -4px; }
  .pt_ml-4 { margin-left: -4px; }
  .pt_mr-4 { margin-right: -4px; }
  .pt_mb-4 { margin-bottom: -4px; }
  .pt_m-5 { margin: -5px; }
  .pt_mt-5 { margin-top: -5px; }
  .pt_ml-5 { margin-left: -5px; }
  .pt_mr-5 { margin-right: -5px; }
  .pt_mb-5 { margin-bottom: -5px; }
  .pt_m-7 { margin: -7px; }
  .pt_mt-7 { margin-top: -7px; }
  .pt_ml-7 { margin-left: -7px; }
  .pt_mr-7 { margin-right: -7px; }
  .pt_mb-7 { margin-bottom: -7px; }
  .pt_m-10 { margin: -10px; }
  .pt_mt-10 { margin-top: -10px; }
  .pt_ml-10 { margin-left: -10px; }
  .pt_mr-10 { margin-right: -10px; }
  .pt_mb-10 { margin-bottom: -10px; }
  .pt_m-13 { margin: -13px; }
  .pt_mt-13 { margin-top: -13px; }
  .pt_ml-13 { margin-left: -13px; }
  .pt_mr-13 { margin-right: -13px; }
  .pt_mb-13 { margin-bottom: -13px; }
  .pt_m-15 { margin: -15px; }
  .pt_mt-15 { margin-top: -15px; }
  .pt_ml-15 { margin-left: -15px; }
  .pt_mr-15 { margin-right: -15px; }
  .pt_mb-15 { margin-bottom: -15px; }
  .pt_m-20 { margin: -20px; }
  .pt_mt-20 { margin-top: -20px; }
  .pt_ml-20 { margin-left: -20px; }
  .pt_mr-20 { margin-right: -20px; }
  .pt_mb-20 { margin-bottom: -20px; }
  .pt_m-25 { margin: -25px; }
  .pt_mt-25 { margin-top: -25px; }
  .pt_ml-25 { margin-left: -25px; }
  .pt_mr-25 { margin-right: -25px; }
  .pt_mb-25 { margin-bottom: -25px; }
  .pt_m-30 { margin: -30px; }
  .pt_mt-30 { margin-top: -30px; }
  .pt_ml-30 { margin-left: -30px; }
  .pt_mr-30 { margin-right: -30px; }
  .pt_mb-30 { margin-bottom: -30px; }
  .pt_m-35 { margin: -35px; }
  .pt_mt-35 { margin-top: -35px; }
  .pt_ml-35 { margin-left: -35px; }
  .pt_mr-35 { margin-right: -35px; }
  .pt_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .pt_p0 { padding: 0 !important; }
  .pt_pt0 { padding-top: 0 !important; }
  .pt_pl0 { padding-left: 0 !important; }
  .pt_pr0 { padding-right: 0 !important; }
  .pt_pb0 { padding-bottom: 0 !important; }
  .pt_p1 { padding: 1px !important; }
  .pt_pt1 { padding-top: 1px !important; }
  .pt_pl1 { padding-left: 1px !important; }
  .pt_pr1 { padding-right: 1px !important; }
  .pt_pb1 { padding-bottom: 1px !important; }
  .pt_p2 { padding: 2px !important; }
  .pt_pt2 { padding-top: 2px !important; }
  .pt_pl2 { padding-left: 2px !important; }
  .pt_pr2 { padding-right: 2px !important; }
  .pt_pb2 { padding-bottom: 2px !important; }
  .pt_p3 { padding: 3px !important; }
  .pt_pt3 { padding-top: 3px !important; }
  .pt_pl3 { padding-left: 3px !important; }
  .pt_pr3 { padding-right: 3px !important; }
  .pt_pb3 { padding-bottom: 3px !important; }
  .pt_p4 { padding: 4px !important; }
  .pt_pt4 { padding-top: 4px !important; }
  .pt_pl4 { padding-left: 4px !important; }
  .pt_pr4 { padding-right: 4px !important; }
  .pt_pb4 { padding-bottom: 4px !important; }
  .pt_p5 { padding: 5px !important; }
  .pt_pt5 { padding-top: 5px !important; }
  .pt_pl5 { padding-left: 5px !important; }
  .pt_pr5 { padding-right: 5px !important; }
  .pt_pb5 { padding-bottom: 5px !important; }
  .pt_p7 { padding: 7px !important; }
  .pt_pt7 { padding-top: 7px !important; }
  .pt_pl7 { padding-left: 7px !important; }
  .pt_pr7 { padding-right: 7px !important; }
  .pt_pb7 { padding-bottom: 7px !important; }
  .pt_p10 { padding: 10px !important; }
  .pt_pt10 { padding-top: 10px !important; }
  .pt_pl10 { padding-left: 10px !important; }
  .pt_pr10 { padding-right: 10px !important; }
  .pt_pb10 { padding-bottom: 10px !important; }
  .pt_p13 { padding: 13px !important; }
  .pt_pt13 { padding-top: 13px !important; }
  .pt_pl13 { padding-left: 13px !important; }
  .pt_pr13 { padding-right: 13px !important; }
  .pt_pb13 { padding-bottom: 13px !important; }
  .pt_p15 { padding: 15px !important; }
  .pt_pt15 { padding-top: 15px !important; }
  .pt_pl15 { padding-left: 15px !important; }
  .pt_pr15 { padding-right: 15px !important; }
  .pt_pb15 { padding-bottom: 15px !important; }
  .pt_p20 { padding: 20px !important; }
  .pt_pt20 { padding-top: 20px !important; }
  .pt_pl20 { padding-left: 20px !important; }
  .pt_pr20 { padding-right: 20px !important; }
  .pt_pb20 { padding-bottom: 20px !important; }
  .pt_p25 { padding: 25px !important; }
  .pt_pt25 { padding-top: 25px !important; }
  .pt_pl25 { padding-left: 25px !important; }
  .pt_pr25 { padding-right: 25px !important; }
  .pt_pb25 { padding-bottom: 25px !important; }
  .pt_p30 { padding: 30px !important; }
  .pt_pt30 { padding-top: 30px !important; }
  .pt_pl30 { padding-left: 30px !important; }
  .pt_pr30 { padding-right: 30px !important; }
  .pt_pb30 { padding-bottom: 30px !important; }
  .pt_p35 { padding: 35px !important; }
  .pt_pt35 { padding-top: 35px !important; }
  .pt_pl35 { padding-left: 35px !important; }
  .pt_pr35 { padding-right: 35px !important; }
  .pt_pb35 { padding-bottom: 35px !important; }

  .pt_pt10p { padding-top: 10% !important; }
  .pt_pt15p { padding-top: 15% !important; }
  .pt_pt20p { padding-top: 20% !important; }
  .pt_pt25p { padding-top: 25% !important; }
  .pt_pt33p { padding-top: 33.33333333% !important; }
  .pt_pt35p { padding-top: 35% !important; }
  .pt_pt40p { padding-top: 40% !important; }
  .pt_pt50p { padding-top: 50% !important; }
  .pt_pt60p { padding-top: 60% !important; }
  .pt_pt65p { padding-top: 65% !important; }
  .pt_pt66p { padding-top: 66.66666666% !important; }
  .pt_pt70p { padding-top: 70% !important; }
  .pt_pt75p { padding-top: 75% !important; }
  .pt_pt80p { padding-top: 80% !important; }
  .pt_pt85p { padding-top: 85% !important; }
  .pt_pt90p { padding-top: 90% !important; }
  .pt_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .pt_vat { vertical-align: top; }
  .pt_vam { vertical-align: middle; }
  .pt_vab { vertical-align: bottom; }
  .pt_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .pt_tal { text-align: left; }
  .pt_tal_i { text-align: left !important; }
  .pt_tac { text-align: center; }
  .pt_tac_i { text-align: center !important; }
  .pt_tar { text-align: right; }
  .pt_tar_i { text-align: right !important; }
  .pt_taj { text-align: justify; }
  .pt_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .pt_fsi { font-style: italic; }

  /* Font size */
  .pt_fz0 { font-size: 0; }
  .pt_fz0_i { font-size: 0 !important; }
  .pt_fz10 { font-size: 0.625em; }
  .pt_fz11 { font-size: 0.6875em; }
  .pt_fz12 { font-size: 0.75em; }
  .pt_fz14 { font-size: 0.875em; }
  .pt_fz15 { font-size: 0.9375em; }
  .pt_fz16 { font-size: 1em; }
  .pt_fz18 { font-size: 1.125em; }
  .pt_fz20 { font-size: 1.25em; }
  .pt_fz22 { font-size: 1.375em; }
  .pt_fz24 { font-size: 1.5em; }
  .pt_fz26 { font-size: 1.625em; }
  .pt_fz28 { font-size: 1.75em; }
  .pt_fz30 { font-size: 1.875em; }
  .pt_fz32 { font-size: 2em; }
  .pt_fz10_i { font-size: 0.625em !important; }
  .pt_fz11_i { font-size: 0.6875em !important; }
  .pt_fz12_i { font-size: 0.75em !important; }
  .pt_fz14_i { font-size: 0.875em !important; }
  .pt_fz15_i { font-size: 0.9375em !important; }
  .pt_fz16_i { font-size: 1em !important; }
  .pt_fz18_i { font-size: 1.125em !important; }
  .pt_fz20_i { font-size: 1.25em !important; }
  .pt_fz22_i { font-size: 1.375em !important; }
  .pt_fz24_i { font-size: 1.5em !important; }
  .pt_fz26_i { font-size: 1.625em !important; }
  .pt_fz28_i { font-size: 1.75em !important; }
  .pt_fz30_i { font-size: 1.875em !important; }
  .pt_fz32_i { font-size: 2em !important; }
  .pt_fz10r { font-size: 0.625rem; }
  .pt_fz11r { font-size: 0.6875rem; }
  .pt_fz12r { font-size: 0.75rem; }
  .pt_fz14r { font-size: 0.875rem; }
  .pt_fz15r { font-size: 0.9375rem; }
  .pt_fz16r { font-size: 1rem; }
  .pt_fz18r { font-size: 1.125rem; }
  .pt_fz20r { font-size: 1.25rem; }
  .pt_fz22r { font-size: 1.375rem; }
  .pt_fz24r { font-size: 1.5rem; }
  .pt_fz26r { font-size: 1.625rem; }
  .pt_fz28r { font-size: 1.75rem; }
  .pt_fz30r { font-size: 1.875rem; }
  .pt_fz32r { font-size: 2rem; }
  .pt_fz10r_i { font-size: 0.625rem !important; }
  .pt_fz11r_i { font-size: 0.6875rem !important; }
  .pt_fz12r_i { font-size: 0.75rem !important; }
  .pt_fz14r_i { font-size: 0.875rem !important; }
  .pt_fz15r_i { font-size: 0.9375rem !important; }
  .pt_fz16r_i { font-size: 1rem !important; }
  .pt_fz18r_i { font-size: 1.125rem !important; }
  .pt_fz20r_i { font-size: 1.25rem !important; }
  .pt_fz22r_i { font-size: 1.375rem !important; }
  .pt_fz24r_i { font-size: 1.5rem !important; }
  .pt_fz26r_i { font-size: 1.625rem !important; }
  .pt_fz28r_i { font-size: 1.75rem !important; }
  .pt_fz30r_i { font-size: 1.875rem !important; }
  .pt_fz32r_i { font-size: 2rem !important; }
  .pt_fz10px { font-size: 10px; }
  .pt_fz11px { font-size: 11px; }
  .pt_fz12px { font-size: 12px; }
  .pt_fz14px { font-size: 14px; }
  .pt_fz15px { font-size: 15px; }
  .pt_fz16px { font-size: 16px; }
  .pt_fz18px { font-size: 18px; }
  .pt_fz20px { font-size: 20px; }
  .pt_fz22px { font-size: 22px; }
  .pt_fz24px { font-size: 24px; }
  .pt_fz26px { font-size: 26px; }
  .pt_fz28px { font-size: 28px; }
  .pt_fz30px { font-size: 30px; }
  .pt_fz32px { font-size: 32px; }
  .pt_fz10px_i { font-size: 10px !important; }
  .pt_fz11px_i { font-size: 11px !important; }
  .pt_fz12px_i { font-size: 12px !important; }
  .pt_fz14px_i { font-size: 14px !important; }
  .pt_fz15px_i { font-size: 15px !important; }
  .pt_fz16px_i { font-size: 16px !important; }
  .pt_fz18px_i { font-size: 18px !important; }
  .pt_fz20px_i { font-size: 20px !important; }
  .pt_fz22px_i { font-size: 22px !important; }
  .pt_fz24px_i { font-size: 24px !important; }
  .pt_fz26px_i { font-size: 26px !important; }
  .pt_fz28px_i { font-size: 28px !important; }
  .pt_fz30px_i { font-size: 30px !important; }
  .pt_fz32px_i { font-size: 32px !important; }

  /* Font weight */
  .pt_fw700, .pt_fw700_a, .pt_fw700_a *, .pt_bold, .pt_bold_a, .pt_bold_a * { font-weight: 700; }
  .pt_fw700_i, .pt_fw700_a_i, .pt_fw700_a_i *, .pt_bold_i, .pt_bold_a_i, .pt_bold_a_i * { font-weight: 700 !important; }
  .pt_fw600, .pt_fw600_a, .pt_fw600_a *, .pt_semibold, .pt_semibold_a, .pt_semibold_a * { font-weight: 600; }
  .pt_fw600_i, .pt_fw600_a_i, .pt_fw600_a_i *, .pt_semibold_i, .pt_semibold_a_i, .pt_semibold_a_i * { font-weight: 600 !important; }
  .pt_fw500, .pt_fw500_a, .pt_fw500_a * { font-weight: 500; }
  .pt_fw500_i, .pt_fw500_a_i, .pt_fw500_a_i * { font-weight: 500 !important; }
  .pt_fw400, .pt_fw400_a, .pt_fw400_a * { font-weight: 400; }
  .pt_fw400_i, .pt_fw400_a_i, .pt_fw400_a_i * { font-weight: 400 !important; }


  /* Floating */
  /* ======== */
  .pt_fln { float: none; }
  .pt_fll { float: left; }
  .pt_flr { float: right; }
}
@media screen and (max-width: 979px) {
}

@media screen and (max-width: 899px) {
  html {
    font-size: max(1.77777778vw, 14px);
  }


  .header-contacts {
    flex-grow: 1;
  }
    .header-address-container {
      display: none;
    }


  .button-style {
    font-size: max(1.55555555vw, max(0.8125em, 13px));
  }
  .price-button {
    font-size: max(2vw, 16px);
  }


  .subheader {
    font-size: max(2.66666667vw, 1.28571428em);
  }
  .subsubheader,
  .price-table-group span {
    font-size: max(2.22222222vw, 1.07142857em);
  }
  .subsubsubheader {
    font-size: max(1.77777778vw, 1em);
  }


  .list-view-item {
    width: 33.33333333%;
  }


  .service-block-container .list-view-item-wrapper {
    min-height: 20.55555556vw;
  }

  .gallery-rotator-block-container.gallery-items-width-4 .list-view-item {
    width: 50%;
  }

    .service-block-container .list-view-item-name a,
    .service-block-container .list-view-item-name span,
    .gallery-rotator-block-container .list-view-item-name span,
    .gallery-rotator-block-container.gallery-items-width-4 .list-view-item-name span {
      font-size: max(2vw, 15px);
    }
      .service-block-container .list-view-item-name a > span,
      .service-block-container .list-view-item-name span > span {
        font-size: max(2.22222222vw, 16px);
      }

      .gallery-rotator-block-container.gallery-items-width-3 .list-view-item-name span {
        font-size: max(1.77777778vw, 14px);
      }


  .contact-info-container,
  .contact-info-container > div {
    display: block;
    min-width: unset;
    max-width: unset;
  }
    .contact-info-container > div > div {
      min-width: unset;
      max-width: unset;
    }

    .contact-info-container > div:first-child {
      padding: 0px 0px 25px;
    }
      .contact-info-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        background: none;
        box-shadow: none;
        width: unset;
        max-width: unset;
        padding: 0px;
        margin: -20px 0px 0px -20px;
      }
        .contact-info-wrapper > div,
        .contact-info-wrapper > div + div {
          flex-grow: 1;
          padding: 20px 0px 0px 20px;
        }

          .contact-phones div:not(.contact-worktime) > a {
            font-size: max(2.22222222vw, 16px);
          }


    .contact-info-container > div + div > div {
      max-height: unset;
    }
      .contact-info-container > div + div > div:before,
      .map-block-container:before {
        padding-top: 65%;
      }

    .contact-info-container .map-placeholder:after {
      margin: 0px;
    }
}

@media screen and (max-width: 799px) {
  .advantages-block-container .list-view-item,
  .team-member-block-container .list-view-item {
    width: 50%;
  }
}

/* landscape mobile - lm */
@media screen and (max-width: 767px) {
  /* Line height */
  /* =========== */
  .lm_lh10 { line-height: 1; }
  .lm_lh100p { line-height: 100%; }
  .lm_lh11 { line-height: 1.1; }
  .lm_lh110p { line-height: 110%; }
  .lm_lh12 { line-height: 1.2; }
  .lm_lh120p { line-height: 120%; }
  .lm_lh13 { line-height: 1.3; }
  .lm_lh130p { line-height: 130%; }
  .lm_lh14 { line-height: 1.4; }
  .lm_lh140p { line-height: 140%; }
  .lm_lh15 { line-height: 1.5; }
  .lm_lh150p { line-height: 150%; }
  .lm_lh16 { line-height: 1.6; }
  .lm_lh160p { line-height: 160%; }
  .lm_lh17 { line-height: 1.7; }
  .lm_lh170p { line-height: 170%; }
  .lm_lh18 { line-height: 1.8; }
  .lm_lh180p { line-height: 180%; }
  .lm_lh19 { line-height: 1.9; }
  .lm_lh190p { line-height: 190%; }
  .lm_lh20 { line-height: 2; }
  .lm_lh200p { line-height: 200%; }


  /* Display */
  /* ======= */
  .lm_dn { display: none; }
  .lm_dn_i { display: none !important; }
  .lm_db { display: block; }
  .lm_di { display: inline; }
  .lm_dib { display: inline-block; }
  .lm_dt { display: table; }
  .lm_dtr { display: table-row; }
  .lm_dtc { display: table-cell; }

  /* Flex */
  .lm_dfdr { display: flex; flex-direction: row; }
  .lm_dfdrr { display: flex; flex-direction: row-reverse; }
  .lm_dfdc { display: flex; flex-direction: column; }
  .lm_dfdcr { display: flex; flex-direction: column-reverse; }
  .lm_difdr { display: inline-flex; flex-direction: row; }
  .lm_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .lm_difdc { display: inline-flex; flex-direction: column; }
  .lm_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .lm_fwn { flex-wrap: nowrap; }
  .lm_fww { flex-wrap: wrap; }
  .lm_fwwr { flex-wrap: wrap-reverse; }
  .lm_fjcs { justify-content: flex-start; }
  .lm_fjce { justify-content: flex-end; }
  .lm_fjcc { justify-content: center; }
  .lm_fjcsa { justify-content: space-around; }
  .lm_fjcsb { justify-content: space-between; }
  .lm_fais { align-items: flex-start; }
  .lm_faie { align-items: flex-end; }
  .lm_faic { align-items: center; }
  .lm_faib { align-items: baseline; }
  .lm_faist { align-items: stretch; }
  .lm_facs { align-content: flex-start; }
  .lm_face { align-content: flex-end; }
  .lm_facc { align-content: center; }
  .lm_facsa { align-content: space-around; }
  .lm_facsb { align-content: space-between; }
  .lm_facst { align-content: stretch; }
  .lm_fass { align-self: flex-start; }
  .lm_fase { align-self: flex-end; }
  .lm_fasc { align-self: center; }
  .lm_fasb { align-self: baseline; }
  .lm_fasst { align-self: stretch; }


  /* Height */
  /* ====== */
  .lm_h0 { height: 0; }
  .lm_h100p, .lm_hfull { height: 100%; }
  .lm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .lm_w0 { width: 0; }
  .lm_w1 { width: 1px; }
  .lm_w5p { width: 5%; }
  .lm_w7p { width: 7%; }
  .lm_w10p { width: 10%; }
  .lm_w12p { width: 12.5%; }
  .lm_w15p { width: 15%; }
  .lm_w20p { width: 20%; }
  .lm_w25p { width: 25%; }
  .lm_w33p { width: 33.33333333%; }
  .lm_w35p { width: 35%; }
  .lm_w40p { width: 40%; }
  .lm_w50p { width: 50%; }
  .lm_w60p { width: 60%; }
  .lm_w65p { width: 65%; }
  .lm_w66p { width: 66.66666666%; }
  .lm_w70p { width: 70%; }
  .lm_w75p { width: 75%; }
  .lm_w80p { width: 80%; }
  .lm_w85p { width: 85%; }
  .lm_w88p { width: 87.5%; }
  .lm_w90p { width: 90%; }
  .lm_w93p { width: 93%; }
  .lm_w95p { width: 95%; }
  .lm_w100p, .lm_wfull { width: 100%; }
  .lm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .lm_m0auto, .lm_m0a { margin: 0 auto; }
  .lm_m0auto_i, .lm_m0a_i { margin: 0 auto !important; }
  .lm_mauto0, .lm_ma0 { margin: auto 0; }
  .lm_mauto0_i, .lm_ma0_i { margin: auto 0 !important; }
  .lm_mauto, .lm_ma { margin: auto; }
  .lm_mauto_i, .lm_ma_i { margin: auto !important; }
  .lm_mtauto, .lm_mta { margin-top: auto; }
  .lm_mtauto_i, .lm_mta_i { margin-top: auto !important; }
  .lm_mlauto, .lm_mla { margin-left: auto; }
  .lm_mlauto_i, .lm_mla_i { margin-left: auto !important; }
  .lm_mrauto, .lm_mra { margin-right: auto; }
  .lm_mrauto_i, .lm_mra_i { margin-right: auto !important; }
  .lm_mbauto, .lm_mba { margin-bottom: auto; }
  .lm_mbauto_i, .lm_mba_i { margin-bottom: auto !important; }
  .lm_m0 { margin: 0; }
  .lm_m0_i { margin: 0 !important; }
  .lm_mt0 { margin-top: 0; }
  .lm_mt0_i { margin-top: 0 !important; }
  .lm_ml0 { margin-left: 0; }
  .lm_ml0_i { margin-left: 0 !important; }
  .lm_mr0 { margin-right: 0; }
  .lm_mr0_i { margin-right: 0 !important; }
  .lm_mb0 { margin-bottom: 0; }
  .lm_mb0_i { margin-bottom: 0 !important; }
  .lm_m1 { margin: 1px; }
  .lm_mt1 { margin-top: 1px; }
  .lm_ml1 { margin-left: 1px; }
  .lm_mr1 { margin-right: 1px; }
  .lm_mb1 { margin-bottom: 1px; }
  .lm_m2 { margin: 2px; }
  .lm_mt2 { margin-top: 2px; }
  .lm_ml2 { margin-left: 2px; }
  .lm_mr2 { margin-right: 2px; }
  .lm_mb2 { margin-bottom: 2px; }
  .lm_m3 { margin: 3px; }
  .lm_mt3 { margin-top: 3px; }
  .lm_ml3 { margin-left: 3px; }
  .lm_mr3 { margin-right: 3px; }
  .lm_mb3 { margin-bottom: 3px; }
  .lm_m4 { margin: 4px; }
  .lm_mt4 { margin-top: 4px; }
  .lm_ml4 { margin-left: 4px; }
  .lm_mr4 { margin-right: 4px; }
  .lm_mb4 { margin-bottom: 4px; }
  .lm_m5 { margin: 5px; }
  .lm_mt5 { margin-top: 5px; }
  .lm_ml5 { margin-left: 5px; }
  .lm_mr5 { margin-right: 5px; }
  .lm_mb5 { margin-bottom: 5px; }
  .lm_m7 { margin: 7px; }
  .lm_mt7 { margin-top: 7px; }
  .lm_ml7 { margin-left: 7px; }
  .lm_mr7 { margin-right: 7px; }
  .lm_mb7 { margin-bottom: 7px; }
  .lm_m10 { margin: 10px; }
  .lm_mt10 { margin-top: 10px; }
  .lm_ml10 { margin-left: 10px; }
  .lm_mr10 { margin-right: 10px; }
  .lm_mb10 { margin-bottom: 10px; }
  .lm_m13 { margin: 13px; }
  .lm_mt13 { margin-top: 13px; }
  .lm_ml13 { margin-left: 13px; }
  .lm_mr13 { margin-right: 13px; }
  .lm_mb13 { margin-bottom: 13px; }
  .lm_m15 { margin: 15px; }
  .lm_mt15 { margin-top: 15px; }
  .lm_ml15 { margin-left: 15px; }
  .lm_mr15 { margin-right: 15px; }
  .lm_mb15 { margin-bottom: 15px; }
  .lm_m20 { margin: 20px; }
  .lm_mt20 { margin-top: 20px; }
  .lm_ml20 { margin-left: 20px; }
  .lm_mr20 { margin-right: 20px; }
  .lm_mb20 { margin-bottom: 20px; }
  .lm_m25 { margin: 25px; }
  .lm_mt25 { margin-top: 25px; }
  .lm_ml25 { margin-left: 25px; }
  .lm_mr25 { margin-right: 25px; }
  .lm_mb25 { margin-bottom: 25px; }
  .lm_m30 { margin: 30px; }
  .lm_mt30 { margin-top: 30px; }
  .lm_ml30 { margin-left: 30px; }
  .lm_mr30 { margin-right: 30px; }
  .lm_mb30 { margin-bottom: 30px; }
  .lm_m35 { margin: 35px; }
  .lm_mt35 { margin-top: 35px; }
  .lm_ml35 { margin-left: 35px; }
  .lm_mr35 { margin-right: 35px; }
  .lm_mb35 { margin-bottom: 35px; }
  .lm_m-1 { margin: -1px; }
  .lm_mt-1 { margin-top: -1px; }
  .lm_ml-1 { margin-left: -1px; }
  .lm_mr-1 { margin-right: -1px; }
  .lm_mb-1 { margin-bottom: -1px; }
  .lm_m-2 { margin: -2px; }
  .lm_mt-2 { margin-top: -2px; }
  .lm_ml-2 { margin-left: -2px; }
  .lm_mr-2 { margin-right: -2px; }
  .lm_mb-2 { margin-bottom: -2px; }
  .lm_m-3 { margin: -3px; }
  .lm_mt-3 { margin-top: -3px; }
  .lm_ml-3 { margin-left: -3px; }
  .lm_mr-3 { margin-right: -3px; }
  .lm_mb-3 { margin-bottom: -3px; }
  .lm_m-4 { margin: -4px; }
  .lm_mt-4 { margin-top: -4px; }
  .lm_ml-4 { margin-left: -4px; }
  .lm_mr-4 { margin-right: -4px; }
  .lm_mb-4 { margin-bottom: -4px; }
  .lm_m-5 { margin: -5px; }
  .lm_mt-5 { margin-top: -5px; }
  .lm_ml-5 { margin-left: -5px; }
  .lm_mr-5 { margin-right: -5px; }
  .lm_mb-5 { margin-bottom: -5px; }
  .lm_m-7 { margin: -7px; }
  .lm_mt-7 { margin-top: -7px; }
  .lm_ml-7 { margin-left: -7px; }
  .lm_mr-7 { margin-right: -7px; }
  .lm_mb-7 { margin-bottom: -7px; }
  .lm_m-10 { margin: -10px; }
  .lm_mt-10 { margin-top: -10px; }
  .lm_ml-10 { margin-left: -10px; }
  .lm_mr-10 { margin-right: -10px; }
  .lm_mb-10 { margin-bottom: -10px; }
  .lm_m-13 { margin: -13px; }
  .lm_mt-13 { margin-top: -13px; }
  .lm_ml-13 { margin-left: -13px; }
  .lm_mr-13 { margin-right: -13px; }
  .lm_mb-13 { margin-bottom: -13px; }
  .lm_m-15 { margin: -15px; }
  .lm_mt-15 { margin-top: -15px; }
  .lm_ml-15 { margin-left: -15px; }
  .lm_mr-15 { margin-right: -15px; }
  .lm_mb-15 { margin-bottom: -15px; }
  .lm_m-20 { margin: -20px; }
  .lm_mt-20 { margin-top: -20px; }
  .lm_ml-20 { margin-left: -20px; }
  .lm_mr-20 { margin-right: -20px; }
  .lm_mb-20 { margin-bottom: -20px; }
  .lm_m-25 { margin: -25px; }
  .lm_mt-25 { margin-top: -25px; }
  .lm_ml-25 { margin-left: -25px; }
  .lm_mr-25 { margin-right: -25px; }
  .lm_mb-25 { margin-bottom: -25px; }
  .lm_m-30 { margin: -30px; }
  .lm_mt-30 { margin-top: -30px; }
  .lm_ml-30 { margin-left: -30px; }
  .lm_mr-30 { margin-right: -30px; }
  .lm_mb-30 { margin-bottom: -30px; }
  .lm_m-35 { margin: -35px; }
  .lm_mt-35 { margin-top: -35px; }
  .lm_ml-35 { margin-left: -35px; }
  .lm_mr-35 { margin-right: -35px; }
  .lm_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .lm_p0 { padding: 0 !important; }
  .lm_pt0 { padding-top: 0 !important; }
  .lm_pl0 { padding-left: 0 !important; }
  .lm_pr0 { padding-right: 0 !important; }
  .lm_pb0 { padding-bottom: 0 !important; }
  .lm_p1 { padding: 1px !important; }
  .lm_pt1 { padding-top: 1px !important; }
  .lm_pl1 { padding-left: 1px !important; }
  .lm_pr1 { padding-right: 1px !important; }
  .lm_pb1 { padding-bottom: 1px !important; }
  .lm_p2 { padding: 2px !important; }
  .lm_pt2 { padding-top: 2px !important; }
  .lm_pl2 { padding-left: 2px !important; }
  .lm_pr2 { padding-right: 2px !important; }
  .lm_pb2 { padding-bottom: 2px !important; }
  .lm_p3 { padding: 3px !important; }
  .lm_pt3 { padding-top: 3px !important; }
  .lm_pl3 { padding-left: 3px !important; }
  .lm_pr3 { padding-right: 3px !important; }
  .lm_pb3 { padding-bottom: 3px !important; }
  .lm_p4 { padding: 4px !important; }
  .lm_pt4 { padding-top: 4px !important; }
  .lm_pl4 { padding-left: 4px !important; }
  .lm_pr4 { padding-right: 4px !important; }
  .lm_pb4 { padding-bottom: 4px !important; }
  .lm_p5 { padding: 5px !important; }
  .lm_pt5 { padding-top: 5px !important; }
  .lm_pl5 { padding-left: 5px !important; }
  .lm_pr5 { padding-right: 5px !important; }
  .lm_pb5 { padding-bottom: 5px !important; }
  .lm_p7 { padding: 7px !important; }
  .lm_pt7 { padding-top: 7px !important; }
  .lm_pl7 { padding-left: 7px !important; }
  .lm_pr7 { padding-right: 7px !important; }
  .lm_pb7 { padding-bottom: 7px !important; }
  .lm_p10 { padding: 10px !important; }
  .lm_pt10 { padding-top: 10px !important; }
  .lm_pl10 { padding-left: 10px !important; }
  .lm_pr10 { padding-right: 10px !important; }
  .lm_pb10 { padding-bottom: 10px !important; }
  .lm_p13 { padding: 13px !important; }
  .lm_pt13 { padding-top: 13px !important; }
  .lm_pl13 { padding-left: 13px !important; }
  .lm_pr13 { padding-right: 13px !important; }
  .lm_pb13 { padding-bottom: 13px !important; }
  .lm_p15 { padding: 15px !important; }
  .lm_pt15 { padding-top: 15px !important; }
  .lm_pl15 { padding-left: 15px !important; }
  .lm_pr15 { padding-right: 15px !important; }
  .lm_pb15 { padding-bottom: 15px !important; }
  .lm_p20 { padding: 20px !important; }
  .lm_pt20 { padding-top: 20px !important; }
  .lm_pl20 { padding-left: 20px !important; }
  .lm_pr20 { padding-right: 20px !important; }
  .lm_pb20 { padding-bottom: 20px !important; }
  .lm_p25 { padding: 25px !important; }
  .lm_pt25 { padding-top: 25px !important; }
  .lm_pl25 { padding-left: 25px !important; }
  .lm_pr25 { padding-right: 25px !important; }
  .lm_pb25 { padding-bottom: 25px !important; }
  .lm_p30 { padding: 30px !important; }
  .lm_pt30 { padding-top: 30px !important; }
  .lm_pl30 { padding-left: 30px !important; }
  .lm_pr30 { padding-right: 30px !important; }
  .lm_pb30 { padding-bottom: 30px !important; }
  .lm_p35 { padding: 35px !important; }
  .lm_pt35 { padding-top: 35px !important; }
  .lm_pl35 { padding-left: 35px !important; }
  .lm_pr35 { padding-right: 35px !important; }
  .lm_pb35 { padding-bottom: 35px !important; }

  .lm_pt10p { padding-top: 10% !important; }
  .lm_pt15p { padding-top: 15% !important; }
  .lm_pt20p { padding-top: 20% !important; }
  .lm_pt25p { padding-top: 25% !important; }
  .lm_pt33p { padding-top: 33.33333333% !important; }
  .lm_pt35p { padding-top: 35% !important; }
  .lm_pt40p { padding-top: 40% !important; }
  .lm_pt50p { padding-top: 50% !important; }
  .lm_pt60p { padding-top: 60% !important; }
  .lm_pt65p { padding-top: 65% !important; }
  .lm_pt66p { padding-top: 66.66666666% !important; }
  .lm_pt70p { padding-top: 70% !important; }
  .lm_pt75p { padding-top: 75% !important; }
  .lm_pt80p { padding-top: 80% !important; }
  .lm_pt85p { padding-top: 85% !important; }
  .lm_pt90p { padding-top: 90% !important; }
  .lm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .lm_vat { vertical-align: top; }
  .lm_vam { vertical-align: middle; }
  .lm_vab { vertical-align: bottom; }
  .lm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .lm_tal { text-align: left; }
  .lm_tal_i { text-align: left !important; }
  .lm_tac { text-align: center; }
  .lm_tac_i { text-align: center !important; }
  .lm_tar { text-align: right; }
  .lm_tar_i { text-align: right !important; }
  .lm_taj { text-align: justify; }
  .lm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .lm_fsi { font-style: italic; }

  /* Font size */
  .lm_fz0 { font-size: 0; }
  .lm_fz0_i { font-size: 0 !important; }
  .lm_fz10 { font-size: 0.625em; }
  .lm_fz11 { font-size: 0.6875em; }
  .lm_fz12 { font-size: 0.75em; }
  .lm_fz14 { font-size: 0.875em; }
  .lm_fz15 { font-size: 0.9375em; }
  .lm_fz16 { font-size: 1em; }
  .lm_fz18 { font-size: 1.125em; }
  .lm_fz20 { font-size: 1.25em; }
  .lm_fz22 { font-size: 1.375em; }
  .lm_fz24 { font-size: 1.5em; }
  .lm_fz26 { font-size: 1.625em; }
  .lm_fz28 { font-size: 1.75em; }
  .lm_fz30 { font-size: 1.875em; }
  .lm_fz32 { font-size: 2em; }
  .lm_fz10_i { font-size: 0.625em !important; }
  .lm_fz11_i { font-size: 0.6875em !important; }
  .lm_fz12_i { font-size: 0.75em !important; }
  .lm_fz14_i { font-size: 0.875em !important; }
  .lm_fz15_i { font-size: 0.9375em !important; }
  .lm_fz16_i { font-size: 1em !important; }
  .lm_fz18_i { font-size: 1.125em !important; }
  .lm_fz20_i { font-size: 1.25em !important; }
  .lm_fz22_i { font-size: 1.375em !important; }
  .lm_fz24_i { font-size: 1.5em !important; }
  .lm_fz26_i { font-size: 1.625em !important; }
  .lm_fz28_i { font-size: 1.75em !important; }
  .lm_fz30_i { font-size: 1.875em !important; }
  .lm_fz32_i { font-size: 2em !important; }
  .lm_fz10r { font-size: 0.625rem; }
  .lm_fz11r { font-size: 0.6875rem; }
  .lm_fz12r { font-size: 0.75rem; }
  .lm_fz14r { font-size: 0.875rem; }
  .lm_fz15r { font-size: 0.9375rem; }
  .lm_fz16r { font-size: 1rem; }
  .lm_fz18r { font-size: 1.125rem; }
  .lm_fz20r { font-size: 1.25rem; }
  .lm_fz22r { font-size: 1.375rem; }
  .lm_fz24r { font-size: 1.5rem; }
  .lm_fz26r { font-size: 1.625rem; }
  .lm_fz28r { font-size: 1.75rem; }
  .lm_fz30r { font-size: 1.875rem; }
  .lm_fz32r { font-size: 2rem; }
  .lm_fz10r_i { font-size: 0.625rem !important; }
  .lm_fz11r_i { font-size: 0.6875rem !important; }
  .lm_fz12r_i { font-size: 0.75rem !important; }
  .lm_fz14r_i { font-size: 0.875rem !important; }
  .lm_fz15r_i { font-size: 0.9375rem !important; }
  .lm_fz16r_i { font-size: 1rem !important; }
  .lm_fz18r_i { font-size: 1.125rem !important; }
  .lm_fz20r_i { font-size: 1.25rem !important; }
  .lm_fz22r_i { font-size: 1.375rem !important; }
  .lm_fz24r_i { font-size: 1.5rem !important; }
  .lm_fz26r_i { font-size: 1.625rem !important; }
  .lm_fz28r_i { font-size: 1.75rem !important; }
  .lm_fz30r_i { font-size: 1.875rem !important; }
  .lm_fz32r_i { font-size: 2rem !important; }
  .lm_fz10px { font-size: 10px; }
  .lm_fz11px { font-size: 11px; }
  .lm_fz12px { font-size: 12px; }
  .lm_fz14px { font-size: 14px; }
  .lm_fz15px { font-size: 15px; }
  .lm_fz16px { font-size: 16px; }
  .lm_fz18px { font-size: 18px; }
  .lm_fz20px { font-size: 20px; }
  .lm_fz22px { font-size: 22px; }
  .lm_fz24px { font-size: 24px; }
  .lm_fz26px { font-size: 26px; }
  .lm_fz28px { font-size: 28px; }
  .lm_fz30px { font-size: 30px; }
  .lm_fz32px { font-size: 32px; }
  .lm_fz10px_i { font-size: 10px !important; }
  .lm_fz11px_i { font-size: 11px !important; }
  .lm_fz12px_i { font-size: 12px !important; }
  .lm_fz14px_i { font-size: 14px !important; }
  .lm_fz15px_i { font-size: 15px !important; }
  .lm_fz16px_i { font-size: 16px !important; }
  .lm_fz18px_i { font-size: 18px !important; }
  .lm_fz20px_i { font-size: 20px !important; }
  .lm_fz22px_i { font-size: 22px !important; }
  .lm_fz24px_i { font-size: 24px !important; }
  .lm_fz26px_i { font-size: 26px !important; }
  .lm_fz28px_i { font-size: 28px !important; }
  .lm_fz30px_i { font-size: 30px !important; }
  .lm_fz32px_i { font-size: 32px !important; }

  /* Font weight */
  .lm_fw700, .lm_fw700_a, .lm_fw700_a *, .lm_bold, .lm_bold_a, .lm_bold_a * { font-weight: 700; }
  .lm_fw700_i, .lm_fw700_a_i, .lm_fw700_a_i *, .lm_bold_i, .lm_bold_a_i, .lm_bold_a_i * { font-weight: 700 !important; }
  .lm_fw600, .lm_fw600_a, .lm_fw600_a *, .lm_semibold, .lm_semibold_a, .lm_semibold_a * { font-weight: 600; }
  .lm_fw600_i, .lm_fw600_a_i, .lm_fw600_a_i *, .lm_semibold_i, .lm_semibold_a_i, .lm_semibold_a_i * { font-weight: 600 !important; }
  .lm_fw500, .lm_fw500_a, .lm_fw500_a * { font-weight: 500; }
  .lm_fw500_i, .lm_fw500_a_i, .lm_fw500_a_i * { font-weight: 500 !important; }
  .lm_fw400, .lm_fw400_a, .lm_fw400_a * { font-weight: 400; }
  .lm_fw400_i, .lm_fw400_a_i, .lm_fw400_a_i * { font-weight: 400 !important; }


  /* Floating */
  /* ======== */
  .lm_fln { float: none; }
  .lm_fll { float: left; }
  .lm_flr { float: right; }
}
@media screen and (max-width: 767px) {
  .main-rotator-container > .wrapper {
    display: block;
  }
    #main-rotator-controls .slick-prev,
    #main-rotator-controls .slick-next {
      background-size: 35% auto;
      width: max(8.07291667vw, 30px);
    }

    .main-slide-code-container {
      left: max(8.07291667vw, 30px);
      right: max(8.07291667vw, 30px);
    }
    .main-slide-code-container,
    .main-rotator-container.with-mini .main-slide-code-container {
      font-size: max(3.90625vw, 16px);
    }

    .main-rotator-mini-slides {
      flex-direction: row;
      margin: 0px 0px 0px -15px;
    }
      .main-rotator-mini-slides > div {
        width: 50%;
      }
        .main-rotator-mini-code {
          font-size: max(2.34375vw, 14px);
        }
}

/* large portrait mobile - lpm */
@media screen and (max-width: 699px) {
  /* Line height */
  /* =========== */
  .lpm_lh10 { line-height: 1; }
  .lpm_lh100p { line-height: 100%; }
  .lpm_lh11 { line-height: 1.1; }
  .lpm_lh110p { line-height: 110%; }
  .lpm_lh12 { line-height: 1.2; }
  .lpm_lh120p { line-height: 120%; }
  .lpm_lh13 { line-height: 1.3; }
  .lpm_lh130p { line-height: 130%; }
  .lpm_lh14 { line-height: 1.4; }
  .lpm_lh140p { line-height: 140%; }
  .lpm_lh15 { line-height: 1.5; }
  .lpm_lh150p { line-height: 150%; }
  .lpm_lh16 { line-height: 1.6; }
  .lpm_lh160p { line-height: 160%; }
  .lpm_lh17 { line-height: 1.7; }
  .lpm_lh170p { line-height: 170%; }
  .lpm_lh18 { line-height: 1.8; }
  .lpm_lh180p { line-height: 180%; }
  .lpm_lh19 { line-height: 1.9; }
  .lpm_lh190p { line-height: 190%; }
  .lpm_lh20 { line-height: 2; }
  .lpm_lh200p { line-height: 200%; }


  /* Display */
  /* ======= */
  .lpm_dn { display: none; }
  .lpm_dn_i { display: none !important; }
  .lpm_db { display: block; }
  .lpm_di { display: inline; }
  .lpm_dib { display: inline-block; }
  .lpm_dt { display: table; }
  .lpm_dtr { display: table-row; }
  .lpm_dtc { display: table-cell; }

  /* Flex */
  .lpm_dfdr { display: flex; flex-direction: row; }
  .lpm_dfdrr { display: flex; flex-direction: row-reverse; }
  .lpm_dfdc { display: flex; flex-direction: column; }
  .lpm_dfdcr { display: flex; flex-direction: column-reverse; }
  .lpm_difdr { display: inline-flex; flex-direction: row; }
  .lpm_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .lpm_difdc { display: inline-flex; flex-direction: column; }
  .lpm_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .lpm_fwn { flex-wrap: nowrap; }
  .lpm_fww { flex-wrap: wrap; }
  .lpm_fwwr { flex-wrap: wrap-reverse; }
  .lpm_fjcs { justify-content: flex-start; }
  .lpm_fjce { justify-content: flex-end; }
  .lpm_fjcc { justify-content: center; }
  .lpm_fjcsa { justify-content: space-around; }
  .lpm_fjcsb { justify-content: space-between; }
  .lpm_fais { align-items: flex-start; }
  .lpm_faie { align-items: flex-end; }
  .lpm_faic { align-items: center; }
  .lpm_faib { align-items: baseline; }
  .lpm_faist { align-items: stretch; }
  .lpm_facs { align-content: flex-start; }
  .lpm_face { align-content: flex-end; }
  .lpm_facc { align-content: center; }
  .lpm_facsa { align-content: space-around; }
  .lpm_facsb { align-content: space-between; }
  .lpm_facst { align-content: stretch; }
  .lpm_fass { align-self: flex-start; }
  .lpm_fase { align-self: flex-end; }
  .lpm_fasc { align-self: center; }
  .lpm_fasb { align-self: baseline; }
  .lpm_fasst { align-self: stretch; }


  /* Height */
  /* ====== */
  .lpm_h0 { height: 0; }
  .lpm_h100p, .lpm_hfull { height: 100%; }
  .lpm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .lpm_w0 { width: 0; }
  .lpm_w1 { width: 1px; }
  .lpm_w5p { width: 5%; }
  .lpm_w7p { width: 7%; }
  .lpm_w10p { width: 10%; }
  .lpm_w12p { width: 12.5%; }
  .lpm_w15p { width: 15%; }
  .lpm_w20p { width: 20%; }
  .lpm_w25p { width: 25%; }
  .lpm_w33p { width: 33.33333333%; }
  .lpm_w35p { width: 35%; }
  .lpm_w40p { width: 40%; }
  .lpm_w50p { width: 50%; }
  .lpm_w60p { width: 60%; }
  .lpm_w65p { width: 65%; }
  .lpm_w66p { width: 66.66666666%; }
  .lpm_w70p { width: 70%; }
  .lpm_w75p { width: 75%; }
  .lpm_w80p { width: 80%; }
  .lpm_w85p { width: 85%; }
  .lpm_w88p { width: 87.5%; }
  .lpm_w90p { width: 90%; }
  .lpm_w93p { width: 93%; }
  .lpm_w95p { width: 95%; }
  .lpm_w100p, .lpm_wfull { width: 100%; }
  .lpm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .lpm_m0auto, .lpm_m0a { margin: 0 auto; }
  .lpm_m0auto_i, .lpm_m0a_i { margin: 0 auto !important; }
  .lpm_mauto0, .lpm_ma0 { margin: auto 0; }
  .lpm_mauto0_i, .lpm_ma0_i { margin: auto 0 !important; }
  .lpm_mauto, .lpm_ma { margin: auto; }
  .lpm_mauto_i, .lpm_ma_i { margin: auto !important; }
  .lpm_mtauto, .lpm_mta { margin-top: auto; }
  .lpm_mtauto_i, .lpm_mta_i { margin-top: auto !important; }
  .lpm_mlauto, .lpm_mla { margin-left: auto; }
  .lpm_mlauto_i, .lpm_mla_i { margin-left: auto !important; }
  .lpm_mrauto, .lpm_mra { margin-right: auto; }
  .lpm_mrauto_i, .lpm_mra_i { margin-right: auto !important; }
  .lpm_mbauto, .lpm_mba { margin-bottom: auto; }
  .lpm_mbauto_i, .lpm_mba_i { margin-bottom: auto !important; }
  .lpm_m0 { margin: 0; }
  .lpm_m0_i { margin: 0 !important; }
  .lpm_mt0 { margin-top: 0; }
  .lpm_mt0_i { margin-top: 0 !important; }
  .lpm_ml0 { margin-left: 0; }
  .lpm_ml0_i { margin-left: 0 !important; }
  .lpm_mr0 { margin-right: 0; }
  .lpm_mr0_i { margin-right: 0 !important; }
  .lpm_mb0 { margin-bottom: 0; }
  .lpm_mb0_i { margin-bottom: 0 !important; }
  .lpm_m1 { margin: 1px; }
  .lpm_mt1 { margin-top: 1px; }
  .lpm_ml1 { margin-left: 1px; }
  .lpm_mr1 { margin-right: 1px; }
  .lpm_mb1 { margin-bottom: 1px; }
  .lpm_m2 { margin: 2px; }
  .lpm_mt2 { margin-top: 2px; }
  .lpm_ml2 { margin-left: 2px; }
  .lpm_mr2 { margin-right: 2px; }
  .lpm_mb2 { margin-bottom: 2px; }
  .lpm_m3 { margin: 3px; }
  .lpm_mt3 { margin-top: 3px; }
  .lpm_ml3 { margin-left: 3px; }
  .lpm_mr3 { margin-right: 3px; }
  .lpm_mb3 { margin-bottom: 3px; }
  .lpm_m4 { margin: 4px; }
  .lpm_mt4 { margin-top: 4px; }
  .lpm_ml4 { margin-left: 4px; }
  .lpm_mr4 { margin-right: 4px; }
  .lpm_mb4 { margin-bottom: 4px; }
  .lpm_m5 { margin: 5px; }
  .lpm_mt5 { margin-top: 5px; }
  .lpm_ml5 { margin-left: 5px; }
  .lpm_mr5 { margin-right: 5px; }
  .lpm_mb5 { margin-bottom: 5px; }
  .lpm_m7 { margin: 7px; }
  .lpm_mt7 { margin-top: 7px; }
  .lpm_ml7 { margin-left: 7px; }
  .lpm_mr7 { margin-right: 7px; }
  .lpm_mb7 { margin-bottom: 7px; }
  .lpm_m10 { margin: 10px; }
  .lpm_mt10 { margin-top: 10px; }
  .lpm_ml10 { margin-left: 10px; }
  .lpm_mr10 { margin-right: 10px; }
  .lpm_mb10 { margin-bottom: 10px; }
  .lpm_m13 { margin: 13px; }
  .lpm_mt13 { margin-top: 13px; }
  .lpm_ml13 { margin-left: 13px; }
  .lpm_mr13 { margin-right: 13px; }
  .lpm_mb13 { margin-bottom: 13px; }
  .lpm_m15 { margin: 15px; }
  .lpm_mt15 { margin-top: 15px; }
  .lpm_ml15 { margin-left: 15px; }
  .lpm_mr15 { margin-right: 15px; }
  .lpm_mb15 { margin-bottom: 15px; }
  .lpm_m20 { margin: 20px; }
  .lpm_mt20 { margin-top: 20px; }
  .lpm_ml20 { margin-left: 20px; }
  .lpm_mr20 { margin-right: 20px; }
  .lpm_mb20 { margin-bottom: 20px; }
  .lpm_m25 { margin: 25px; }
  .lpm_mt25 { margin-top: 25px; }
  .lpm_ml25 { margin-left: 25px; }
  .lpm_mr25 { margin-right: 25px; }
  .lpm_mb25 { margin-bottom: 25px; }
  .lpm_m30 { margin: 30px; }
  .lpm_mt30 { margin-top: 30px; }
  .lpm_ml30 { margin-left: 30px; }
  .lpm_mr30 { margin-right: 30px; }
  .lpm_mb30 { margin-bottom: 30px; }
  .lpm_m35 { margin: 35px; }
  .lpm_mt35 { margin-top: 35px; }
  .lpm_ml35 { margin-left: 35px; }
  .lpm_mr35 { margin-right: 35px; }
  .lpm_mb35 { margin-bottom: 35px; }
  .lpm_m-1 { margin: -1px; }
  .lpm_mt-1 { margin-top: -1px; }
  .lpm_ml-1 { margin-left: -1px; }
  .lpm_mr-1 { margin-right: -1px; }
  .lpm_mb-1 { margin-bottom: -1px; }
  .lpm_m-2 { margin: -2px; }
  .lpm_mt-2 { margin-top: -2px; }
  .lpm_ml-2 { margin-left: -2px; }
  .lpm_mr-2 { margin-right: -2px; }
  .lpm_mb-2 { margin-bottom: -2px; }
  .lpm_m-3 { margin: -3px; }
  .lpm_mt-3 { margin-top: -3px; }
  .lpm_ml-3 { margin-left: -3px; }
  .lpm_mr-3 { margin-right: -3px; }
  .lpm_mb-3 { margin-bottom: -3px; }
  .lpm_m-4 { margin: -4px; }
  .lpm_mt-4 { margin-top: -4px; }
  .lpm_ml-4 { margin-left: -4px; }
  .lpm_mr-4 { margin-right: -4px; }
  .lpm_mb-4 { margin-bottom: -4px; }
  .lpm_m-5 { margin: -5px; }
  .lpm_mt-5 { margin-top: -5px; }
  .lpm_ml-5 { margin-left: -5px; }
  .lpm_mr-5 { margin-right: -5px; }
  .lpm_mb-5 { margin-bottom: -5px; }
  .lpm_m-7 { margin: -7px; }
  .lpm_mt-7 { margin-top: -7px; }
  .lpm_ml-7 { margin-left: -7px; }
  .lpm_mr-7 { margin-right: -7px; }
  .lpm_mb-7 { margin-bottom: -7px; }
  .lpm_m-10 { margin: -10px; }
  .lpm_mt-10 { margin-top: -10px; }
  .lpm_ml-10 { margin-left: -10px; }
  .lpm_mr-10 { margin-right: -10px; }
  .lpm_mb-10 { margin-bottom: -10px; }
  .lpm_m-13 { margin: -13px; }
  .lpm_mt-13 { margin-top: -13px; }
  .lpm_ml-13 { margin-left: -13px; }
  .lpm_mr-13 { margin-right: -13px; }
  .lpm_mb-13 { margin-bottom: -13px; }
  .lpm_m-15 { margin: -15px; }
  .lpm_mt-15 { margin-top: -15px; }
  .lpm_ml-15 { margin-left: -15px; }
  .lpm_mr-15 { margin-right: -15px; }
  .lpm_mb-15 { margin-bottom: -15px; }
  .lpm_m-20 { margin: -20px; }
  .lpm_mt-20 { margin-top: -20px; }
  .lpm_ml-20 { margin-left: -20px; }
  .lpm_mr-20 { margin-right: -20px; }
  .lpm_mb-20 { margin-bottom: -20px; }
  .lpm_m-25 { margin: -25px; }
  .lpm_mt-25 { margin-top: -25px; }
  .lpm_ml-25 { margin-left: -25px; }
  .lpm_mr-25 { margin-right: -25px; }
  .lpm_mb-25 { margin-bottom: -25px; }
  .lpm_m-30 { margin: -30px; }
  .lpm_mt-30 { margin-top: -30px; }
  .lpm_ml-30 { margin-left: -30px; }
  .lpm_mr-30 { margin-right: -30px; }
  .lpm_mb-30 { margin-bottom: -30px; }
  .lpm_m-35 { margin: -35px; }
  .lpm_mt-35 { margin-top: -35px; }
  .lpm_ml-35 { margin-left: -35px; }
  .lpm_mr-35 { margin-right: -35px; }
  .lpm_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .lpm_p0 { padding: 0 !important; }
  .lpm_pt0 { padding-top: 0 !important; }
  .lpm_pl0 { padding-left: 0 !important; }
  .lpm_pr0 { padding-right: 0 !important; }
  .lpm_pb0 { padding-bottom: 0 !important; }
  .lpm_p1 { padding: 1px !important; }
  .lpm_pt1 { padding-top: 1px !important; }
  .lpm_pl1 { padding-left: 1px !important; }
  .lpm_pr1 { padding-right: 1px !important; }
  .lpm_pb1 { padding-bottom: 1px !important; }
  .lpm_p2 { padding: 2px !important; }
  .lpm_pt2 { padding-top: 2px !important; }
  .lpm_pl2 { padding-left: 2px !important; }
  .lpm_pr2 { padding-right: 2px !important; }
  .lpm_pb2 { padding-bottom: 2px !important; }
  .lpm_p3 { padding: 3px !important; }
  .lpm_pt3 { padding-top: 3px !important; }
  .lpm_pl3 { padding-left: 3px !important; }
  .lpm_pr3 { padding-right: 3px !important; }
  .lpm_pb3 { padding-bottom: 3px !important; }
  .lpm_p4 { padding: 4px !important; }
  .lpm_pt4 { padding-top: 4px !important; }
  .lpm_pl4 { padding-left: 4px !important; }
  .lpm_pr4 { padding-right: 4px !important; }
  .lpm_pb4 { padding-bottom: 4px !important; }
  .lpm_p5 { padding: 5px !important; }
  .lpm_pt5 { padding-top: 5px !important; }
  .lpm_pl5 { padding-left: 5px !important; }
  .lpm_pr5 { padding-right: 5px !important; }
  .lpm_pb5 { padding-bottom: 5px !important; }
  .lpm_p7 { padding: 7px !important; }
  .lpm_pt7 { padding-top: 7px !important; }
  .lpm_pl7 { padding-left: 7px !important; }
  .lpm_pr7 { padding-right: 7px !important; }
  .lpm_pb7 { padding-bottom: 7px !important; }
  .lpm_p10 { padding: 10px !important; }
  .lpm_pt10 { padding-top: 10px !important; }
  .lpm_pl10 { padding-left: 10px !important; }
  .lpm_pr10 { padding-right: 10px !important; }
  .lpm_pb10 { padding-bottom: 10px !important; }
  .lpm_p13 { padding: 13px !important; }
  .lpm_pt13 { padding-top: 13px !important; }
  .lpm_pl13 { padding-left: 13px !important; }
  .lpm_pr13 { padding-right: 13px !important; }
  .lpm_pb13 { padding-bottom: 13px !important; }
  .lpm_p15 { padding: 15px !important; }
  .lpm_pt15 { padding-top: 15px !important; }
  .lpm_pl15 { padding-left: 15px !important; }
  .lpm_pr15 { padding-right: 15px !important; }
  .lpm_pb15 { padding-bottom: 15px !important; }
  .lpm_p20 { padding: 20px !important; }
  .lpm_pt20 { padding-top: 20px !important; }
  .lpm_pl20 { padding-left: 20px !important; }
  .lpm_pr20 { padding-right: 20px !important; }
  .lpm_pb20 { padding-bottom: 20px !important; }
  .lpm_p25 { padding: 25px !important; }
  .lpm_pt25 { padding-top: 25px !important; }
  .lpm_pl25 { padding-left: 25px !important; }
  .lpm_pr25 { padding-right: 25px !important; }
  .lpm_pb25 { padding-bottom: 25px !important; }
  .lpm_p30 { padding: 30px !important; }
  .lpm_pt30 { padding-top: 30px !important; }
  .lpm_pl30 { padding-left: 30px !important; }
  .lpm_pr30 { padding-right: 30px !important; }
  .lpm_pb30 { padding-bottom: 30px !important; }
  .lpm_p35 { padding: 35px !important; }
  .lpm_pt35 { padding-top: 35px !important; }
  .lpm_pl35 { padding-left: 35px !important; }
  .lpm_pr35 { padding-right: 35px !important; }
  .lpm_pb35 { padding-bottom: 35px !important; }

  .lpm_pt10p { padding-top: 10% !important; }
  .lpm_pt15p { padding-top: 15% !important; }
  .lpm_pt20p { padding-top: 20% !important; }
  .lpm_pt25p { padding-top: 25% !important; }
  .lpm_pt33p { padding-top: 33.33333333% !important; }
  .lpm_pt35p { padding-top: 35% !important; }
  .lpm_pt40p { padding-top: 40% !important; }
  .lpm_pt50p { padding-top: 50% !important; }
  .lpm_pt60p { padding-top: 60% !important; }
  .lpm_pt65p { padding-top: 65% !important; }
  .lpm_pt66p { padding-top: 66.66666666% !important; }
  .lpm_pt70p { padding-top: 70% !important; }
  .lpm_pt75p { padding-top: 75% !important; }
  .lpm_pt80p { padding-top: 80% !important; }
  .lpm_pt85p { padding-top: 85% !important; }
  .lpm_pt90p { padding-top: 90% !important; }
  .lpm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .lpm_vat { vertical-align: top; }
  .lpm_vam { vertical-align: middle; }
  .lpm_vab { vertical-align: bottom; }
  .lpm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .lpm_tal { text-align: left; }
  .lpm_tal_i { text-align: left !important; }
  .lpm_tac { text-align: center; }
  .lpm_tac_i { text-align: center !important; }
  .lpm_tar { text-align: right; }
  .lpm_tar_i { text-align: right !important; }
  .lpm_taj { text-align: justify; }
  .lpm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .lpm_fsi { font-style: italic; }

  /* Font size */
  .lpm_fz0 { font-size: 0; }
  .lpm_fz0_i { font-size: 0 !important; }
  .lpm_fz10 { font-size: 0.625em; }
  .lpm_fz11 { font-size: 0.6875em; }
  .lpm_fz12 { font-size: 0.75em; }
  .lpm_fz14 { font-size: 0.875em; }
  .lpm_fz15 { font-size: 0.9375em; }
  .lpm_fz16 { font-size: 1em; }
  .lpm_fz18 { font-size: 1.125em; }
  .lpm_fz20 { font-size: 1.25em; }
  .lpm_fz22 { font-size: 1.375em; }
  .lpm_fz24 { font-size: 1.5em; }
  .lpm_fz26 { font-size: 1.625em; }
  .lpm_fz28 { font-size: 1.75em; }
  .lpm_fz30 { font-size: 1.875em; }
  .lpm_fz32 { font-size: 2em; }
  .lpm_fz10_i { font-size: 0.625em !important; }
  .lpm_fz11_i { font-size: 0.6875em !important; }
  .lpm_fz12_i { font-size: 0.75em !important; }
  .lpm_fz14_i { font-size: 0.875em !important; }
  .lpm_fz15_i { font-size: 0.9375em !important; }
  .lpm_fz16_i { font-size: 1em !important; }
  .lpm_fz18_i { font-size: 1.125em !important; }
  .lpm_fz20_i { font-size: 1.25em !important; }
  .lpm_fz22_i { font-size: 1.375em !important; }
  .lpm_fz24_i { font-size: 1.5em !important; }
  .lpm_fz26_i { font-size: 1.625em !important; }
  .lpm_fz28_i { font-size: 1.75em !important; }
  .lpm_fz30_i { font-size: 1.875em !important; }
  .lpm_fz32_i { font-size: 2em !important; }
  .lpm_fz10r { font-size: 0.625rem; }
  .lpm_fz11r { font-size: 0.6875rem; }
  .lpm_fz12r { font-size: 0.75rem; }
  .lpm_fz14r { font-size: 0.875rem; }
  .lpm_fz15r { font-size: 0.9375rem; }
  .lpm_fz16r { font-size: 1rem; }
  .lpm_fz18r { font-size: 1.125rem; }
  .lpm_fz20r { font-size: 1.25rem; }
  .lpm_fz22r { font-size: 1.375rem; }
  .lpm_fz24r { font-size: 1.5rem; }
  .lpm_fz26r { font-size: 1.625rem; }
  .lpm_fz28r { font-size: 1.75rem; }
  .lpm_fz30r { font-size: 1.875rem; }
  .lpm_fz32r { font-size: 2rem; }
  .lpm_fz10r_i { font-size: 0.625rem !important; }
  .lpm_fz11r_i { font-size: 0.6875rem !important; }
  .lpm_fz12r_i { font-size: 0.75rem !important; }
  .lpm_fz14r_i { font-size: 0.875rem !important; }
  .lpm_fz15r_i { font-size: 0.9375rem !important; }
  .lpm_fz16r_i { font-size: 1rem !important; }
  .lpm_fz18r_i { font-size: 1.125rem !important; }
  .lpm_fz20r_i { font-size: 1.25rem !important; }
  .lpm_fz22r_i { font-size: 1.375rem !important; }
  .lpm_fz24r_i { font-size: 1.5rem !important; }
  .lpm_fz26r_i { font-size: 1.625rem !important; }
  .lpm_fz28r_i { font-size: 1.75rem !important; }
  .lpm_fz30r_i { font-size: 1.875rem !important; }
  .lpm_fz32r_i { font-size: 2rem !important; }
  .lpm_fz10px { font-size: 10px; }
  .lpm_fz11px { font-size: 11px; }
  .lpm_fz12px { font-size: 12px; }
  .lpm_fz14px { font-size: 14px; }
  .lpm_fz15px { font-size: 15px; }
  .lpm_fz16px { font-size: 16px; }
  .lpm_fz18px { font-size: 18px; }
  .lpm_fz20px { font-size: 20px; }
  .lpm_fz22px { font-size: 22px; }
  .lpm_fz24px { font-size: 24px; }
  .lpm_fz26px { font-size: 26px; }
  .lpm_fz28px { font-size: 28px; }
  .lpm_fz30px { font-size: 30px; }
  .lpm_fz32px { font-size: 32px; }
  .lpm_fz10px_i { font-size: 10px !important; }
  .lpm_fz11px_i { font-size: 11px !important; }
  .lpm_fz12px_i { font-size: 12px !important; }
  .lpm_fz14px_i { font-size: 14px !important; }
  .lpm_fz15px_i { font-size: 15px !important; }
  .lpm_fz16px_i { font-size: 16px !important; }
  .lpm_fz18px_i { font-size: 18px !important; }
  .lpm_fz20px_i { font-size: 20px !important; }
  .lpm_fz22px_i { font-size: 22px !important; }
  .lpm_fz24px_i { font-size: 24px !important; }
  .lpm_fz26px_i { font-size: 26px !important; }
  .lpm_fz28px_i { font-size: 28px !important; }
  .lpm_fz30px_i { font-size: 30px !important; }
  .lpm_fz32px_i { font-size: 32px !important; }

  /* Font weight */
  .lpm_fw700, .lpm_fw700_a, .lpm_fw700_a *, .lpm_bold, .lpm_bold_a, .lpm_bold_a * { font-weight: 700; }
  .lpm_fw700_i, .lpm_fw700_a_i, .lpm_fw700_a_i *, .lpm_bold_i, .lpm_bold_a_i, .lpm_bold_a_i * { font-weight: 700 !important; }
  .lpm_fw600, .lpm_fw600_a, .lpm_fw600_a *, .lpm_semibold, .lpm_semibold_a, .lpm_semibold_a * { font-weight: 600; }
  .lpm_fw600_i, .lpm_fw600_a_i, .lpm_fw600_a_i *, .lpm_semibold_i, .lpm_semibold_a_i, .lpm_semibold_a_i * { font-weight: 600 !important; }
  .lpm_fw500, .lpm_fw500_a, .lpm_fw500_a * { font-weight: 500; }
  .lpm_fw500_i, .lpm_fw500_a_i, .lpm_fw500_a_i * { font-weight: 500 !important; }
  .lpm_fw400, .lpm_fw400_a, .lpm_fw400_a * { font-weight: 400; }
  .lpm_fw400_i, .lpm_fw400_a_i, .lpm_fw400_a_i * { font-weight: 400 !important; }


  /* Floating */
  /* ======== */
  .lpm_fln { float: none; }
  .lpm_fll { float: left; }
  .lpm_flr { float: right; }
}
@media screen and (max-width: 699px) {
  .header-content {
    padding-left: 40px;
  }
    .header-phones div {
      line-height: 1.1;
    }
      .header-phones a {
        font-size: 16px;
      }

    .header-worktime {
      display: none;
    }


  .price-name,
  .price-price,
  .price-unit,
  .price-table-head .price-name,
  .price-table-head .price-price,
  .price-table-head .price-unit,
  .price-table-subheader .price-name {
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
    .price-name {
      padding-left: 10px;
    }
      .price-table-subheader .price-name {
        padding-top: 7px;
        padding-bottom: 6px;
      }


  .gallery-rotator-block-container.gallery-items-width-3 .list-view-item,
  .gallery-mini-block-container .list-view-item {
    width: 50%;
  }
    .gallery-rotator-block-container.gallery-items-width-3 .list-view-item-name span,
    .gallery-mini-block-container .list-view-item-name span {
      font-size: max(2vw, 15px);
    }


  .faq-list > div {
    width: 100%;
  }


  .footer-callback-container .form-wrapper {
    max-width: 420px;
    margin: auto;
  }
    .footer-callback-container form {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }
      .footer-callback-container .form-elements > div:first-child {
        display: block;
      }


  .footer-copyrights {
    flex-wrap: wrap;
    justify-content: space-around;
    border: none;
    text-align: center;
    padding-top: 10px;
    margin-left: -10px;
  }
    .footer-copyrights > div {
      padding: 10px 0px 0px 10px;
    }
}

@media screen and (max-width: 639px) {
  .list-view-item {
    width: 50%;
  }


  .service-block-container .list-view-item {
    width: 50%;
  }
    .service-block-container .list-view-item-wrapper {
      min-height: 25vw;
    }
}

@media screen and (max-width: 599px) {
  .header-contacts {
    justify-content: flex-end;
  }


  .gallery-item-width-4 {
    width: 50%;
  }
}

@media screen and (max-width: 519px) {
  .header-logo {
    max-width: max(120px, 55%);
  }

  .header-buttons {
    display: none;
  }
}

@media screen and (max-width: 499px) {
  .header-menu-container {
    width: 100%;
  }


  .gallery-rotator-block-container .list-view-item,
  .gallery-rotator-block-container.gallery-items-width-3 .list-view-item,
  .gallery-rotator-block-container.gallery-items-width-4 .list-view-item,
  .gallery-mini-block-container .list-view-item {
    width: 100%;
    max-width: 412px;
  }


  .advantages-block-container .list-view-item,
  .team-member-block-container .list-view-item {
    width: 100%;
  }
    .team-member-block-container .list-view-item-image {
      max-width: 25%;
      min-width: 25%;
    }
}

/* portrait mobile - pm */
@media screen and (max-width: 479px) {
  /* Line height */
  /* =========== */
  .pm_lh10 { line-height: 1; }
  .pm_lh100p { line-height: 100%; }
  .pm_lh11 { line-height: 1.1; }
  .pm_lh110p { line-height: 110%; }
  .pm_lh12 { line-height: 1.2; }
  .pm_lh120p { line-height: 120%; }
  .pm_lh13 { line-height: 1.3; }
  .pm_lh130p { line-height: 130%; }
  .pm_lh14 { line-height: 1.4; }
  .pm_lh140p { line-height: 140%; }
  .pm_lh15 { line-height: 1.5; }
  .pm_lh150p { line-height: 150%; }
  .pm_lh16 { line-height: 1.6; }
  .pm_lh160p { line-height: 160%; }
  .pm_lh17 { line-height: 1.7; }
  .pm_lh170p { line-height: 170%; }
  .pm_lh18 { line-height: 1.8; }
  .pm_lh180p { line-height: 180%; }
  .pm_lh19 { line-height: 1.9; }
  .pm_lh190p { line-height: 190%; }
  .pm_lh20 { line-height: 2; }
  .pm_lh200p { line-height: 200%; }


  /* Display */
  /* ======= */
  .pm_dn { display: none; }
  .pm_dn_i { display: none !important; }
  .pm_db { display: block; }
  .pm_di { display: inline; }
  .pm_dib { display: inline-block; }
  .pm_dt { display: table; }
  .pm_dtr { display: table-row; }
  .pm_dtc { display: table-cell; }

  /* Flex */
  .pm_dfdr { display: flex; flex-direction: row; }
  .pm_dfdrr { display: flex; flex-direction: row-reverse; }
  .pm_dfdc { display: flex; flex-direction: column; }
  .pm_dfdcr { display: flex; flex-direction: column-reverse; }
  .pm_difdr { display: inline-flex; flex-direction: row; }
  .pm_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .pm_difdc { display: inline-flex; flex-direction: column; }
  .pm_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .pm_fwn { flex-wrap: nowrap; }
  .pm_fww { flex-wrap: wrap; }
  .pm_fwwr { flex-wrap: wrap-reverse; }
  .pm_fjcs { justify-content: flex-start; }
  .pm_fjce { justify-content: flex-end; }
  .pm_fjcc { justify-content: center; }
  .pm_fjcsa { justify-content: space-around; }
  .pm_fjcsb { justify-content: space-between; }
  .pm_fais { align-items: flex-start; }
  .pm_faie { align-items: flex-end; }
  .pm_faic { align-items: center; }
  .pm_faib { align-items: baseline; }
  .pm_faist { align-items: stretch; }
  .pm_facs { align-content: flex-start; }
  .pm_face { align-content: flex-end; }
  .pm_facc { align-content: center; }
  .pm_facsa { align-content: space-around; }
  .pm_facsb { align-content: space-between; }
  .pm_facst { align-content: stretch; }
  .pm_fass { align-self: flex-start; }
  .pm_fase { align-self: flex-end; }
  .pm_fasc { align-self: center; }
  .pm_fasb { align-self: baseline; }
  .pm_fasst { align-self: stretch; }


  /* Height */
  /* ====== */
  .pm_h0 { height: 0; }
  .pm_h100p, .pm_hfull { height: 100%; }
  .pm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .pm_w0 { width: 0; }
  .pm_w1 { width: 1px; }
  .pm_w5p { width: 5%; }
  .pm_w7p { width: 7%; }
  .pm_w10p { width: 10%; }
  .pm_w12p { width: 12.5%; }
  .pm_w15p { width: 15%; }
  .pm_w20p { width: 20%; }
  .pm_w25p { width: 25%; }
  .pm_w33p { width: 33.33333333%; }
  .pm_w35p { width: 35%; }
  .pm_w40p { width: 40%; }
  .pm_w50p { width: 50%; }
  .pm_w60p { width: 60%; }
  .pm_w65p { width: 65%; }
  .pm_w66p { width: 66.66666666%; }
  .pm_w70p { width: 70%; }
  .pm_w75p { width: 75%; }
  .pm_w80p { width: 80%; }
  .pm_w85p { width: 85%; }
  .pm_w88p { width: 87.5%; }
  .pm_w90p { width: 90%; }
  .pm_w93p { width: 93%; }
  .pm_w95p { width: 95%; }
  .pm_w100p, .pm_wfull { width: 100%; }
  .pm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .pm_m0auto, .pm_m0a { margin: 0 auto; }
  .pm_m0auto_i, .pm_m0a_i { margin: 0 auto !important; }
  .pm_mauto0, .pm_ma0 { margin: auto 0; }
  .pm_mauto0_i, .pm_ma0_i { margin: auto 0 !important; }
  .pm_mauto, .pm_ma { margin: auto; }
  .pm_mauto_i, .pm_ma_i { margin: auto !important; }
  .pm_mtauto, .pm_mta { margin-top: auto; }
  .pm_mtauto_i, .pm_mta_i { margin-top: auto !important; }
  .pm_mlauto, .pm_mla { margin-left: auto; }
  .pm_mlauto_i, .pm_mla_i { margin-left: auto !important; }
  .pm_mrauto, .pm_mra { margin-right: auto; }
  .pm_mrauto_i, .pm_mra_i { margin-right: auto !important; }
  .pm_mbauto, .pm_mba { margin-bottom: auto; }
  .pm_mbauto_i, .pm_mba_i { margin-bottom: auto !important; }
  .pm_m0 { margin: 0; }
  .pm_m0_i { margin: 0 !important; }
  .pm_mt0 { margin-top: 0; }
  .pm_mt0_i { margin-top: 0 !important; }
  .pm_ml0 { margin-left: 0; }
  .pm_ml0_i { margin-left: 0 !important; }
  .pm_mr0 { margin-right: 0; }
  .pm_mr0_i { margin-right: 0 !important; }
  .pm_mb0 { margin-bottom: 0; }
  .pm_mb0_i { margin-bottom: 0 !important; }
  .pm_m1 { margin: 1px; }
  .pm_mt1 { margin-top: 1px; }
  .pm_ml1 { margin-left: 1px; }
  .pm_mr1 { margin-right: 1px; }
  .pm_mb1 { margin-bottom: 1px; }
  .pm_m2 { margin: 2px; }
  .pm_mt2 { margin-top: 2px; }
  .pm_ml2 { margin-left: 2px; }
  .pm_mr2 { margin-right: 2px; }
  .pm_mb2 { margin-bottom: 2px; }
  .pm_m3 { margin: 3px; }
  .pm_mt3 { margin-top: 3px; }
  .pm_ml3 { margin-left: 3px; }
  .pm_mr3 { margin-right: 3px; }
  .pm_mb3 { margin-bottom: 3px; }
  .pm_m4 { margin: 4px; }
  .pm_mt4 { margin-top: 4px; }
  .pm_ml4 { margin-left: 4px; }
  .pm_mr4 { margin-right: 4px; }
  .pm_mb4 { margin-bottom: 4px; }
  .pm_m5 { margin: 5px; }
  .pm_mt5 { margin-top: 5px; }
  .pm_ml5 { margin-left: 5px; }
  .pm_mr5 { margin-right: 5px; }
  .pm_mb5 { margin-bottom: 5px; }
  .pm_m7 { margin: 7px; }
  .pm_mt7 { margin-top: 7px; }
  .pm_ml7 { margin-left: 7px; }
  .pm_mr7 { margin-right: 7px; }
  .pm_mb7 { margin-bottom: 7px; }
  .pm_m10 { margin: 10px; }
  .pm_mt10 { margin-top: 10px; }
  .pm_ml10 { margin-left: 10px; }
  .pm_mr10 { margin-right: 10px; }
  .pm_mb10 { margin-bottom: 10px; }
  .pm_m13 { margin: 13px; }
  .pm_mt13 { margin-top: 13px; }
  .pm_ml13 { margin-left: 13px; }
  .pm_mr13 { margin-right: 13px; }
  .pm_mb13 { margin-bottom: 13px; }
  .pm_m15 { margin: 15px; }
  .pm_mt15 { margin-top: 15px; }
  .pm_ml15 { margin-left: 15px; }
  .pm_mr15 { margin-right: 15px; }
  .pm_mb15 { margin-bottom: 15px; }
  .pm_m20 { margin: 20px; }
  .pm_mt20 { margin-top: 20px; }
  .pm_ml20 { margin-left: 20px; }
  .pm_mr20 { margin-right: 20px; }
  .pm_mb20 { margin-bottom: 20px; }
  .pm_m25 { margin: 25px; }
  .pm_mt25 { margin-top: 25px; }
  .pm_ml25 { margin-left: 25px; }
  .pm_mr25 { margin-right: 25px; }
  .pm_mb25 { margin-bottom: 25px; }
  .pm_m30 { margin: 30px; }
  .pm_mt30 { margin-top: 30px; }
  .pm_ml30 { margin-left: 30px; }
  .pm_mr30 { margin-right: 30px; }
  .pm_mb30 { margin-bottom: 30px; }
  .pm_m35 { margin: 35px; }
  .pm_mt35 { margin-top: 35px; }
  .pm_ml35 { margin-left: 35px; }
  .pm_mr35 { margin-right: 35px; }
  .pm_mb35 { margin-bottom: 35px; }
  .pm_m-1 { margin: -1px; }
  .pm_mt-1 { margin-top: -1px; }
  .pm_ml-1 { margin-left: -1px; }
  .pm_mr-1 { margin-right: -1px; }
  .pm_mb-1 { margin-bottom: -1px; }
  .pm_m-2 { margin: -2px; }
  .pm_mt-2 { margin-top: -2px; }
  .pm_ml-2 { margin-left: -2px; }
  .pm_mr-2 { margin-right: -2px; }
  .pm_mb-2 { margin-bottom: -2px; }
  .pm_m-3 { margin: -3px; }
  .pm_mt-3 { margin-top: -3px; }
  .pm_ml-3 { margin-left: -3px; }
  .pm_mr-3 { margin-right: -3px; }
  .pm_mb-3 { margin-bottom: -3px; }
  .pm_m-4 { margin: -4px; }
  .pm_mt-4 { margin-top: -4px; }
  .pm_ml-4 { margin-left: -4px; }
  .pm_mr-4 { margin-right: -4px; }
  .pm_mb-4 { margin-bottom: -4px; }
  .pm_m-5 { margin: -5px; }
  .pm_mt-5 { margin-top: -5px; }
  .pm_ml-5 { margin-left: -5px; }
  .pm_mr-5 { margin-right: -5px; }
  .pm_mb-5 { margin-bottom: -5px; }
  .pm_m-7 { margin: -7px; }
  .pm_mt-7 { margin-top: -7px; }
  .pm_ml-7 { margin-left: -7px; }
  .pm_mr-7 { margin-right: -7px; }
  .pm_mb-7 { margin-bottom: -7px; }
  .pm_m-10 { margin: -10px; }
  .pm_mt-10 { margin-top: -10px; }
  .pm_ml-10 { margin-left: -10px; }
  .pm_mr-10 { margin-right: -10px; }
  .pm_mb-10 { margin-bottom: -10px; }
  .pm_m-13 { margin: -13px; }
  .pm_mt-13 { margin-top: -13px; }
  .pm_ml-13 { margin-left: -13px; }
  .pm_mr-13 { margin-right: -13px; }
  .pm_mb-13 { margin-bottom: -13px; }
  .pm_m-15 { margin: -15px; }
  .pm_mt-15 { margin-top: -15px; }
  .pm_ml-15 { margin-left: -15px; }
  .pm_mr-15 { margin-right: -15px; }
  .pm_mb-15 { margin-bottom: -15px; }
  .pm_m-20 { margin: -20px; }
  .pm_mt-20 { margin-top: -20px; }
  .pm_ml-20 { margin-left: -20px; }
  .pm_mr-20 { margin-right: -20px; }
  .pm_mb-20 { margin-bottom: -20px; }
  .pm_m-25 { margin: -25px; }
  .pm_mt-25 { margin-top: -25px; }
  .pm_ml-25 { margin-left: -25px; }
  .pm_mr-25 { margin-right: -25px; }
  .pm_mb-25 { margin-bottom: -25px; }
  .pm_m-30 { margin: -30px; }
  .pm_mt-30 { margin-top: -30px; }
  .pm_ml-30 { margin-left: -30px; }
  .pm_mr-30 { margin-right: -30px; }
  .pm_mb-30 { margin-bottom: -30px; }
  .pm_m-35 { margin: -35px; }
  .pm_mt-35 { margin-top: -35px; }
  .pm_ml-35 { margin-left: -35px; }
  .pm_mr-35 { margin-right: -35px; }
  .pm_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .pm_p0 { padding: 0 !important; }
  .pm_pt0 { padding-top: 0 !important; }
  .pm_pl0 { padding-left: 0 !important; }
  .pm_pr0 { padding-right: 0 !important; }
  .pm_pb0 { padding-bottom: 0 !important; }
  .pm_p1 { padding: 1px !important; }
  .pm_pt1 { padding-top: 1px !important; }
  .pm_pl1 { padding-left: 1px !important; }
  .pm_pr1 { padding-right: 1px !important; }
  .pm_pb1 { padding-bottom: 1px !important; }
  .pm_p2 { padding: 2px !important; }
  .pm_pt2 { padding-top: 2px !important; }
  .pm_pl2 { padding-left: 2px !important; }
  .pm_pr2 { padding-right: 2px !important; }
  .pm_pb2 { padding-bottom: 2px !important; }
  .pm_p3 { padding: 3px !important; }
  .pm_pt3 { padding-top: 3px !important; }
  .pm_pl3 { padding-left: 3px !important; }
  .pm_pr3 { padding-right: 3px !important; }
  .pm_pb3 { padding-bottom: 3px !important; }
  .pm_p4 { padding: 4px !important; }
  .pm_pt4 { padding-top: 4px !important; }
  .pm_pl4 { padding-left: 4px !important; }
  .pm_pr4 { padding-right: 4px !important; }
  .pm_pb4 { padding-bottom: 4px !important; }
  .pm_p5 { padding: 5px !important; }
  .pm_pt5 { padding-top: 5px !important; }
  .pm_pl5 { padding-left: 5px !important; }
  .pm_pr5 { padding-right: 5px !important; }
  .pm_pb5 { padding-bottom: 5px !important; }
  .pm_p7 { padding: 7px !important; }
  .pm_pt7 { padding-top: 7px !important; }
  .pm_pl7 { padding-left: 7px !important; }
  .pm_pr7 { padding-right: 7px !important; }
  .pm_pb7 { padding-bottom: 7px !important; }
  .pm_p10 { padding: 10px !important; }
  .pm_pt10 { padding-top: 10px !important; }
  .pm_pl10 { padding-left: 10px !important; }
  .pm_pr10 { padding-right: 10px !important; }
  .pm_pb10 { padding-bottom: 10px !important; }
  .pm_p13 { padding: 13px !important; }
  .pm_pt13 { padding-top: 13px !important; }
  .pm_pl13 { padding-left: 13px !important; }
  .pm_pr13 { padding-right: 13px !important; }
  .pm_pb13 { padding-bottom: 13px !important; }
  .pm_p15 { padding: 15px !important; }
  .pm_pt15 { padding-top: 15px !important; }
  .pm_pl15 { padding-left: 15px !important; }
  .pm_pr15 { padding-right: 15px !important; }
  .pm_pb15 { padding-bottom: 15px !important; }
  .pm_p20 { padding: 20px !important; }
  .pm_pt20 { padding-top: 20px !important; }
  .pm_pl20 { padding-left: 20px !important; }
  .pm_pr20 { padding-right: 20px !important; }
  .pm_pb20 { padding-bottom: 20px !important; }
  .pm_p25 { padding: 25px !important; }
  .pm_pt25 { padding-top: 25px !important; }
  .pm_pl25 { padding-left: 25px !important; }
  .pm_pr25 { padding-right: 25px !important; }
  .pm_pb25 { padding-bottom: 25px !important; }
  .pm_p30 { padding: 30px !important; }
  .pm_pt30 { padding-top: 30px !important; }
  .pm_pl30 { padding-left: 30px !important; }
  .pm_pr30 { padding-right: 30px !important; }
  .pm_pb30 { padding-bottom: 30px !important; }
  .pm_p35 { padding: 35px !important; }
  .pm_pt35 { padding-top: 35px !important; }
  .pm_pl35 { padding-left: 35px !important; }
  .pm_pr35 { padding-right: 35px !important; }
  .pm_pb35 { padding-bottom: 35px !important; }

  .pm_pt10p { padding-top: 10% !important; }
  .pm_pt15p { padding-top: 15% !important; }
  .pm_pt20p { padding-top: 20% !important; }
  .pm_pt25p { padding-top: 25% !important; }
  .pm_pt33p { padding-top: 33.33333333% !important; }
  .pm_pt35p { padding-top: 35% !important; }
  .pm_pt40p { padding-top: 40% !important; }
  .pm_pt50p { padding-top: 50% !important; }
  .pm_pt60p { padding-top: 60% !important; }
  .pm_pt65p { padding-top: 65% !important; }
  .pm_pt66p { padding-top: 66.66666666% !important; }
  .pm_pt70p { padding-top: 70% !important; }
  .pm_pt75p { padding-top: 75% !important; }
  .pm_pt80p { padding-top: 80% !important; }
  .pm_pt85p { padding-top: 85% !important; }
  .pm_pt90p { padding-top: 90% !important; }
  .pm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .pm_vat { vertical-align: top; }
  .pm_vam { vertical-align: middle; }
  .pm_vab { vertical-align: bottom; }
  .pm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .pm_tal { text-align: left; }
  .pm_tal_i { text-align: left !important; }
  .pm_tac { text-align: center; }
  .pm_tac_i { text-align: center !important; }
  .pm_tar { text-align: right; }
  .pm_tar_i { text-align: right !important; }
  .pm_taj { text-align: justify; }
  .pm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .pm_fsi { font-style: italic; }

  /* Font size */
  .pm_fz0 { font-size: 0; }
  .pm_fz0_i { font-size: 0 !important; }
  .pm_fz10 { font-size: 0.625em; }
  .pm_fz11 { font-size: 0.6875em; }
  .pm_fz12 { font-size: 0.75em; }
  .pm_fz14 { font-size: 0.875em; }
  .pm_fz15 { font-size: 0.9375em; }
  .pm_fz16 { font-size: 1em; }
  .pm_fz18 { font-size: 1.125em; }
  .pm_fz20 { font-size: 1.25em; }
  .pm_fz22 { font-size: 1.375em; }
  .pm_fz24 { font-size: 1.5em; }
  .pm_fz26 { font-size: 1.625em; }
  .pm_fz28 { font-size: 1.75em; }
  .pm_fz30 { font-size: 1.875em; }
  .pm_fz32 { font-size: 2em; }
  .pm_fz10_i { font-size: 0.625em !important; }
  .pm_fz11_i { font-size: 0.6875em !important; }
  .pm_fz12_i { font-size: 0.75em !important; }
  .pm_fz14_i { font-size: 0.875em !important; }
  .pm_fz15_i { font-size: 0.9375em !important; }
  .pm_fz16_i { font-size: 1em !important; }
  .pm_fz18_i { font-size: 1.125em !important; }
  .pm_fz20_i { font-size: 1.25em !important; }
  .pm_fz22_i { font-size: 1.375em !important; }
  .pm_fz24_i { font-size: 1.5em !important; }
  .pm_fz26_i { font-size: 1.625em !important; }
  .pm_fz28_i { font-size: 1.75em !important; }
  .pm_fz30_i { font-size: 1.875em !important; }
  .pm_fz32_i { font-size: 2em !important; }
  .pm_fz10r { font-size: 0.625rem; }
  .pm_fz11r { font-size: 0.6875rem; }
  .pm_fz12r { font-size: 0.75rem; }
  .pm_fz14r { font-size: 0.875rem; }
  .pm_fz15r { font-size: 0.9375rem; }
  .pm_fz16r { font-size: 1rem; }
  .pm_fz18r { font-size: 1.125rem; }
  .pm_fz20r { font-size: 1.25rem; }
  .pm_fz22r { font-size: 1.375rem; }
  .pm_fz24r { font-size: 1.5rem; }
  .pm_fz26r { font-size: 1.625rem; }
  .pm_fz28r { font-size: 1.75rem; }
  .pm_fz30r { font-size: 1.875rem; }
  .pm_fz32r { font-size: 2rem; }
  .pm_fz10r_i { font-size: 0.625rem !important; }
  .pm_fz11r_i { font-size: 0.6875rem !important; }
  .pm_fz12r_i { font-size: 0.75rem !important; }
  .pm_fz14r_i { font-size: 0.875rem !important; }
  .pm_fz15r_i { font-size: 0.9375rem !important; }
  .pm_fz16r_i { font-size: 1rem !important; }
  .pm_fz18r_i { font-size: 1.125rem !important; }
  .pm_fz20r_i { font-size: 1.25rem !important; }
  .pm_fz22r_i { font-size: 1.375rem !important; }
  .pm_fz24r_i { font-size: 1.5rem !important; }
  .pm_fz26r_i { font-size: 1.625rem !important; }
  .pm_fz28r_i { font-size: 1.75rem !important; }
  .pm_fz30r_i { font-size: 1.875rem !important; }
  .pm_fz32r_i { font-size: 2rem !important; }
  .pm_fz10px { font-size: 10px; }
  .pm_fz11px { font-size: 11px; }
  .pm_fz12px { font-size: 12px; }
  .pm_fz14px { font-size: 14px; }
  .pm_fz15px { font-size: 15px; }
  .pm_fz16px { font-size: 16px; }
  .pm_fz18px { font-size: 18px; }
  .pm_fz20px { font-size: 20px; }
  .pm_fz22px { font-size: 22px; }
  .pm_fz24px { font-size: 24px; }
  .pm_fz26px { font-size: 26px; }
  .pm_fz28px { font-size: 28px; }
  .pm_fz30px { font-size: 30px; }
  .pm_fz32px { font-size: 32px; }
  .pm_fz10px_i { font-size: 10px !important; }
  .pm_fz11px_i { font-size: 11px !important; }
  .pm_fz12px_i { font-size: 12px !important; }
  .pm_fz14px_i { font-size: 14px !important; }
  .pm_fz15px_i { font-size: 15px !important; }
  .pm_fz16px_i { font-size: 16px !important; }
  .pm_fz18px_i { font-size: 18px !important; }
  .pm_fz20px_i { font-size: 20px !important; }
  .pm_fz22px_i { font-size: 22px !important; }
  .pm_fz24px_i { font-size: 24px !important; }
  .pm_fz26px_i { font-size: 26px !important; }
  .pm_fz28px_i { font-size: 28px !important; }
  .pm_fz30px_i { font-size: 30px !important; }
  .pm_fz32px_i { font-size: 32px !important; }

  /* Font weight */
  .pm_fw700, .pm_fw700_a, .pm_fw700_a *, .pm_bold, .pm_bold_a, .pm_bold_a * { font-weight: 700; }
  .pm_fw700_i, .pm_fw700_a_i, .pm_fw700_a_i *, .pm_bold_i, .pm_bold_a_i, .pm_bold_a_i * { font-weight: 700 !important; }
  .pm_fw600, .pm_fw600_a, .pm_fw600_a *, .pm_semibold, .pm_semibold_a, .pm_semibold_a * { font-weight: 600; }
  .pm_fw600_i, .pm_fw600_a_i, .pm_fw600_a_i *, .pm_semibold_i, .pm_semibold_a_i, .pm_semibold_a_i * { font-weight: 600 !important; }
  .pm_fw500, .pm_fw500_a, .pm_fw500_a * { font-weight: 500; }
  .pm_fw500_i, .pm_fw500_a_i, .pm_fw500_a_i * { font-weight: 500 !important; }
  .pm_fw400, .pm_fw400_a, .pm_fw400_a * { font-weight: 400; }
  .pm_fw400_i, .pm_fw400_a_i, .pm_fw400_a_i * { font-weight: 400 !important; }


  /* Floating */
  /* ======== */
  .pm_fln { float: none; }
  .pm_fll { float: left; }
  .pm_flr { float: right; }
}
@media screen and (max-width: 479px) {
  .header-slogan,
  .header-logo + .header-slogan,
  .header-contacts > div {
    padding-left: 10px;
  }
    .header-phones a {
      font-size: 15px;
    }



  .form-popup-container .form-block-container {
    min-width: unset;
  }


  .service-block-container .list-view {
    justify-content: center;
  }
    .service-block-container .list-view-item {
      width: 100%;
      max-width: 380px;
    }
      .service-block-container .list-view-item-wrapper {
        min-height: 35vw;
      }


  .portfolio-block-container .list-view-item {
    width: 100%;
  }
    .portfolio-block-container .list-view-item-wrapper {
      max-width: 350px;
      margin: auto;
    }


  .incut {
    display: block;
  }
    .incut:before {
      display: block;
      background-size: auto 40%;
      max-width: unset;
      min-height: 90px;
      max-height: 90px;
      margin-right: unset;
      margin-bottom: 4px;
    }


  .footer-copyrights > div {
    min-width: 51%;
  }


  .footer-social-panel a[class^="icon-"]:before {
    margin-right: 5px;
  }
}

@media screen and (max-width: 406px) {
  .team-member-block-container .list-view-item-image {
    max-width: 28%;
    min-width: 28%;
  }
}

/* small mobile - sm */
@media screen and (max-width: 359px) {
  /* Line height */
  /* =========== */
  .sm_lh10 { line-height: 1; }
  .sm_lh100p { line-height: 100%; }
  .sm_lh11 { line-height: 1.1; }
  .sm_lh110p { line-height: 110%; }
  .sm_lh12 { line-height: 1.2; }
  .sm_lh120p { line-height: 120%; }
  .sm_lh13 { line-height: 1.3; }
  .sm_lh130p { line-height: 130%; }
  .sm_lh14 { line-height: 1.4; }
  .sm_lh140p { line-height: 140%; }
  .sm_lh15 { line-height: 1.5; }
  .sm_lh150p { line-height: 150%; }
  .sm_lh16 { line-height: 1.6; }
  .sm_lh160p { line-height: 160%; }
  .sm_lh17 { line-height: 1.7; }
  .sm_lh170p { line-height: 170%; }
  .sm_lh18 { line-height: 1.8; }
  .sm_lh180p { line-height: 180%; }
  .sm_lh19 { line-height: 1.9; }
  .sm_lh190p { line-height: 190%; }
  .sm_lh20 { line-height: 2; }
  .sm_lh200p { line-height: 200%; }


  /* Display */
  /* ======= */
  .sm_dn { display: none; }
  .sm_dn_i { display: none !important; }
  .sm_db { display: block; }
  .sm_di { display: inline; }
  .sm_dib { display: inline-block; }
  .sm_dt { display: table; }
  .sm_dtr { display: table-row; }
  .sm_dtc { display: table-cell; }

  /* Flex */
  .sm_dfdr { display: flex; flex-direction: row; }
  .sm_dfdrr { display: flex; flex-direction: row-reverse; }
  .sm_dfdc { display: flex; flex-direction: column; }
  .sm_dfdcr { display: flex; flex-direction: column-reverse; }
  .sm_difdr { display: inline-flex; flex-direction: row; }
  .sm_difdrr { display: inline-flex; flex-direction: row-reverse; }
  .sm_difdc { display: inline-flex; flex-direction: column; }
  .sm_difdcr { display: inline-flex; flex-direction: column-reverse; }
  .sm_fwn { flex-wrap: nowrap; }
  .sm_fww { flex-wrap: wrap; }
  .sm_fwwr { flex-wrap: wrap-reverse; }
  .sm_fjcs { justify-content: flex-start; }
  .sm_fjce { justify-content: flex-end; }
  .sm_fjcc { justify-content: center; }
  .sm_fjcsa { justify-content: space-around; }
  .sm_fjcsb { justify-content: space-between; }
  .sm_fais { align-items: flex-start; }
  .sm_faie { align-items: flex-end; }
  .sm_faic { align-items: center; }
  .sm_faib { align-items: baseline; }
  .sm_faist { align-items: stretch; }
  .sm_facs { align-content: flex-start; }
  .sm_face { align-content: flex-end; }
  .sm_facc { align-content: center; }
  .sm_facsa { align-content: space-around; }
  .sm_facsb { align-content: space-between; }
  .sm_facst { align-content: stretch; }
  .sm_fass { align-self: flex-start; }
  .sm_fase { align-self: flex-end; }
  .sm_fasc { align-self: center; }
  .sm_fasb { align-self: baseline; }
  .sm_fasst { align-self: stretch; }


  /* Height */
  /* ====== */
  .sm_h0 { height: 0; }
  .sm_h100p, .sm_hfull { height: 100%; }
  .sm_hauto { height: auto; }


  /* Width */
  /* ===== */
  .sm_w0 { width: 0; }
  .sm_w1 { width: 1px; }
  .sm_w5p { width: 5%; }
  .sm_w7p { width: 7%; }
  .sm_w10p { width: 10%; }
  .sm_w12p { width: 12.5%; }
  .sm_w15p { width: 15%; }
  .sm_w20p { width: 20%; }
  .sm_w25p { width: 25%; }
  .sm_w33p { width: 33.33333333%; }
  .sm_w35p { width: 35%; }
  .sm_w40p { width: 40%; }
  .sm_w50p { width: 50%; }
  .sm_w60p { width: 60%; }
  .sm_w65p { width: 65%; }
  .sm_w66p { width: 66.66666666%; }
  .sm_w70p { width: 70%; }
  .sm_w75p { width: 75%; }
  .sm_w80p { width: 80%; }
  .sm_w85p { width: 85%; }
  .sm_w88p { width: 87.5%; }
  .sm_w90p { width: 90%; }
  .sm_w93p { width: 93%; }
  .sm_w95p { width: 95%; }
  .sm_w100p, .sm_wfull { width: 100%; }
  .sm_wauto { width: auto; }


  /* Margins */
  /* ======= */
  .sm_m0auto, .sm_m0a { margin: 0 auto; }
  .sm_m0auto_i, .sm_m0a_i { margin: 0 auto !important; }
  .sm_mauto0, .sm_ma0 { margin: auto 0; }
  .sm_mauto0_i, .sm_ma0_i { margin: auto 0 !important; }
  .sm_mauto, .sm_ma { margin: auto; }
  .sm_mauto_i, .sm_ma_i { margin: auto !important; }
  .sm_mtauto, .sm_mta { margin-top: auto; }
  .sm_mtauto_i, .sm_mta_i { margin-top: auto !important; }
  .sm_mlauto, .sm_mla { margin-left: auto; }
  .sm_mlauto_i, .sm_mla_i { margin-left: auto !important; }
  .sm_mrauto, .sm_mra { margin-right: auto; }
  .sm_mrauto_i, .sm_mra_i { margin-right: auto !important; }
  .sm_mbauto, .sm_mba { margin-bottom: auto; }
  .sm_mbauto_i, .sm_mba_i { margin-bottom: auto !important; }
  .sm_m0 { margin: 0; }
  .sm_m0_i { margin: 0 !important; }
  .sm_mt0 { margin-top: 0; }
  .sm_mt0_i { margin-top: 0 !important; }
  .sm_ml0 { margin-left: 0; }
  .sm_ml0_i { margin-left: 0 !important; }
  .sm_mr0 { margin-right: 0; }
  .sm_mr0_i { margin-right: 0 !important; }
  .sm_mb0 { margin-bottom: 0; }
  .sm_mb0_i { margin-bottom: 0 !important; }
  .sm_m1 { margin: 1px; }
  .sm_mt1 { margin-top: 1px; }
  .sm_ml1 { margin-left: 1px; }
  .sm_mr1 { margin-right: 1px; }
  .sm_mb1 { margin-bottom: 1px; }
  .sm_m2 { margin: 2px; }
  .sm_mt2 { margin-top: 2px; }
  .sm_ml2 { margin-left: 2px; }
  .sm_mr2 { margin-right: 2px; }
  .sm_mb2 { margin-bottom: 2px; }
  .sm_m3 { margin: 3px; }
  .sm_mt3 { margin-top: 3px; }
  .sm_ml3 { margin-left: 3px; }
  .sm_mr3 { margin-right: 3px; }
  .sm_mb3 { margin-bottom: 3px; }
  .sm_m4 { margin: 4px; }
  .sm_mt4 { margin-top: 4px; }
  .sm_ml4 { margin-left: 4px; }
  .sm_mr4 { margin-right: 4px; }
  .sm_mb4 { margin-bottom: 4px; }
  .sm_m5 { margin: 5px; }
  .sm_mt5 { margin-top: 5px; }
  .sm_ml5 { margin-left: 5px; }
  .sm_mr5 { margin-right: 5px; }
  .sm_mb5 { margin-bottom: 5px; }
  .sm_m7 { margin: 7px; }
  .sm_mt7 { margin-top: 7px; }
  .sm_ml7 { margin-left: 7px; }
  .sm_mr7 { margin-right: 7px; }
  .sm_mb7 { margin-bottom: 7px; }
  .sm_m10 { margin: 10px; }
  .sm_mt10 { margin-top: 10px; }
  .sm_ml10 { margin-left: 10px; }
  .sm_mr10 { margin-right: 10px; }
  .sm_mb10 { margin-bottom: 10px; }
  .sm_m13 { margin: 13px; }
  .sm_mt13 { margin-top: 13px; }
  .sm_ml13 { margin-left: 13px; }
  .sm_mr13 { margin-right: 13px; }
  .sm_mb13 { margin-bottom: 13px; }
  .sm_m15 { margin: 15px; }
  .sm_mt15 { margin-top: 15px; }
  .sm_ml15 { margin-left: 15px; }
  .sm_mr15 { margin-right: 15px; }
  .sm_mb15 { margin-bottom: 15px; }
  .sm_m20 { margin: 20px; }
  .sm_mt20 { margin-top: 20px; }
  .sm_ml20 { margin-left: 20px; }
  .sm_mr20 { margin-right: 20px; }
  .sm_mb20 { margin-bottom: 20px; }
  .sm_m25 { margin: 25px; }
  .sm_mt25 { margin-top: 25px; }
  .sm_ml25 { margin-left: 25px; }
  .sm_mr25 { margin-right: 25px; }
  .sm_mb25 { margin-bottom: 25px; }
  .sm_m30 { margin: 30px; }
  .sm_mt30 { margin-top: 30px; }
  .sm_ml30 { margin-left: 30px; }
  .sm_mr30 { margin-right: 30px; }
  .sm_mb30 { margin-bottom: 30px; }
  .sm_m35 { margin: 35px; }
  .sm_mt35 { margin-top: 35px; }
  .sm_ml35 { margin-left: 35px; }
  .sm_mr35 { margin-right: 35px; }
  .sm_mb35 { margin-bottom: 35px; }
  .sm_m-1 { margin: -1px; }
  .sm_mt-1 { margin-top: -1px; }
  .sm_ml-1 { margin-left: -1px; }
  .sm_mr-1 { margin-right: -1px; }
  .sm_mb-1 { margin-bottom: -1px; }
  .sm_m-2 { margin: -2px; }
  .sm_mt-2 { margin-top: -2px; }
  .sm_ml-2 { margin-left: -2px; }
  .sm_mr-2 { margin-right: -2px; }
  .sm_mb-2 { margin-bottom: -2px; }
  .sm_m-3 { margin: -3px; }
  .sm_mt-3 { margin-top: -3px; }
  .sm_ml-3 { margin-left: -3px; }
  .sm_mr-3 { margin-right: -3px; }
  .sm_mb-3 { margin-bottom: -3px; }
  .sm_m-4 { margin: -4px; }
  .sm_mt-4 { margin-top: -4px; }
  .sm_ml-4 { margin-left: -4px; }
  .sm_mr-4 { margin-right: -4px; }
  .sm_mb-4 { margin-bottom: -4px; }
  .sm_m-5 { margin: -5px; }
  .sm_mt-5 { margin-top: -5px; }
  .sm_ml-5 { margin-left: -5px; }
  .sm_mr-5 { margin-right: -5px; }
  .sm_mb-5 { margin-bottom: -5px; }
  .sm_m-7 { margin: -7px; }
  .sm_mt-7 { margin-top: -7px; }
  .sm_ml-7 { margin-left: -7px; }
  .sm_mr-7 { margin-right: -7px; }
  .sm_mb-7 { margin-bottom: -7px; }
  .sm_m-10 { margin: -10px; }
  .sm_mt-10 { margin-top: -10px; }
  .sm_ml-10 { margin-left: -10px; }
  .sm_mr-10 { margin-right: -10px; }
  .sm_mb-10 { margin-bottom: -10px; }
  .sm_m-13 { margin: -13px; }
  .sm_mt-13 { margin-top: -13px; }
  .sm_ml-13 { margin-left: -13px; }
  .sm_mr-13 { margin-right: -13px; }
  .sm_mb-13 { margin-bottom: -13px; }
  .sm_m-15 { margin: -15px; }
  .sm_mt-15 { margin-top: -15px; }
  .sm_ml-15 { margin-left: -15px; }
  .sm_mr-15 { margin-right: -15px; }
  .sm_mb-15 { margin-bottom: -15px; }
  .sm_m-20 { margin: -20px; }
  .sm_mt-20 { margin-top: -20px; }
  .sm_ml-20 { margin-left: -20px; }
  .sm_mr-20 { margin-right: -20px; }
  .sm_mb-20 { margin-bottom: -20px; }
  .sm_m-25 { margin: -25px; }
  .sm_mt-25 { margin-top: -25px; }
  .sm_ml-25 { margin-left: -25px; }
  .sm_mr-25 { margin-right: -25px; }
  .sm_mb-25 { margin-bottom: -25px; }
  .sm_m-30 { margin: -30px; }
  .sm_mt-30 { margin-top: -30px; }
  .sm_ml-30 { margin-left: -30px; }
  .sm_mr-30 { margin-right: -30px; }
  .sm_mb-30 { margin-bottom: -30px; }
  .sm_m-35 { margin: -35px; }
  .sm_mt-35 { margin-top: -35px; }
  .sm_ml-35 { margin-left: -35px; }
  .sm_mr-35 { margin-right: -35px; }
  .sm_mb-35 { margin-bottom: -35px; }


  /* Paddings */
  /* ======== */
  .sm_p0 { padding: 0 !important; }
  .sm_pt0 { padding-top: 0 !important; }
  .sm_pl0 { padding-left: 0 !important; }
  .sm_pr0 { padding-right: 0 !important; }
  .sm_pb0 { padding-bottom: 0 !important; }
  .sm_p1 { padding: 1px !important; }
  .sm_pt1 { padding-top: 1px !important; }
  .sm_pl1 { padding-left: 1px !important; }
  .sm_pr1 { padding-right: 1px !important; }
  .sm_pb1 { padding-bottom: 1px !important; }
  .sm_p2 { padding: 2px !important; }
  .sm_pt2 { padding-top: 2px !important; }
  .sm_pl2 { padding-left: 2px !important; }
  .sm_pr2 { padding-right: 2px !important; }
  .sm_pb2 { padding-bottom: 2px !important; }
  .sm_p3 { padding: 3px !important; }
  .sm_pt3 { padding-top: 3px !important; }
  .sm_pl3 { padding-left: 3px !important; }
  .sm_pr3 { padding-right: 3px !important; }
  .sm_pb3 { padding-bottom: 3px !important; }
  .sm_p4 { padding: 4px !important; }
  .sm_pt4 { padding-top: 4px !important; }
  .sm_pl4 { padding-left: 4px !important; }
  .sm_pr4 { padding-right: 4px !important; }
  .sm_pb4 { padding-bottom: 4px !important; }
  .sm_p5 { padding: 5px !important; }
  .sm_pt5 { padding-top: 5px !important; }
  .sm_pl5 { padding-left: 5px !important; }
  .sm_pr5 { padding-right: 5px !important; }
  .sm_pb5 { padding-bottom: 5px !important; }
  .sm_p7 { padding: 7px !important; }
  .sm_pt7 { padding-top: 7px !important; }
  .sm_pl7 { padding-left: 7px !important; }
  .sm_pr7 { padding-right: 7px !important; }
  .sm_pb7 { padding-bottom: 7px !important; }
  .sm_p10 { padding: 10px !important; }
  .sm_pt10 { padding-top: 10px !important; }
  .sm_pl10 { padding-left: 10px !important; }
  .sm_pr10 { padding-right: 10px !important; }
  .sm_pb10 { padding-bottom: 10px !important; }
  .sm_p13 { padding: 13px !important; }
  .sm_pt13 { padding-top: 13px !important; }
  .sm_pl13 { padding-left: 13px !important; }
  .sm_pr13 { padding-right: 13px !important; }
  .sm_pb13 { padding-bottom: 13px !important; }
  .sm_p15 { padding: 15px !important; }
  .sm_pt15 { padding-top: 15px !important; }
  .sm_pl15 { padding-left: 15px !important; }
  .sm_pr15 { padding-right: 15px !important; }
  .sm_pb15 { padding-bottom: 15px !important; }
  .sm_p20 { padding: 20px !important; }
  .sm_pt20 { padding-top: 20px !important; }
  .sm_pl20 { padding-left: 20px !important; }
  .sm_pr20 { padding-right: 20px !important; }
  .sm_pb20 { padding-bottom: 20px !important; }
  .sm_p25 { padding: 25px !important; }
  .sm_pt25 { padding-top: 25px !important; }
  .sm_pl25 { padding-left: 25px !important; }
  .sm_pr25 { padding-right: 25px !important; }
  .sm_pb25 { padding-bottom: 25px !important; }
  .sm_p30 { padding: 30px !important; }
  .sm_pt30 { padding-top: 30px !important; }
  .sm_pl30 { padding-left: 30px !important; }
  .sm_pr30 { padding-right: 30px !important; }
  .sm_pb30 { padding-bottom: 30px !important; }
  .sm_p35 { padding: 35px !important; }
  .sm_pt35 { padding-top: 35px !important; }
  .sm_pl35 { padding-left: 35px !important; }
  .sm_pr35 { padding-right: 35px !important; }
  .sm_pb35 { padding-bottom: 35px !important; }

  .sm_pt10p { padding-top: 10% !important; }
  .sm_pt15p { padding-top: 15% !important; }
  .sm_pt20p { padding-top: 20% !important; }
  .sm_pt25p { padding-top: 25% !important; }
  .sm_pt33p { padding-top: 33.33333333% !important; }
  .sm_pt35p { padding-top: 35% !important; }
  .sm_pt40p { padding-top: 40% !important; }
  .sm_pt50p { padding-top: 50% !important; }
  .sm_pt60p { padding-top: 60% !important; }
  .sm_pt65p { padding-top: 65% !important; }
  .sm_pt66p { padding-top: 66.66666666% !important; }
  .sm_pt70p { padding-top: 70% !important; }
  .sm_pt75p { padding-top: 75% !important; }
  .sm_pt80p { padding-top: 80% !important; }
  .sm_pt85p { padding-top: 85% !important; }
  .sm_pt90p { padding-top: 90% !important; }
  .sm_pt100p { padding-top: 100% !important; }


  /* Aligning */
  /* ======== */
  /* Vertical align */
  .sm_vat { vertical-align: top; }
  .sm_vam { vertical-align: middle; }
  .sm_vab { vertical-align: bottom; }
  .sm_vabl { vertical-align: baseline; }

  /* Horizontal text align */
  .sm_tal { text-align: left; }
  .sm_tal_i { text-align: left !important; }
  .sm_tac { text-align: center; }
  .sm_tac_i { text-align: center !important; }
  .sm_tar { text-align: right; }
  .sm_tar_i { text-align: right !important; }
  .sm_taj { text-align: justify; }
  .sm_taj_i { text-align: justify !important; }


  /* Text styling */
  /* ============ */
  /* Font style*/
  .sm_fsi { font-style: italic; }

  /* Font size */
  .sm_fz0 { font-size: 0; }
  .sm_fz0_i { font-size: 0 !important; }
  .sm_fz10 { font-size: 0.625em; }
  .sm_fz11 { font-size: 0.6875em; }
  .sm_fz12 { font-size: 0.75em; }
  .sm_fz14 { font-size: 0.875em; }
  .sm_fz15 { font-size: 0.9375em; }
  .sm_fz16 { font-size: 1em; }
  .sm_fz18 { font-size: 1.125em; }
  .sm_fz20 { font-size: 1.25em; }
  .sm_fz22 { font-size: 1.375em; }
  .sm_fz24 { font-size: 1.5em; }
  .sm_fz26 { font-size: 1.625em; }
  .sm_fz28 { font-size: 1.75em; }
  .sm_fz30 { font-size: 1.875em; }
  .sm_fz32 { font-size: 2em; }
  .sm_fz10_i { font-size: 0.625em !important; }
  .sm_fz11_i { font-size: 0.6875em !important; }
  .sm_fz12_i { font-size: 0.75em !important; }
  .sm_fz14_i { font-size: 0.875em !important; }
  .sm_fz15_i { font-size: 0.9375em !important; }
  .sm_fz16_i { font-size: 1em !important; }
  .sm_fz18_i { font-size: 1.125em !important; }
  .sm_fz20_i { font-size: 1.25em !important; }
  .sm_fz22_i { font-size: 1.375em !important; }
  .sm_fz24_i { font-size: 1.5em !important; }
  .sm_fz26_i { font-size: 1.625em !important; }
  .sm_fz28_i { font-size: 1.75em !important; }
  .sm_fz30_i { font-size: 1.875em !important; }
  .sm_fz32_i { font-size: 2em !important; }
  .sm_fz10r { font-size: 0.625rem; }
  .sm_fz11r { font-size: 0.6875rem; }
  .sm_fz12r { font-size: 0.75rem; }
  .sm_fz14r { font-size: 0.875rem; }
  .sm_fz15r { font-size: 0.9375rem; }
  .sm_fz16r { font-size: 1rem; }
  .sm_fz18r { font-size: 1.125rem; }
  .sm_fz20r { font-size: 1.25rem; }
  .sm_fz22r { font-size: 1.375rem; }
  .sm_fz24r { font-size: 1.5rem; }
  .sm_fz26r { font-size: 1.625rem; }
  .sm_fz28r { font-size: 1.75rem; }
  .sm_fz30r { font-size: 1.875rem; }
  .sm_fz32r { font-size: 2rem; }
  .sm_fz10r_i { font-size: 0.625rem !important; }
  .sm_fz11r_i { font-size: 0.6875rem !important; }
  .sm_fz12r_i { font-size: 0.75rem !important; }
  .sm_fz14r_i { font-size: 0.875rem !important; }
  .sm_fz15r_i { font-size: 0.9375rem !important; }
  .sm_fz16r_i { font-size: 1rem !important; }
  .sm_fz18r_i { font-size: 1.125rem !important; }
  .sm_fz20r_i { font-size: 1.25rem !important; }
  .sm_fz22r_i { font-size: 1.375rem !important; }
  .sm_fz24r_i { font-size: 1.5rem !important; }
  .sm_fz26r_i { font-size: 1.625rem !important; }
  .sm_fz28r_i { font-size: 1.75rem !important; }
  .sm_fz30r_i { font-size: 1.875rem !important; }
  .sm_fz32r_i { font-size: 2rem !important; }
  .sm_fz10px { font-size: 10px; }
  .sm_fz11px { font-size: 11px; }
  .sm_fz12px { font-size: 12px; }
  .sm_fz14px { font-size: 14px; }
  .sm_fz15px { font-size: 15px; }
  .sm_fz16px { font-size: 16px; }
  .sm_fz18px { font-size: 18px; }
  .sm_fz20px { font-size: 20px; }
  .sm_fz22px { font-size: 22px; }
  .sm_fz24px { font-size: 24px; }
  .sm_fz26px { font-size: 26px; }
  .sm_fz28px { font-size: 28px; }
  .sm_fz30px { font-size: 30px; }
  .sm_fz32px { font-size: 32px; }
  .sm_fz10px_i { font-size: 10px !important; }
  .sm_fz11px_i { font-size: 11px !important; }
  .sm_fz12px_i { font-size: 12px !important; }
  .sm_fz14px_i { font-size: 14px !important; }
  .sm_fz15px_i { font-size: 15px !important; }
  .sm_fz16px_i { font-size: 16px !important; }
  .sm_fz18px_i { font-size: 18px !important; }
  .sm_fz20px_i { font-size: 20px !important; }
  .sm_fz22px_i { font-size: 22px !important; }
  .sm_fz24px_i { font-size: 24px !important; }
  .sm_fz26px_i { font-size: 26px !important; }
  .sm_fz28px_i { font-size: 28px !important; }
  .sm_fz30px_i { font-size: 30px !important; }
  .sm_fz32px_i { font-size: 32px !important; }

  /* Font weight */
  .sm_fw700, .sm_fw700_a, .sm_fw700_a *, .sm_bold, .sm_bold_a, .sm_bold_a * { font-weight: 700; }
  .sm_fw700_i, .sm_fw700_a_i, .sm_fw700_a_i *, .sm_bold_i, .sm_bold_a_i, .sm_bold_a_i * { font-weight: 700 !important; }
  .sm_fw600, .sm_fw600_a, .sm_fw600_a *, .sm_semibold, .sm_semibold_a, .sm_semibold_a * { font-weight: 600; }
  .sm_fw600_i, .sm_fw600_a_i, .sm_fw600_a_i *, .sm_semibold_i, .sm_semibold_a_i, .sm_semibold_a_i * { font-weight: 600 !important; }
  .sm_fw500, .sm_fw500_a, .sm_fw500_a * { font-weight: 500; }
  .sm_fw500_i, .sm_fw500_a_i, .sm_fw500_a_i * { font-weight: 500 !important; }
  .sm_fw400, .sm_fw400_a, .sm_fw400_a * { font-weight: 400; }
  .sm_fw400_i, .sm_fw400_a_i, .sm_fw400_a_i * { font-weight: 400 !important; }


  /* Floating */
  /* ======== */
  .sm_fln { float: none; }
  .sm_fll { float: left; }
  .sm_flr { float: right; }
}
@media screen and (max-width: 359px) {
}

@media screen and (max-width: 319px) {
  .header-logo {
    display: none;
  }
}