/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fafafb;
}

.ffarial { font-family: "Arial", "Helvetica", sans-serif; }

.semibold, .semibold_a, .semibold_a * { font-weight: 600; }

span.required { color: #e04949; font-weight: 700; }

.bgcgreen, .bgcgreen_h:hover { background-color: #1a580d; }
.bgcgreen_i, .bgcgreen_h_i:hover { background-color: #1a580d !important; }

.cgreen, .cgreen_h:hover { color: #1a580d; }
.cgreen_i, .cgreen_h_i:hover { color: #1a580d !important; }

.brcgreen, .brcgreen_h:hover { border-color: #1a580d; }
.brcgreen_i, .brcgreen_h_i:hover { border-color: #1a580d !important; }

p { margin-bottom: 16px; }
p + p, .subheader + p, .subsubheader + p, .subsubsubheader + p { margin-top: -4px; }

.link {
  color: #1a580d;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }



.form-block-container {
  background: #fff;
  padding: 40px;
}
  .form-popup-container .form-block-container {
    border: 4px solid #1a580d;
    min-width: 380px;
    max-width: 700px;
  }
    .form-popup-container.fancybox-confirm .form-block-container {
      min-width: unset;
    }

  .form-block-container > .subsubheader {
    margin-top: -12px;
  }

  .form {
    position: relative;
  }
    .form-row + .form-row {
      margin-top: 22px;
    }
      .form-label {
        margin-bottom: 7px;
      }
        .form-row-checkbox .form-label {
          font-size: 14px;
        }

    .form-row-checkboxlist .form-field > div + div,
    .form-row-radiolist .form-field > div + div {
      margin: 4px 0px 0px;
    }
      .form-row-checkboxlist .form-field > div > label > span,
      .form-row-radiolist .form-field > div > label > span,
      .form-row-checkbox .form-field-wrapper {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
        .form-row-checkboxlist .form-field > div,
        .form-row-radiolist .form-field > div,
        .form-row-checkbox .form-field {
          position: relative;
        }
          .form-row-checkboxlist .form-field > div > input,
          .form-row-radiolist .form-field > div > input,
          .form-row-checkbox .form-field input {
            border: none;
            width: 0px;
            height: 0px;
            padding: 0px;
            margin: auto;
            top: 0px;
            left: 2px;
            bottom: 0px;
            position: absolute;
            opacity: 0;
            z-index: -1;
          }

          .form-row-checkboxlist .form-field > div > label > span > span:first-child,
          .form-row-radiolist .form-field > div > label > span > span:first-child,
          .form-row-checkbox .form-field > span {
            content: "";
            color: transparent;
            background: #fff;
            border: 1px solid #979797;
            font-size: 0px;
            line-height: 0px;
            display: block;
            width: 22px;
            min-width: 22px;
            height: 22px;
            position: relative;
            cursor: pointer;
          }
          .form-row-radiolist .form-field > div > label > span > span:first-child {
            border-radius: 50%;
            width: 18px;
            min-width: 18px;
            height: 18px;
            margin: 1px 1px 1px 3px;
          }
            .form-row-checkboxlist .form-field.error > div > label > span > span:first-child,
            .form-row-radiolist .form-field.error > div > label > span > span:first-child,
            .form-row-checkbox .form-field.error > span {
              border-color: #e04949;
            }

            .form-row-checkboxlist .form-field > div > label > span > span:first-child:before,
            .form-row-radiolist .form-field > div > label > span > span:first-child:before,
            .form-row-checkbox .form-field > span label {
              content: "";
              color: transparent;
              display: block;
              font-size: 0px;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              margin: auto;
              cursor: pointer;
            }
              .form-row-checkboxlist .form-field > div > label > span > span:first-child:before,
              .form-row-checkbox .form-field > span label:after {
                content: "";
                display: none;
                background: url('/i/icon-tick.svg') no-repeat 50% 50%/12px auto transparent;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;
              }
                .form-row-checkboxlist .form-field > div > input:checked + label > span > span:first-child:before,
                .form-row-checkbox .form-field input:checked + span label:after {
                  display: block;
                }

              .form-row-radiolist .form-field > div > input:checked + label > span > span:first-child:before {
                background: #1a580d;
                border-radius: 50%;
                margin: 4px;
              }

        .form-row-checkboxlist .form-field > div > label > span > span + span,
        .form-row-radiolist .form-field > div > label > span > span + span,
        .form-row-checkbox .form-label {
          padding: 0px 0px 0px 10px;
          margin: 0px;
          cursor: pointer;
        }
          .form-row-checkbox .form-label label {
            display: block;
            cursor: pointer;
          }


      .input-container {
        background: #fff;
        border: 1px solid #979797;
        padding: 7px 10px 6px;
        position: relative;
        z-index: 1;
      }
        .input-container.error {
          border-color: #e04949 !important;
        }

        .input-container input,
        .input-container textarea,
        .input-container select {
          color: #000;
          background: transparent;
          font-size: 1em;
          font-weight: 400;
          line-height: 1.3;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
        .input-container input:not([type="radio"]):not([type="checkbox"]),
        .input-container textarea,
        .input-container select {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }

      .form-error {
        position: relative;
      }
        .form-row-checkboxlist .form-error,
        .form-row-radiolist .form-error,
        .form-row-checkbox .form-error {
          margin: 3px 0px 0px;
        }

        .errorMessage {
          color: #fff;
          background: #e04949;
          font-size: 12px;
          line-height: 1.3;
          padding: 3px 6px;
        }

      .form-row .hint {
        color: #505050;
        font-size: 13px;
        padding: 2px 0px 0px;
      }
        .form-row-checkboxlist .hint,
        .form-row-radiolist .hint,
        .form-row-checkbox .hint {
          padding-left: 32px;
        }


    .form-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      margin: 7px 0px 0px -15px;
    }
      .form-button {
        padding: 15px 0px 0px 15px;
      }


.button-style {
  display: inline-block;
  color: #fff;
  background: #000;
  border: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  min-width: 10em;
  padding: 0.75em 1.2em;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  -webkit-transition: color .2s ease-in-out 0s;
  -moz-transition: color .2s ease-in-out 0s;
  -o-transition: color .2s ease-in-out 0s;
  transition: color .2s ease-in-out 0s;
}
  .button-style.button-green {
    background: #1a580d;
  }

  .button-style:before {
    content: "";
    background: #fff;
    position: absolute;
    top: 0px;
    left: 50%;
    right: 50%;
    bottom: 0px;
    margin: 0px auto;
    opacity: 0.25;
    z-index: 1;

    -webkit-transform: skew(-45deg);
    -moz-transform: skew(-45deg);
    -ms-transform: skew(-45deg);
    -o-transform: skew(-45deg);
    transform: skew(-45deg);

    -webkit-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    -moz-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    -o-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
  }
    .button-style.button-green:before {
      opacity: 0.15;
    }

    .button-style:hover:before {
      left: -1.5em;
      right: -1.5em;
    }

  .button-style * {
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
  }


ul.ul, ol.ol {
  margin-top: -6px;
  margin-bottom: 20px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 18px;
    margin-top: 0px;
    margin-bottom: 6px;
  }
  ul.ul li, ol.ol li {
    padding-top: 6px;
    padding-bottom: 0px;
    position: relative;
  }
  ul.ul li {
    padding-left: 1.1875em;
  }
    ul.ul li:before {
      content: "●";
      color: transparent;
      display: inline-block;
      background: url('/i/ul-list-item.svg') no-repeat 50% 50%/contain transparent;
      margin-right: 0.725em;
      margin-left: -1.1875em;
      width: 0.4625em;
      min-width: 0.4625em;
      height: 1em;
      vertical-align: middle;
    }

    ul.ul li.dfdr {
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 0px;
    }
      ul.ul li.dfdr:before {
        margin-left: 0px;
        height: unset;
      }

    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      font-weight: 500;
      color: #1a580d;
    }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:before {
    content: "";
    background: url('/i/gallery-image-zoom.svg') 50% 50% no-repeat transparent;
    background-size: contain;
    position: absolute;
    top: 45%;
    left: 45%;
    width: 10%;
    height: 10%;
    opacity: 0;

    -webkit-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    -moz-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    -o-transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
    transition: top 0.2s ease-in-out 0s, left 0.2s ease-in-out 0s, width 0.2s ease-in-out 0s, height 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  }
    a.zoom-image:hover:before {
      top: 37%;
      left: 37%;
      width: 26%;
      height: 26%;
      opacity: 0.9 !important;
    }



.unwrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
  .unwrapper > div {
    width: 100vw;
    min-width: 100vw;
  }

.wrapper {
  width: 1210px;
  margin: 0px auto;
  padding: 0px;
  position: relative;
}


/* Structure */
.structure {
  overflow: hidden;
}
  /* Header */
  .header-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: stretch;
  }
    .header {
      position: relative;
      z-index: 5;
    }
      .header-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        padding: 15px 0px;
      }
        .header-logo-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex: 1 5 auto;
        }
          .header-slogan {
            font-size: 14px;
          }
            .header-logo + .header-slogan {
              padding-left: 15px;
            }


        .header-contacts {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex: 3 3 auto;
        }
          .header-contacts > div {
            flex-grow: 1;
            padding-left: 15px;
          }
            .header-phones > div {
              padding-bottom: 4px;
            }
              .header-phones a {
                font-size: 20px;
                font-weight: 600;
                line-height: 1.2;
                text-decoration: none;
                white-space: nowrap;
                cursor: default;
              }
            .header-worktime {
              font-size: 12px;
            }

            .header-buttons {
              text-align: center;
            }
              .header-buttons > div {
                display: inline-flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
              }
                .header-buttons .button-style {
                  font-size: 12px;
                  width: 100%;
                }
                .header-buttons .button-style + .button-style {
                  margin-top: 4px;
                }


      .header-menu-container {
        background: #1a580d;
      }
        .header-menu-button-container,
        .header-menu-checkbox,
        .header-menu-button-close {
          display: none;
        }

        .header-menu-container > ul {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: relative;
        }
          .header-menu-container > ul > li {
            flex: 1 1 auto;
            position: relative;
          }
            .header-menu-container > ul > li.icn-menu-custom1 {
              display: none;
            }

            .header-menu-container > ul > li > div {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: stretch;
              position: relative;
            }
            .header-menu-container > ul > li > div:before {
              content: "";
              background: #000;
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 0;

              opacity: 0;

              -webkit-transition: opacity .2s ease-in-out 0s;
              -moz-transition: opacity .2s ease-in-out 0s;
              -o-transition: opacity .2s ease-in-out 0s;
              transition: opacity .2s ease-in-out 0s;
            }
              .header-menu-container > ul > li:hover > div:before {
                opacity: .35;
              }
              .header-menu-container > ul > li > div:hover:before,
              .header-menu-container > ul > li.active > div:before {
                opacity: 1;
              }


              .header-menu-container > ul > li > div > div {
                position: relative;
                z-index: 1;
              }
                .header-menu-container > ul > li:not(.with-dropdown) > div > div {
                  width: 100%;
                }

                .header-menu-container > ul > li > div a,
                .header-menu-container > ul > li > div > div > span {
                  display: block;
                  color: #fff;
                  font-size: 14px;
                  font-weight: 600;
                  white-space: nowrap;
                  text-align: center;
                  padding: 12px 6px;
                }
                .header-menu-container > ul li > div > div > span {
                  cursor: default;
                }
                .header-menu-container > ul li > div a {
                  cursor: pointer;
                }
                  .header-menu-container > ul li.active > div a {
                    font-weight: 700;
                    cursor: default;
                  }

                  .header-menu-container > ul > li > div a > span,
                  .header-menu-container > ul > li > div > div > span > span {
                    text-transform: uppercase;
                  }

              .header-menu-container > ul li > div > .dropmarker {
                content: "";
                color: transparent;
                font-size: 0px;
                width: 10px;
                min-width: 10px;
                cursor: default;
                position: relative;
                margin-right: 6px;
                box-sizing: content-box;
              }
                .header-menu-container > ul li > div > .dropmarker:before {
                  content: "";
                  display: block;
                  background: url('/i/header-menu-dropdown--white.svg') no-repeat 0px 50%/contain transparent;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  margin: auto;
                }


            .header-menu-container > ul > li > ul {
              background: #fff;
              border: 2px solid #000;
              position: absolute;
              top: 100%;
              left: 0px;
              min-width: 100%;
              padding: 15px 16px;
              box-shadow: 0px 3px 2px -1px rgba(0,0,0,.3);

              visibility: hidden;
              opacity: 0;

              -webkit-transition: opacity .2s ease 0s, visibility 0s ease .2s;
              -moz-transition: opacity .2s ease 0s, visibility 0s ease .2s;
              -o-transition: opacity .2s ease 0s, visibility 0s ease .2s;
              transition: opacity .2s ease 0s, visibility 0s ease .2s;
            }
              .header-menu-container > ul > li.with-groups > ul {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
                padding: 15px 6px;
              }
              .header-menu-container > ul > li:hover > ul {
                visibility: visible;
                opacity: 1;

                -webkit-transition: opacity .2s ease 0s, visibility 0s ease 0s;
                -moz-transition: opacity .2s ease 0s, visibility 0s ease 0s;
                -o-transition: opacity .2s ease 0s, visibility 0s ease 0s;
                transition: opacity .2s ease 0s, visibility 0s ease 0s;
              }

              .header-menu-container > ul > li.with-groups > ul > li {
                padding: 0px 20px 0px 10px;
              }
                .header-menu-container > ul > li.with-groups > ul > li + li {
                  border-left: 1px solid #eaeaea;
                }

                .header-menu-container > ul > li > ul > li:not(.icn-menu-service-group-null) > ul {
                  padding-left: 12px;
                }
                  .header-menu-container > ul > li > ul > li.icn-menu-service-group-null > div,
                  .header-menu-container > ul > li ul > li > div > .dropmarker {
                    display: none;
                  }

                  .header-menu-container > ul > li ul > li > div a,
                  .header-menu-container > ul > li ul > li > div > div > span {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 6px 12px;
                    white-space: nowrap;
                    position: relative;
                  }
                    .header-menu-container > ul > li ul > li > div a {
                      cursor: pointer;

                      -webkit-transition: color .2s ease-in-out 0s, background .2s ease-in-out 0s;
                      -moz-transition: color .2s ease-in-out 0s, background .2s ease-in-out 0s;
                      -o-transition: color .2s ease-in-out 0s, background .2s ease-in-out 0s;
                      transition: color .2s ease-in-out 0s, background .2s ease-in-out 0s;
                    }
                        .header-menu-container > ul > li ul > li > div a:hover,
                        .header-menu-container > ul > li ul > li.active > div a {
                          color: #fff;
                          background: #1a580d;
                        }

                      .header-menu-container > ul > li ul > li > div a > span {
                        position: relative;
                        z-index: 1;
                      }

                  .header-menu-container > ul > li.with-groups > ul > li > div a,
                  .header-menu-container > ul > li.with-groups > ul > li > div > div > span {
                    font-size: 16px;
                    font-weight: 700;
                  }
                    .header-menu-container > ul > li.with-groups > ul > li > div a:after,
                    .header-menu-container > ul > li.with-groups > ul > li > div > div > span:after {
                      content: ":";
                    }


    .header-discount {
      background: #f0f0f0;
      font-weight: 600;
      text-align: center;
      padding: 16px 0px 0px;
    }
      .header-discount:after {
        content: "";
        display: block;
        background: #1a580d;
        height: 2px;
        margin: 15px 0px 0px;
      }

      .header-discount a {
        text-decoration: underline;
        cursor: pointer;
      }
        .header-discount a:hover {
          text-decoration: none;
        }



  /* Content block */
  .content {
    position: relative;
    overflow: hidden;
    z-index: 3;

    /*min-height: 3000px;*/
  }
    .main-rotator-container {
      position: relative;
      padding: 15px 0px 10px;
    }
      .main-rotator-container > .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
      }
        #main-rotator {
          flex: 1 2 100%;
          order: 1;
          position: relative;
          overflow: hidden;
        }
          .main-rotator-slide-container {
            position: relative;
          }
            #main-rotator > .main-rotator-slide-container {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              z-index: 2;
            }
              #main-rotator > .main-rotator-slide-container + .main-rotator-slide-container {
                z-index: 1;
              }

            .main-rotator-slide-image {
              position: relative;
              background: no-repeat 50% 50%/cover #eee;
              max-height: 500px;
              overflow: hidden;
            }
              .main-rotator-slide-image:before {
                content: "";
                display: block;
                height: 0px;
                padding-top: 46.5%;
              }

              .main-rotator-slide-image a {
                content: "";
                display: block;
                font-size: 0px;
                cursor: pointer;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 1;
              }

            .main-slide-code-container {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              color: #fff;
              font-size: 2.25em;
              font-weight: 600;
              line-height: 1.3;
              text-shadow: 1px 2px 2px rgba(0,0,0,.5);
              height: 0px;
              position: absolute;
              top: 30px;
              left: 62px;
              right: 62px;
            }
              .main-slide-code-container > div {
                position: relative;
                z-index: 2;
              }
                .main-slide-code-container a {
                  text-decoration: underline;
                }
                  .main-rotator-slide-image.with-url:hover + .main-slide-code-container a,
                  .main-slide-code-container a:hover {
                    text-decoration: none;
                  }


          #main-rotator-controls {
            height: 0px;
          }
            #main-rotator-controls .slick-prev,
            #main-rotator-controls .slick-next {
              display: inline-block;
              font-size: 0px;
              background: url('/i/arrow-left--white.svg') no-repeat 50% 50%/auto 34px transparent;
              width: 62px;
              cursor: pointer;
              position: absolute;
              top: 0px;
              bottom: 0px;
              z-index: 2;
            }
            #main-rotator-controls .slick-prev {
              left: 0px;
            }
            #main-rotator-controls .slick-next {
              background-image: url('/i/arrow-right--white.svg');
              right: 0px;
            }


        .main-rotator-mini-slides {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          flex: 1 5 100%;
          order: 2;
          margin: -15px 0px 0px;
          position: relative;
          overflow: hidden;
        }
          .main-rotator-mini-slides > div {
            flex: 1 1 auto;
            padding: 15px 0px 0px 15px;
            position: relative;
          }
            .main-rotator-mini-image {
              background: no-repeat 50% 50%/cover #eee;
              height: 100%;
              position: relative;
            }
              .main-rotator-mini-image:before {
                content: "";
                display: block;
                height: 0px;
                padding-top: 50%;
              }

              .main-rotator-mini-image a {
                color: transparent;
                font-size: 0px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
              }

            .main-rotator-mini-code {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-start;
              color: #fff;
              font-size: 1.125em;
              font-weight: 600;
              line-height: 1.3;
              text-shadow: 1px 2px 2px rgba(0,0,0,.5);
              height: 0px;
              position: absolute;
              left: 30px;
              right: 15px;
              bottom: 10px;
            }
              .main-rotator-mini-code > div {
                position: relative;
                z-index: 2;
              }
                .main-rotator-mini-code a {
                  text-decoration: underline;
                }
                  .main-rotator-mini-image.with-url:hover + .main-rotator-mini-code a,
                  .main-rotator-mini-code a:hover {
                    text-decoration: none;
                  }


    .breadcrumbs {
      font-size: 0px;
      background: #f0f0f0;
      padding: 7px 10px;
      margin: 15px 0px 0px;
    }
      .breadcrumbs li {
        display: inline;
        font-size: 0px;
      }
        .breadcrumbs li:before,
        .breadcrumbs a,
        .breadcrumbs span {
          font-size: 13px;
          vertical-align: baseline;
          line-height: 1.2;
        }
        .breadcrumbs li + li:before {
          content: "\232A";
          display: inline-block;
          background: url('/i/arrow-right--black.svg') no-repeat 50% 50%/auto 10px transparent;
          width: 20px;
          color: transparent;
          font-weight: 600;
        }
        .breadcrumbs span {
          color: #333;
          font-weight: 600;
        }
        .breadcrumbs a {
          cursor: pointer;
          text-decoration: underline;
        }
          .breadcrumbs a:hover {
            color: #000;
            text-decoration: none;
          }


    .subheader,
    .subsubheader,
    .subsubsubheader {
      position: relative;
      font-size: 1.5em; /* 24px */
      font-weight: 500;
      padding: 1.25em 0em 0.625em;
      margin: 0em 0em 1.25em;
      /*text-align: left;*/
      z-index: 2;
    }
      .subheader:after {
        content: "";
        background: #1a580d;
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100px;
        height: 4px;
        margin: auto;
      }
        .tac .subheader:after,
        .subheader.tac:after {
          right: 0px;
        }

      h1.subheader {
        font-weight: 600;
      }
        h1.subheader:after {
          height: 5px;
        }

      .quick-link-list + .subheader {
        padding-top: 0px;
      }

    .subsubheader {
      font-size: 1.25em; /* 20px */
      font-weight: 600;
      padding: 0em 0em 0.75em;
      margin: 0px;
    }

    .subsubsubheader {
      font-size: 1em; /* 16px */
      padding: 0em 0em 0.5em;
      margin: 0px;
    }

      .subheader,
      .subsubheader,
      .subsubsubheader,
      .subheader > *,
      .subsubheader > *,
      .subsubsubheader > * {
        text-transform: uppercase;
      }


    .text-container {
      padding: 0px 0px 20px;
      position: relative;
      z-index: 1;
    }


    .quick-link-list {
      background: #f0f0f0;
      padding: 0px 12px 10px 0px;
      margin: 0px 0px 20px;
      position: relative;
      z-index: 1;
    }
      .quick-link-list > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: center;
      }
        .quick-link-list > div > div {
          padding: 10px 0px 0px 12px;
        }
          .quick-link-list a {
            background: #1a580d;

            display: block;
            font-size: 12px;
            font-weight: 500;
            padding: 8px 12px;
            cursor: pointer;
            position: relative;

            -webkit-transition: color .2s ease-in-out 0s;
            -moz-transition: color .2s ease-in-out 0s;
            -o-transition: color .2s ease-in-out 0s;
            transition: color .2s ease-in-out 0s;
          }
            .quick-link-list a:hover,
            .quick-link-list a.active {
              color: #fff;
            }
            .quick-link-list a.active {
              cursor: default;
            }

            .quick-link-list a:before {
              content: '';
              background: #f0f0f0;
              position: absolute;
              top: 2px;
              left: 2px;
              right: 2px;
              bottom: 2px;
              cursor: pointer;
              opacity: 1;
              z-index: 0;

              -webkit-transition: opacity .2s ease-in-out 0s;
              -moz-transition: opacity .2s ease-in-out 0s;
              -o-transition: opacity .2s ease-in-out 0s;
              transition: opacity .2s ease-in-out 0s;
            }
              .quick-link-list a:hover:before,
              .quick-link-list a.active:before {
                opacity: 0;
              }

            .quick-link-list a > span {
              cursor: pointer;
              position: relative;
              z-index: 1;
            }


    .price-grouped-table-container .quick-link-list {
      background: none;
      padding: 0px;
    }
      .price-grouped-table-container .quick-link-list > div {
        justify-content: center;
        margin: 0px -10px;
      }
        .price-grouped-table-container .quick-link-list > div > div {
          font-size: 0px;
          border-right: 1px solid #111;
          padding: 4px 10px;
        }
          .price-grouped-table-container .quick-link-list > div > div:last-child {
            border-right-color: transparent;
          }

          .price-grouped-table-container .quick-link-list a {
            font-size: 15px;
            font-weight: 400;
            display: inline;
            color: #000;
            background: none !important;
            border: 0px dashed #000;
            border-bottom-width: 1px;
            line-height: 1;
            padding: 0px;

            -webkit-transition: color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;
            -moz-transition: color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;
            -o-transition: color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;
            transition: color .2s ease-in-out 0s, border-color .2s ease-in-out 0s;
          }
            .price-grouped-table-container .quick-link-list a:hover {
              border-bottom-color: transparent;
            }
            .price-grouped-table-container .quick-link-list:not(.selected) > div > div:first-child a,
            .price-grouped-table-container .quick-link-list > div > div.active a {
              color: #1a580d;
              border-bottom-color: transparent;
              font-weight: 700;
              cursor: default;
            }

            .price-grouped-table-container .quick-link-list a:before {
              display: none;
            }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      color: #1a580d;
      font-weight: 500;
      font-style: italic;
      padding: 0px 0px 20px;
      margin: -14px 0px 0px -14px;
      position: relative;
    }
      .gallery-block-container > div.fjcs {
        justify-content: flex-start;
      }

      .gallery-item {
        text-align: center;
        padding: 14px 0px 0px 14px;
        position: relative;
      }
        .gallery-item-width-2 {
          width: 50%;
        }
        .gallery-item-width-3 {
          width: 33.33333333%;
        }
        .gallery-item-width-4 {
          width: 25%;
        }

        .gallery-rotator-image img,
        .gallery-item img {
          width: 100%;
        }

      .gallery-block-container .gallery-notice {
        text-align: center;
        margin: -8px 0px 0px;
      }


    .list-view,
    .portfolio-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: stretch;
      position: relative;
    }
    .list-view {
      margin: -15px 0px 0px -10px;
      padding: 0px 0px 20px;
    }
      .list-view-item {
        width: 25%;
        padding: 15px 0px 0px 10px;
        position: relative;
      }
        .list-view-item-wrapper {
          background: #f0f0f0;
          height: 100%;
          position: relative;
        }
          .list-view-item-image {
            font-size: 0px;
            padding: 4px 4px 0px;
            position: relative;
            overflow: hidden;
            z-index: 1;
          }
            .list-view-item-image > a,
            .list-view-item-image > span {
              display: block;
              background: no-repeat 50% 50%/cover transparent;
              height: 0px;
              padding-top: 62%;
            }
              .list-view-item-image > a {
                cursor: pointer;
              }

          .list-view-item-content {
            padding: 14px 15px 15px;
            position: relative;
            z-index: 2;
          }
            .list-view-item-name {
              position: relative;
            }
              .list-view-item-name a,
              .list-view-item-name span {
                font-weight: 500;
                text-transform: uppercase;
                text-decoration: none;
              }
              .list-view-item-name a {
                color: #1a580d;
                text-decoration: underline;
                cursor: pointer;
              }
                .list-view-item-image:hover + .list-view-item-content .list-view-item-name a,
                .list-view-item-name a:hover {
                  text-decoration: none;
                }

          .list-view-item-notice {
            font-size: 14px;
            padding: 10px 0px 0px;
          }
            .list-view-item-notice *:last-child {
              margin-bottom: 0px;
            }


    .list-block-container + .list-page-group-name {
      padding-top: 10px;
    }
    .list-page-group-name ~ .list-block-container + .list-block-container {
      padding-top: 20px;
    }


    .gallery-rotator-block-container .list-view-item {
      width: 50%;
    }
    .gallery-rotator-block-container.gallery-items-width-3 .list-view-item,
    .gallery-mini-block-container .list-view-item {
      width: 33.333333%;
    }
    .gallery-rotator-block-container.gallery-items-width-4 .list-view-item {
      width: 25%;
    }
      .gallery-rotator {
        position: relative;
      }
        .gallery-rotator:before {
          content: "";
          background: #eee;
          display: block;
          height: 0px;
          padding: 60% 0% 0%;
        }
          .gallery-rotator.slick-initialized:before {
            display: none;
          }

        .gallery-rotator-image {
          position: relative;
          z-index: 0;
        }
          .gallery-rotator > .gallery-rotator-image {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
          }
            .gallery-rotator > .gallery-rotator-image:first-child {
              z-index: 1;
            }

            .gallery-rotator-image a,
            .gallery-rotator-image img {
              width: 100%;
            }

        .gallery-rotator-pager {
          position: relative;
          overflow: hidden;
        }
          .gallery-rotator-pager > div {
            display: none;
            margin: 0px 0px 0px -6px;
          }
            .gallery-rotator-pager > div.slick-initialized {
              display: block;
            }

            .gallery-rotator-pager .slick-track {
              margin: 0px;
            }

            .gallery-rotator-pager-image {
              display: inline-block;
              width: 16.666666%;
              padding: 6px 0px 0px 6px;
              z-index: 0;
            }
              .gallery-rotator-pager-image div {
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                height: 0px;
                padding: 60% 0% 0%;
                cursor: pointer;
                position: relative;
              }
                .gallery-rotator-pager-image div:after {
                  content: "";
                  border: 2px solid transparent;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  z-index: 0;

                  -webkit-transition: border-color .3s ease 0s;
                  -moz-transition: border-color .3s ease 0s;
                  -o-transition: border-color .3s ease 0s;
                  transition: border-color .3s ease 0s;
                }
                  .gallery-rotator-pager-image.slick-current div:after {
                    border-color: #1a580d;
                  }

      .gallery-rotator-block-container .list-view-item-name span {
        font-size: 18px;
      }
        .gallery-rotator-block-container.gallery-items-width-3 .list-view-item-name span,
        .gallery-rotator-block-container.gallery-items-width-4 .list-view-item-name span {
          font-size: 16px;
        }


    .gallery-mini-block-container .list-view-item-image {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      padding: 0px 4px 0px 0px;
    }
      .gallery-mini-block-container .list-view-item-image > span {
        background: none;
        height: auto;
        padding: 4px 0px 0px 4px;
      }
        .gallery-mini-block-container .list-view-item-image > span:first-child {
          width: 100%;
        }
        .gallery-mini-block-container .list-view-item-image > span + span {
          width: 33.333333%;
        }
        .gallery-mini-block-container .list-view-item-image > span:nth-child(n + 5) {
          display: none;
        }

        .gallery-mini-block-container .list-view-item-image a {
          display: block;
        }
          .gallery-mini-block-container .list-view-item-image > span + span a.zoom-image:hover:before {
            top: 20%;
            left: 20%;
            width: 60%;
            height: 60%;
          }

        .gallery-mini-block-container .list-view-item-image img {
          width: 100%;
        }


    .service-block-container .list-view {
      justify-content: flex-start;
    }
      .service-block-container .list-view-item {
        width: 33.333333%;
      }
        .service-block-container .list-view-item-wrapper {
          min-height: 185px;
          z-index: 1;
        }
          .service-block-container .list-view-item-image {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            padding: 0px;
            margin: 0px;
          }
            .service-block-container .list-view-item-image > span {
              width: 70%;
              height: 100%;
              padding-top: 0px;
              margin: 0px 0px 0px auto;
              position: relative;
              z-index: 0;
            }

            .service-block-container .list-view-item-image:after {
              content: "";
              background: repeat-y top right/100% auto transparent;
              display: block;
              position: absolute;
              top: 0px;
              left: 0px;
              bottom: 0px;
              width: 60%;
              z-index: 1;
            }
              .service-block-container .list-view-item:nth-child(8n+1) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-01.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+2) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-02.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+3) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-03.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+4) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-04.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+5) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-05.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+6) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-06.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+7) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-07.svg');
              }
              .service-block-container .list-view-item:nth-child(8n+8) .list-view-item-image:after {
                background-image: url('/i/list-view-bg-gradient-08.svg');
              }


          .service-block-container .list-view-item-content {
            height: 100%;
            padding: 25px 35% 20px 22px;
            text-shadow: 1px 1px 2px #fff;
          }
            .service-block-container .list-view-item-name a,
            .service-block-container .list-view-item-name span {
              color: #000;
              font-size: 18px;
            }
              .service-block-container .list-view-item-name a > span,
              .service-block-container .list-view-item-name span > span {
                font-size: 20px;
                font-weight: 600;
              }


            .service-block-container .list-view-item-notice {
              font-size: 16px;
              font-weight: 400;
              padding-top: 15px;
            }


    .price-vitrine-item-price {
      color: #ec5b47;
      line-height: 1.2;
      font-weight: 700;
      padding: 10px 0px 0px;
    }
      .price-vitrine-item-price > span {
        font-size: inherit;
        width: 0px;
        height: 0px;
        padding: 0px 3px 0px;
      }


    .portfolio-block-container .list-view-item-name {
      min-height: 3em;
    }


    .portfolio-page-block-container .list-view {
      overflow: hidden;
    }
      .portfolio-items {
        flex-grow: 1;
      }
        .portfolio-page-block-container .portfolio-items {
          justify-content: flex-start;
        }


    .advantages-block-container .list-view-item {
      width: 33.33333333%;
    }
      .advantages-block-container .list-view-item-wrapper {
        background: #1a580d;
        min-height: 151px;
        padding: 3px;
      }
      .advantages-block-container .list-view-item-wrapper:before {
        content: "";
        display: block;
        background: #fafafb;
        position: absolute;
        top: 3px;
        left: 3px;
        right: 118px;
        bottom: 3px;
        z-index: 0;
      }
      .advantages-block-container .list-view-item-wrapper:after {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        right: 3px;
        bottom: 3px;
        border-bottom: 145px solid transparent;
        border-left: 115px solid #fafafb;
        z-index: 0;
      }

      .advantages-block-container .list-view-item-image {
        position: absolute;
        right: 12px;
        bottom: 15px;
        width: 50px;
        height: 50px;
        padding: 0px;
        z-index: 1;
      }
        .advantages-block-container .list-view-item-image span {
          background: no-repeat 50% 50%/cover transparent;
          padding: 100% 0% 0%;
        }

      .advantages-block-container .list-view-item-content {
        line-height: 1.3;
        padding: 18px 20px 20px;
        z-index: 2;
      }
        .advantages-block-container .list-view-item-name {
          padding-right: 20px;
        }
          .advantages-block-container .list-view-item-name span {
            font-weight: 500;
          }

        .advantages-block-container .list-view-item-notice {
          padding-right: 60px;
        }


    .price-table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0px 0px 20px;
      position: relative;
    }
      .price-table-counter {
        counter-reset: price-table-group-counter;
      }
      .price-table > div {
        border-bottom: 1px solid #e8e8e8;
      }
        .price-grouped-table-container .price-table:not(.selected) > div:not(:first-child),
        .price-grouped-table-container .price-table.selected > div:not(.active),
        .price-grouped-table-container .price-table-group {
          display: none;
        }

      .price-table > div + div {
        margin-top: 35px;
      }
        .price-grouped-table-container .price-table > div + div {
          margin-top: 0px;
        }

        .price-table > div > div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
        }
        .price-table-group {
          justify-content: flex-start !important;
          padding: 0px 0px 14px 30px;
        }
          .price-table-counter .price-table-group {
            counter-reset: price-table-subheader-counter;
          }
          .price-table-group span {
            color: #1a580d;
            font-size: 22px;
            font-weight: 700;
            text-transform: uppercase;
          }
            .price-table-counter .price-table-group span:before {
              content: counter(price-table-group-counter) ". ";
              counter-increment: price-table-group-counter;
            }

        .price-table-head {
          color: #fff;
          background: #1a580d;
          align-items: flex-end !important;
        }

        .price-table-subheader {
          background: #b4c8b0;
          position: relative;
          z-index: 1;
        }

        .price-table-row {
          background: #fff;
        }
          .price-table-row.odd,
          .price-table-design .price-table-row:nth-child(odd) {
            background: #f0f0f0;
          }
          .price-name,
          .price-price,
          .price-unit {
            font-size: 15px;
            font-weight: 400;
            padding: 5px 2px;
            flex-basis: 100%;
            flex-shrink: 4;
          }
          .price-name {
            padding-left: 30px;
          }
          .price-price,
          .price-unit {
            text-align: center;
            flex-shrink: 8;
          }
            .price-price > span {
              font-size: inherit;
              width: 0px;
              height: 0px;
              padding: 0px 2px 0px;
            }

            .price-table-head .price-name,
            .price-table-head .price-price,
            .price-table-head .price-unit {
              font-size: 14px;
              font-weight: 600;
              line-height: 1.2;
              text-transform: uppercase;
              padding-top: 11px;
              padding-bottom: 10px;
            }
            .price-table-subheader .price-name {
              /*color: #1e6496;*/
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
              padding-top: 10px;
              padding-bottom: 8px;
              /*border-bottom: 1px solid #e8e8e8;*/
            }
              .price-table-counter .price-table-subheader .price-name:before {
                content: counter(price-table-group-counter) "." counter(price-table-subheader-counter) ". ";
                counter-increment: price-table-subheader-counter;
                font-weight: 500;
              }
              /*.price-table-row + .price-table-subheader .price-name {
                border-top: 1px solid #e8e8e8;
              }*/


    .price-button {
      font-size: 19px;
    }



    .portfolio-info-icons {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: center;
      margin: -8px 0px 0px -15px;
    }
      .portfolio-block-container .portfolio-info-icons {
        font-size: 14px;
      }

      .portfolio-info-icons > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-weight: 500;
        padding: 8px 0px 0px 15px;
      }
        .portfolio-info-icons img {
          height: 18px;
          margin-right: 8px;
        }

        .portfolio-info-icons .price-item > span,
        .portfolio-info-icons .price-stuff > span + span {
          line-height: 1;
          border-bottom: 1px dashed #000;
          cursor: pointer;
        }
          .portfolio-info-icons .price-item > span:hover,
          .portfolio-info-icons .price-stuff > span + span:hover {
            border-bottom-color: transparent;
          }

          .portfolio-info-icons .price-item > span > span,
          .portfolio-info-icons .price-stuff > span > span {
            font-size: inherit;
            width: 0px;
            height: 0px;
            padding: 0px 2px 0px;
          }

        .portfolio-info-icons .price-stuff > span:first-child {
          font-size: 14px;
          font-weight: 600;
          margin-right: 5px;
        }

      .portfolio-info-icons + div {
        padding: 18px 0px 0px;
      }


    .faq-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;
      padding: 0px 0px 25px;
      margin: -15px 0px 0px -10px;
    }
      .faq-list > div {
        width: 50%;
        padding: 15px 0px 0px 10px;
      }
        .faq-list > div > div {
          background: #1a580d;
          padding: 3px;
        }
          .faq-list > div > div > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            color: #fff;
            font-weight: 600;
            line-height: 1.2;
            margin: -3px;
            position: relative;
            cursor: pointer;
            overflow: hidden;
            z-index: 1;

            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
            .faq-list > div > div > div:first-child:before {
              content: "";
              background: #fff;
              position: absolute;
              top: 0px;
              left: 50%;
              right: 50%;
              bottom: 0px;
              margin: 0px auto;
              opacity: 0.15;
              z-index: 1;

              -webkit-transform: skew(-45deg);
              -moz-transform: skew(-45deg);
              -ms-transform: skew(-45deg);
              -o-transform: skew(-45deg);
              transform: skew(-45deg);

              -webkit-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
              -moz-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
              -o-transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
              transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
            }
              .faq-list > div > div > div:first-child:hover:before {
                left: -3em;
                right: -3em;
              }

            .faq-list > div > div > div:first-child > span:first-child {
              padding: 1.25em 1.5625em;
            }

            .faq-list > div > div > div:first-child > span + span {
              width: 3.75em;
              min-width: 3.75em;
              position: relative;
            }
              .faq-list > div > div > div:first-child > span + span:after {
                content: "";
                display: flex;
                background: url('/i/faq-list-plus.svg') no-repeat 50% 50%/1em auto transparent;
                line-height: 1;
                height: 100%;
                position: relative;
                z-index: 1;

                -webkit-transition: background-image 0.2s ease 0s;
                -moz-transition: background-image 0.2s ease 0s;
                -o-transition: background-image 0.2s ease 0s;
                transition: background-image 0.2s ease 0s;
              }
              .faq-list > div > div.active > div:first-child > span + span:after {
                background-image: url('/i/faq-list-minus.svg');
              }


            .faq-list > div > div > div:first-child + div {
              display: none;
              background: #fafafb;
              font-size: 0.875em;
              line-height: 1.3;
              overflow: hidden;
              vertical-align: bottom;
            }
              .faq-list > div > div > div:first-child + div > div {
                width: 100%;
                padding: 1.42857143em 1.57142857em;
              }
                .faq-list > div > div > div:first-child + div > div p:last-child {
                  margin-bottom: 0px;
                }


    .hide-after:before,
    .hide-after ~ * {
      display: none !important;
    }


    .js-show-hide {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }
      .js-show-hide > div:first-child {
        line-height: 1;
        position: relative;
        order: 1;
      }
        .js-show-hide.active > div:first-child {
          order: 3;
        }

        .js-show-hide > div:first-child > span {
          color: #1a580d;
          border-bottom: 1px dashed #1a580d;
          font-weight: 500;
          cursor: pointer;
        }
          .js-show-hide > div:first-child > span:hover {
            border-bottom-color: transparent;
          }

      .js-show-hide > div + div {
        display: none;
        position: relative;
        order: 2;
      }
        .js-show-hide.active > div + div {
          display: block;
        }


    .incut {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      background: #1a580d;
      padding: 4px;
      position: relative;
    }
      .incut:before,
      .incut > div {
        flex: 1 1 auto;
      }
      .incut:before {
        content: "";
        font-size: 0px;
        background: url('/f/upload/incut-icon.svg') no-repeat 50% 50%/40% 40% #fafafb;
        min-width: 90px;
        max-width: 90px;
        margin-right: 4px;
      }

      .incut > div {
        background: #fafafb;
        width: 100%;
        padding: 18px 20px 20px;
      }
        .incut p:last-child {
          margin-bottom: 0px;
        }


    .incut-photo {
      display: block;
    }
      .incut-photo:before {
        position: absolute;
        background-image: url('/f/upload/incut-photo-bg.jpg');
        background-size: cover;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        min-width: unset;
        max-width: unset;
        margin-right: 0px;
      }

      .incut-photo > div {
        background: none;
        padding: 28px 30px 30px;
        position: relative;
      }

      .incut-photo,
      .incut-photo .subheader,
      .incut-photo .subsubheader,
      .incut-photo .subsubsubheader {
        color: #fff;
        text-align: center;
      }
      .incut-photo a {
        color: #fff;
      }



    .team-member-block-container .list-view {
      justify-content: flex-start;
    }
      .team-member-block-container .list-view-item {
        width: 33.33333333%;
      }
        .team-member-block-container .list-view-item-wrapper {
          padding-bottom: 4px;
        }
          .team-member-info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
          }
            .team-member-block-container .list-view-item-image {
              max-width: 26%;
              min-width: 26%;
            }
              .team-member-block-container .list-view-item-image > span {
                padding-top: 100%;
              }

            .team-member-info > div + div {
              align-self: center;
              padding: 5px 15px 5px 10px;
            }


          .team-member-block-container .list-view-item-content {
            font-size: 14px;
            font-style: italic;
            padding-bottom: 11px;
          }



  /* Contact */
  .contact-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    padding: 0px 0px 30px;
  }
    .contact-info-container > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      min-width: 50%;
      max-width: 50%;
    }
      .contact-info-container > div > div {
        min-width: 200%;
        max-width: 200%;
      }

    .contact-info-container > div:first-child {
      align-self: center;
      padding: 30px 0px;
    }
      .contact-info-container > div:first-child > div > .wrapper {
        position: unset;
      }
        .contact-info-wrapper {
          display: inline-block;
          background: #fff;
          box-shadow: inset 0 0 0 1px rgba(0,0,0,.15), 0 5px 15px -7px rgba(0,0,0,.5);
          width: 350px;
          max-width: 100%;
          padding: 25px;
          margin-left: 10px;
          position: relative;
          z-index: 3;
        }
          .contact-info-wrapper > div + div {
            padding-top: 20px;
          }
            .contact-info-label {
              font-size: 18px;
              font-weight: 600;
              padding: 0px 0px 4px;
            }

            .contact-phones > div > div + div,
            .contact-emails > div > div + div {
              padding-top: 2px;
            }
            .contact-address > div > div + div {
              padding-top: 12px;
            }
              .contact-phones div:not(.contact-worktime) > a {
                font-size: 20px;
                font-weight: 500;
              }

              .contact-worktime {
                font-size: 14px;
                font-weight: 300;
              }

            .contact-address {
              white-space: nowrap;
            }

            .contact-emails a {
              font-weight: 500;
              line-height: 1.1;
              text-decoration: underline;
              cursor: pointer;
            }
              .contact-emails a:hover {
                text-decoration: none;
              }

            .contact-info-wrapper .contact-socials > div {
              justify-content: flex-start;
            }


    .contact-info-container > div + div {
      justify-content: flex-end;
    }
      .contact-info-container > div + div > div,
      .map-block-container {
        background: #eee;
        max-height: 600px;
        overflow: hidden;
        position: relative;
      }
        .contact-info-container > div + div > div:before,
        .map-block-container:before {
          content: "";
          display: block;
          height: 0px;
          padding-top: 45%;
        }

        .map-placeholder {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
        }
          .map-placeholder:after {
            content: "Загрузка карты...";
          }
          .contact-info-container .map-placeholder:after {
            margin: 0px 0px 0px 360px;
          }

          .map-container {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 2;
          }



  /* Footer */
  .footer-callback-container {
    background: #f0f0f0;
    margin: 20px 0px 25px;
  }
    .footer-callback-container .form-block-container {
      background-color: transparent;
      max-width: 900px;
      margin: 0px auto;
      padding: 25px;
    }
      .footer-callback-container .subsubheader {
        margin-top: -2px;
        text-align: center;
      }

      .footer-callback-container form {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin: -10px 0px 0px -10px;
      }
        .footer-callback-container .form-row,
        .footer-callback-container .form-button,
        .footer-callback-container #form-success-message {
          padding: 10px 0px 0px 10px;
          margin: 0px;
        }

        .footer-callback-container .form-elements {
          flex: 2 1 auto;
        }
          .footer-callback-container .form-elements > div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;
          }
          .footer-callback-container .form-elements > div:first-child .form-row {
            flex: 1 1 auto;
          }
            .footer-callback-container .form-elements > div:first-child .form-label {
              display: none;
            }

        .footer-callback-container .form-buttons {
          flex: 1 1 auto;
          margin: 0px;
        }
          .footer-callback-container .form-button,
          .footer-callback-container .button-style {
            width: 100%;
          }

        .footer-callback-container #form-success-message {
          text-align: center;
        }


  .footer {
    color: #fff;
    background: #1a580d;
    padding: 20px 0px 0px;
    position: relative;
    z-index: 1;
  }
    .footer-social-panel + .footer {
      padding-bottom: 38px;
    }

    .footer-content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding: 0px 0px 20px;
      margin-left: -25px;
    }
      .footer-left-container {
        flex: 1 1 auto;
        margin-left: 25px;
      }
        .footer-left-container > div {
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
          .footer-left-container > div > div + div {
            padding-top: 10px;
          }

          .footer-logo {
            margin: 3px 0px 0px;
          }

          .footer-phones > div + div {
            padding-top: 3px;
          }
            .footer-phones a {
              color: #fff;
              font-size: 18px;
              font-weight: 600;
              white-space: nowrap;
            }

          .footer-buttons .button-style {
            font-size: 12px;
          }


      .footer-menu-container {
        flex: 3 1 auto;
      }
        .footer-menu-container > ul {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
          .footer-menu-container > ul > li {
            padding: 0px 0px 0px 25px;
          }
            .footer-menu-container .dropmarker {
              display: none;
            }

            .footer-menu-container > ul > li > div {
              border-bottom: 1px solid #fff;
              padding: 0px 0px 8px;
            }

              .footer-menu-container > ul li > div a,
              .footer-menu-container > ul li > div span {
                color: #fff;
                font-size: 14px;
              }
              .footer-menu-container > ul li > div a {
                cursor: pointer;

                -webkit-transition: color .2s ease-in-out 0s;
                -moz-transition: color .2s ease-in-out 0s;
                -o-transition: color .2s ease-in-out 0s;
                transition: color .2s ease-in-out 0s;
              }
              .footer-menu-container > ul li > div a:hover {
                text-decoration: underline;
              }
              .footer-menu-container > ul li.active > div a {
                font-weight: 600;
                text-decoration: none;
              }

              .footer-menu-container > ul > li > div a,
              .footer-menu-container > ul > li > div span {
                font-size: 16px;
                font-weight: 500;
                white-space: nowrap;
              }


            .footer-menu-container > ul > li > ul {
              padding: 8px 0px 0px;
            }
              .footer-menu-container > ul > li > ul > li {
                padding: 2px 0px 0px;
              }


    .footer-copyrights {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      font-size: 14px;
      border-top: 1px solid #fff;
      padding: 20px 0px;
    }



  .footer-social-panel {
    color: #fff;
    background: rgba(0, 0, 0, .8);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
    white-space: nowrap;
    text-align: center;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 4;
  }
    .footer-social-panel .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: stretch;
    }
      .footer-social-panel div {
        flex-grow: 1;
      }
      .footer-social-panel div + div {
        border-left: 1px solid #fff;
      }
        .footer-social-panel a {
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 10px 5px;
        }
          .footer-social-panel a[class^="icon-"]:before {
            content: '';
            background: url('/i/socials--white.svg') no-repeat 0 0/75px auto transparent;
            width: 18px;
            min-width: 18px;
            height: 18px;
            margin-right: 8px;
          }
          .footer-social-panel a.icon-telegram:before  { background-position: 0 -19px; }
          .footer-social-panel a.icon-vk:before        { background-position: 0 -38px; }
          .footer-social-panel a.icon-linkedin:before  { background-position: 0 -57px; }
          .footer-social-panel a.icon-instagram:before { background-position: -19px 0; }
          .footer-social-panel a.icon-tiktok:before    { background-position: -19px -19px; }
          .footer-social-panel a.icon-whatsapp:before  { background-position: -19px -38px; }
          .footer-social-panel a.icon-houzz:before     { background-position: -19px -57px; }
          .footer-social-panel a.icon-ok:before        { background-position: -38px 0; }
          .footer-social-panel a.icon-twitter:before   { background-position: -38px -19px; }
          .footer-social-panel a.icon-youtube:before   { background-position: -38px -38px; }
          .footer-social-panel a.icon-pinterest:before { background-position: -57px 0; }
          .footer-social-panel a.icon-viber:before     { background-position: -57px -19px; }
          .footer-social-panel a.icon-zen:before       { background-position: -57px -38px; }




  /* Other */
  .grecaptcha-badge {
    bottom: 50px !important;
    z-index: 9;
  }